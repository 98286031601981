import { createSelector } from 'reselect';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import intersection from 'lodash/intersection';

import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';
import { optionsSelector } from 'selectors/optionsSelector';
import { variationsSelector } from 'redux/modules/variations';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';

export const dishConfiguratorSelector = state => state.dishConfigurator;

export const configuredDishIdSelector = createSelector(
  dishConfiguratorSelector,
  dishConfigurator => dishConfigurator.dishId,
);

export const configuredDishSelector = createSelector(
  availableAtDateItemsSelector,
  configuredDishIdSelector,
  (dishes, dishId) => get(dishes, dishId, {}),
);

export const configuredDishVariations = createSelector(
  variationsSelector,
  configuredDishSelector,
  (variation, dish) =>
    filter(get(dish, 'variations', []).map(id => variation[id])),
);

export const dishVariationsWithOptionsSelector = createSelector(
  configuredDishVariations,
  optionsSelector,
  // Looks messy, but it just joins `options` with `variations`
  (variations, options) =>
    variations.map(variation => ({
      ...variation,
      options: variation.options.map(option => ({
        ...option,
        ...options[`${option.id}`],
      })),
    })),
);

const variationsWithValueSelector = createSelector(
  [dishVariationsWithOptionsSelector, dishConfiguratorSelector],
  (dishVariations, configuration) =>
    dishVariations.map(variation => ({
      ...variation,
      value: mapValues(
        get(configuration, ['variations', variation.id, 'value'], {}),
        (v, k) => ({
          ...variation.options.find(({ id }) => id === +k),
          choice: v,
        }),
      ),
    })),
);

const keyedVariationsWithValueSelector = createSelector(
  [variationsWithValueSelector],
  variations => keyBy(variations, 'id'),
);

// Keep this logic in sync with cartItemsPriceSelector
export const configuredDishVariationsSelector = createSelector(
  [variationsWithValueSelector, keyedVariationsWithValueSelector,scheduleTimeSelector],
  (variations, keyedVariations,scheduleTime) =>
    
    variations.map(variation => {
      
      const { availability } = variation;
      const scheduleTimeISOString = new Date(scheduleTime).toISOString();
      const convertedTime = new Date(scheduleTimeISOString);
      
      

      if (availability) {
          
          const orderDay = convertedTime.getDay();
          const orderHour = convertedTime.getHours();
          const orderMinute = convertedTime.getMinutes();
          const orderHourMinute = `${orderHour}:${orderMinute}`;
          const orderTotalMinutes = orderHour * 60 + orderMinute;
          const isAvailableDay = availability.days.includes(orderDay.toString());

          const isAvailableHour = availability.hours.some(({ from, to }) => {
            const [fromHour, fromMinute] = from.split(':').map(Number);
            const [toHour, toMinute] = to.split(':').map(Number);
            const fromTotalMinutes = fromHour * 60 + fromMinute;
            const toTotalMinutes = toHour * 60 + toMinute;  
   
            return fromTotalMinutes <= orderTotalMinutes && orderTotalMinutes <= toTotalMinutes;
           
          });
          if (!isAvailableDay || !isAvailableHour) {
            return {
              ...variation,
              visible: false,
              valid: true,
            };
          }
      }
      let overwrittenValues = {};
      const { conditionalValues } = variation;
      if (conditionalValues && Object.keys(conditionalValues)?.length) {
        Object.entries(conditionalValues).forEach(([k, v]) => {
          Object.entries(v).forEach(([optionKey, newDataValues]) => {
            if (optionKey in keyedVariations[k]?.value) {
              overwrittenValues = { ...overwrittenValues, ...newDataValues };
            }
          });
        });
      }

      const overwrittenVariation = { ...variation, ...overwrittenValues };

      const {
        value,
        min,
        max,
        isRequired,
        chargeMoreExpensive,
        type,
        visibility,
      } = overwrittenVariation;

      let visible = true;
      if (visibility && Object.keys(visibility)?.length) {
        visible = Object.entries(visibility).every(([k, v]) =>
          Boolean(
            intersection(v, Object.keys(keyedVariations[k]?.value).map(Number))
              ?.length,
          ),
        );
      }
      const optionsLength = sumBy(Object.values(value), ({ choice }) =>
        Math.max(choice, 1),
      );
      const required = isRequired;
      const variationPrice =
        chargeMoreExpensive && type === 'mix_and_match' && optionsLength === 2
          ? Math.max(...Object.values(value).map(({ price }) => price))
          : sumBy(Object.values(value), ({ partialPrice, price, choice }) =>
              partialPrice && Math.abs(choice) === 0.5
                ? partialPrice
                : Math.max(choice, 1) * price,
            );
      const valid =
        !visible ||
        !(
          (required && !optionsLength) ||
          (min && optionsLength && optionsLength < min) ||
          (max > 0 && optionsLength && optionsLength > max)
        );
      return {
        ...overwrittenVariation,
        variationPrice,
        optionsLength,
        required,
        visible,
        valid,
      };
    }),
);

export const configuredDishPriceSelector = createSelector(
  [configuredDishSelector, configuredDishVariationsSelector],
  (dish, variations) => {
    const { overwrittenPriceRange } = dish;
    if (!overwrittenPriceRange) {
      return dish.price;
    }
    const priceOverwriteVariation = find(variations, 'priceOverwrite');
    const priceOverwriteOption = find(priceOverwriteVariation.value, 'choice');
    if (priceOverwriteOption) {
      return priceOverwriteOption.price;
    }
    return overwrittenPriceRange;
  },
);
