import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { canDeliverySelector, canPickupSelector } from 'redux/modules/general';
import destinationAddressSelector from 'selectors/destinationAddressSelector';
import { actions as modalActions } from 'components/ReduxModal';
import { history } from 'routes';
import DeliveryMethod from '../components/DeliveryMethod';

const DeliveryMethodHOC = compose(
  connect(
    state => ({
      method: getDeliveryType(state),
      address: destinationAddressSelector(state),
      canPickup: canPickupSelector(state),
      canDelivery: canDeliverySelector(state),
    }),
    {
      closeModal: modalActions.closeModal,
    },
  ),
  withProps(({ method, canDelivery }) => ({
    titleText: {
      [true]: 'Delivery',
      [method === 'PICKUP']: 'Pickup Address',
      [method === 'DELIVERY']: 'Delivery Address',
    }.true,
    method,
    editButtonEnabled: canDelivery,
  })),
  withHandlers({
    onEditClick: ({ closeModal }) => () => {
      closeModal();
      history.push('/');
    },
    onSaveClick: ({ setUserAddressId, setDeliveryType }) => ({
      method,
      addressId,
    }) => {
      setUserAddressId(addressId);
      setDeliveryType(method);
    },
  }),
);

export default DeliveryMethodHOC(DeliveryMethod);
