import React from 'react';
import FocusLock from 'react-focus-lock';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { actions as modalActions } from 'components/ReduxModal';
import { Switch, Route } from 'react-router-dom';
import RootPortal from 'components/RootPortal';
import { media } from 'utils/mediaQueries';
import logo from 'assets/logo-footer-black.svg';
import { DeliverySettings } from 'routes/DeliverySettings';

import Icon from 'components/Icon';

const ModalHeader = ({ onCloseClick, children, showBack }) => (
  <Header>
    <Close onClick={onCloseClick}>
      {!showBack &&  <Icon name="icon-cross-thin-small" size="34px" />}
      {showBack && <Icon name="icon-arrow-left-small" size="34px" />}
    </Close>
    <CloseMobile onClick={onCloseClick}>
      {showBack && <Icon name="icon-arrow-left" size="24px" />}
      {!showBack && <Icon name="icon-cross-small" size="24px" />}
    </CloseMobile>
    <Heading>{children}</Heading>
    <div />
  </Header>
);

ModalHeader.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  showBack: PropTypes.bool,
};

const Header = styled.div`
  background: #fff;
  box-shadow: 1px 1px 3px 0 #e6e7e8;
  padding: 11px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Close = styled.button`
  width: 16px;
  height: 16px;
  opacity: 0.5;

  ${media.tablet`
    display: none;
  `};
`;

const CloseMobile = styled.button`
  width: 16px;
  height: 16px;
  opacity: 0.5;
  display: none;
  cursor: pointer;

  ${media.tablet`
    display: block;
  `};
`;

const Heading = styled.span`
  text-align: center;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.2px;
`;

const Modal = ({ history, children, closeModal }) => {
  const back = e => {
    e.stopPropagation();
    // get current location
    const { pathname } = history.location;
    // if pathname is /forgot-password go back to previous location
    if (pathname === '/validate-lastname') {
      // get login from history locaiton state
      const { login } = history.location.state;
      // if login is not set go back to forgot-password
      if (!login) {
        history.push('/sign-in');
      }
      else
      {
        history.push({
          pathname: '/forgot-password',
          state: {
            login: login,
          },  
        });
      }
      return;
    }
    closeModal();
    history.push('/');
  };
  
  const showBack = history.location.pathname === '/validate-lastname';
  return (
    <RootPortal>
      <PopUpOverlay />
      <PopupWrapper>
        <FocusLock>
          <ModalContent>
            <ModalHeader onCloseClick={back} smallPaddings showBack={showBack}> 
              <Top aria-hidden>
                <Label>POWERED BY</Label>
                <Logo />
              </Top>
            </ModalHeader>
            <Content>{children}</Content>
          </ModalContent>
        </FocusLock>
      </PopupWrapper>
    </RootPortal>
  );
};

Modal.propTypes = {
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
};

class ModalSwitch extends React.Component {
  // We can pass a location to <Switch/> that will tell it to
  // ignore the router's current location and use the location
  // prop instead.
  //
  // We can also use "location state" to tell the app the user
  // wants to go to `/img/2` in a modal, rather than as the
  // main page, keeping the gallery visible behind it.
  //
  // Normally, `/img/2` wouldn't match the gallery at `/`.
  // So, to get both screens to render, we can save the old
  // location and pass it to Switch, so it will think the location
  // is still `/` even though its `/img/2`.

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  previousLocation = this.props.location;

  render() {
    const { location, children, routes, closeModal } = this.props;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render
    return (
      <React.Fragment>
        <Switch location={isModal ? this.previousLocation : location}>
          {routes.map(({ props: { path, ...rest } }) => (
            <Route
              key={path}
              path={path}
              {...rest}
              component={DeliverySettings}
            />
          ))}
          {children}
        </Switch>
        {routes.map(({ props: { path, component: Component, ...rest } }) => (
          <Route
            key={path}
            path={path}
            {...rest}
            render={props => (
              <Modal closeModal={closeModal} {...props}>
                <Component />
              </Modal>
            )}
          />
        ))}
      </React.Fragment>
    );
  }
}

ModalSwitch.propTypes = {
  children: PropTypes.node.isRequired,
  routes: PropTypes.arrayOf(PropTypes.node).isRequired,
  location: PropTypes.shape({}).isRequired,
  closeModal: PropTypes.func.isRequired,
};

const modalSwitchHOC = compose(
  withRouter,
  connect(null, {
    closeModal: modalActions.closeModal,
  }),
);

export default modalSwitchHOC(ModalSwitch);

const Content = styled.div`
  overflow-y: auto;
  position: absolute;
  top: 50px;
  bottom: 0px;
  width: 100%;

  ${media.tablet`
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    height: 100%;
    max-height: calc(85vh - 55px);
    overflow-y: auto;
  `};
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PopupWrapper = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  position: fixed;
  overflow: hidden;
  z-index: 40;
  background: #f3f4f5;

  ${media.tablet`
    width: 440px;
    right: initial;
    left: calc(50% - 220px);
    top: 115px;
    max-height: 84vh;
    bottom: initial;
  `};
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  font-size: 11px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
`;

const Logo = styled.div`
width: 70px;
height: 30px;
background: url('${logo}') no-repeat center / contain;
margin-left: 10px;
`;

const PopUpOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  transform: translate3d(0, 0, 0);
`;
