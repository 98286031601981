import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import styled from 'styled-components';

const PopupStyled = styled.div`
  pointer-events: all;
  cursor: default;
  text-align: center;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px;
  padding: 16px;
  width: calc(100% - 30px);
  max-width: 400px;
`;

const HeaderStyled = styled.div`
  font-family: 'Roboto', sans-serif;
  color: #181818;
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 16px;
`;

const MessageStyled = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.322;
  font-weight: 300;
  margin: 20px 0px 10px;
  text-align: left;
`;

const SubtitleStyled = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.322;
  font-weight: 300;
  margin-top: 20px;
  text-align: left;
  color: #424648;
  font-size: 13px;
  font-weight: 400;
`;

const AltMessageStyled = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.322;
  font-weight: 300;
  margin: 0px;
  color: #787d80;
  font-weight: 300;
  border-top: none !important;
  font-size: 12px;
  cursor: default;
  line-height: 125%;
  text-align: left;
  padding: 8px 0px 20px;
`;

const SecondLineStyled = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 1.322;
  font-weight: 300;
  margin: 0px;
  color: #787d80;
  font-weight: 300;
  border-top: none !important;
  font-size: 12px;
  cursor: default;
  line-height: 125%;
  text-align: left;
  padding: 10px 0px 0px;
`;

const MessageListStyled = styled.div`
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  font-weight: 300;
  border-top: none !important;
  cursor: default;
  text-align: left;
  padding: 10px 0px 0px;

  ul {
    margin-left: 12px;
    margin-bottom: 30px;
  }
  ul li {
    list-style: disc;
    font-weight: 500;
    color: black;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ActionsStyled = styled.div``;

const ButtonWrapper = styled.div`
  flex: 1 0 auto;
`;

const ButtonStyled = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  position: relative;
  background: #54a300;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  background-color: #424648;
  padding: 19px 10px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
  height: 52px;
`;

const ButtonLight = styled.button`
  cursor: pointer;
  color: rgb(66, 70, 72);
  position: relative;
  text-align: center;
  border-radius: 2px;
  background-color: transparent;
  padding: 19px 10px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  -webkit-letter-spacing: 1px;
  -moz-letter-spacing: 1px;
  -ms-letter-spacing: 1px;
  letter-spacing: 1px;
  -webkit-transition: background 0.2s ease;
  transition: background 0.2s ease;
  height: 52px;
  margin-top: 10px;
  border: 1px solid #898e90;
`;

const SpacerStyled = styled.div`
  width: 24px;
`;

const InformPopup = ({
  title,
  subtitle,
  message,
  secondLine,
  messagelist,
  buttonChoice,
  showCancelButton,
  onCloseClick,
  cancelButtonText,
  onButtonClick,
  buttonText,
}) => (
  <PopupStyled>
    <HeaderStyled>{title || ''}</HeaderStyled>
    {subtitle != '' ? (
      <>
        <SubtitleStyled>{subtitle}</SubtitleStyled>
        <AltMessageStyled>
          {message}
          {secondLine != '' && (
            <SecondLineStyled>{secondLine}</SecondLineStyled>
          )}
        </AltMessageStyled>
      </>
    ) : (
      <MessageStyled>{message}</MessageStyled>
    )}

    {messagelist.length && (
      <MessageListStyled>
        <ul>
          {messagelist.map(listItem => (
            <li>{listItem}</li>
          ))}
        </ul>
      </MessageListStyled>
    )}

    {buttonChoice ? (
      <ActionsStyled>
        <ButtonWrapper>
          <Button onClick={onButtonClick}>{buttonText}</Button>
        </ButtonWrapper>
        <ButtonWrapper>
          {showCancelButton && (
            <ButtonLight onClick={onCloseClick} primary={false}>
              {cancelButtonText}
            </ButtonLight>
          )}
        </ButtonWrapper>
      </ActionsStyled>
    ) : (
      <div>
        <ButtonStyled onClick={onButtonClick}>{buttonText}</ButtonStyled>
        {showCancelButton && (
          <ButtonStyled onClick={onCloseClick}>{cancelButtonText}</ButtonStyled>
        )}
      </div>
    )}
  </PopupStyled>
);

InformPopup.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  secondLine: PropTypes.string.isRequired,
  buttonChoice: PropTypes.bool.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  messagelist: PropTypes.array.isRequired,
};

export default InformPopup;
