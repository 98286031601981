import styled from 'styled-components';

export const InformationItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 3px;
  }
`;

export const InformationItemText = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  margin-left: 5px;
  font-weight: 300;
  max-width: 70%;
  line-height: 17px;

  span {
    text-transform: uppercase;
  }
`;
