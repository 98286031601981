import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import OrderDishesList from 'containers/OrderDishesListContainer';

import Button from 'components/Button';
import Modal from 'components/Modal';
import TouchHandler from 'components/TouchHandler';
import { getFixedPrice } from 'utils/currencyHelpers';
import Icon from 'components/Icon';

const ReviewOrder = ({
  itemsCount,
  handleCheckoutClick,
  closeModal,
  show,
  checkoutButtonEnabled,
  minimumOrderWarningDiff,
  minimumOrder,
  disclaimerText,
  
}) => (
  <>
    <Modal
      show={show}
      onCloseClick={closeModal}
      title={`Your order has ${itemsCount} ${
        itemsCount === 1 ? 'item' : 'items'
      }`}
    >
      <ScrollableContentWrapper>
        {itemsCount ? (
          <TouchHandler>
            {!!disclaimerText && (
              <Disclaimer>
                *Consuming raw or undercooked meats, poultry, seafood, shellfish
                or eggs may increase your risk of foodbourne illness.
              </Disclaimer>
            )}
            <Content>
              <OrderDishesList withScheduleTime />
            </Content>
          </TouchHandler>
        ) : (
          <EmptyCartContainer>
            <IconWrapper>
              <Icon name="icon-shopping-bag" size="140px" />
            </IconWrapper>
            <Text>Your bag is empty.</Text>
          </EmptyCartContainer>
        )}
      </ScrollableContentWrapper>
      <ButtonWrapper>
        {!!minimumOrderWarningDiff && (
          <MinimumOrderWarning>
            Minimum Order: ${getFixedPrice(minimumOrder)}
          </MinimumOrderWarning>
        )}
        <Button disabled={!checkoutButtonEnabled} onClick={handleCheckoutClick}>
          {minimumOrderWarningDiff
            ? `Add $${getFixedPrice(
                minimumOrderWarningDiff,
              )} to Proceed to Checkout`
            : 'Proceed to Checkout'}
        </Button>
      </ButtonWrapper>
    </Modal>
  </>
);

ReviewOrder.propTypes = {
  show: PropTypes.bool,
  itemsCount: PropTypes.number,
  checkoutButtonEnabled: PropTypes.bool,
  minimumOrderWarningDiff: PropTypes.number,
  handleCheckoutClick: PropTypes.func,
  closeModal: PropTypes.func,
  minimumOrder: PropTypes.number,
  disclaimerText: PropTypes.string,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: calc(100% - 83px);
`;

const Disclaimer = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #563f2c;
  text-align: center;
  padding: 13px 15px 11px;
  background-color: #fef9cd;
`;

const Content = styled.div`
  padding: 15px 15px 15px 15px;
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
`;

const MinimumOrderWarning = styled.div`
  color: red;
  padding: 2px 0 16px;
  text-align: center;
`;

const EmptyCartContainer = styled.div`
  color: #424648;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
`;

const IconWrapper = styled.div`
  height: 240px;
`;

const Text = styled.div`
  font-size: 23px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
`;

const ButtonWrapper = styled.div`
  background: #fff;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11000;
  transform: translate3d(0, 0, 0);
`;



export default ReviewOrder;
