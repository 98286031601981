import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { Title as CardTitle, Edit } from 'components/Card';
import MapAddress from 'components/MapAddress';

import { media } from 'utils/mediaQueries';

import ScheduleSettings from '../../../Checkout/containers/ScheduleSettings';
import styled from 'styled-components';

const DeliveryMethod = ({
  address,
  titleText,
  editButtonEnabled,
  onEditClick,
  method,
  ismobile,
  hidePickupBox,
}) => (
  <>
    <DeliveryMethodContainer>
      {(method === 'DELIVERY' || hidePickupBox !== true) && (
        <MapAddress
          method={method}
          titleText={titleText}
          address={address}
          editButtonEnabled={editButtonEnabled}
          onEditClick={onEditClick}
        />
      )}
      {ismobile && (
        <ScheduleSettings method={method} hidePickupBox={hidePickupBox} />
      )}
    </DeliveryMethodContainer>
    {!ismobile && (
      <ScheduleWrapper>
        <ScheduleSettings method={method} hidePickupBox={hidePickupBox} />
      </ScheduleWrapper>
    )}
  </>
);

DeliveryMethod.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    id: PropTypes.number,
    placeId: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }),
  titleText: PropTypes.string.isRequired,
  editButtonEnabled: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  ismobile: PropTypes.bool,
  hidePickupBox: PropTypes.bool,
};

DeliveryMethod.defaultProps = {
  address: {},
};

export default DeliveryMethod;

const DeliveryMethodContainer = styled.div`
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
`;

const EditWithCursor = Edit.extend`
  ${media.desktop`
    cursor: pointer;
  `};
`;

const ScheduleWrapper = styled.div`
  margin: 17px 0px;
  border-radius: 2px;
  border: ${p => p.showError && '2px solid red'};
`;
