import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import RadioButton from 'components/RadioButton';
import PaymentMethodFormHOC from 'HOC/PaymentMethodForm';

import Icon from 'components/Icon';
import CardIcon from './components/CardIcon';

const PaymentMethod = ({
  selectedPayment,
  cardMethodActive,
  cashMethodActive,
  setCardMethod,
  setCashMethod,
  showModalPayments,
  cashEnabled,
  creditCardEnabled,
  cashButtonName,
}) => (
  <Wrapper>
    <Top>
      {creditCardEnabled && (
        <Radio>
          <RadioButton
            name="Credit Card"
            active={cardMethodActive}
            onClick={setCardMethod}
            disabled={!creditCardEnabled}
          />
        </Radio>
      )}
      {cashEnabled && (
        <Radio>
          <RadioButton
            name={cashButtonName}
            active={cashMethodActive}
            onClick={setCashMethod}
            disabled={!cashEnabled}
          />
        </Radio>
      )}
    </Top>
    {cardMethodActive && (
      <div>
        {selectedPayment ? (
          <CardHolder>
            <CardName>
              <span>Name on card</span>
              {selectedPayment.name}
            </CardName>
            <Bottom>
              <Fragment>
                <CardIcon brand={selectedPayment.type} />
                <InputWrapper>
                  <HiddenNumber>····</HiddenNumber>
                  <Number>{selectedPayment.last4}</Number>
                </InputWrapper>
                <Label onClick={showModalPayments}>Change</Label>
              </Fragment>
            </Bottom>
          </CardHolder>
        ) : (
          <Bottom>
            <AddNewCardButton onClick={showModalPayments}>
              <IconWrapper>
                <Icon name="icon-credit-card-small" size="36px" />
              </IconWrapper>
              <span>Add new card</span>
            </AddNewCardButton>
          </Bottom>
        )}
      </div>
    )}
  </Wrapper>
);

PaymentMethod.propTypes = {
  selectedPayment: PropTypes.shape({
    id: PropTypes.number,
    last4: PropTypes.string,
    expiration: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
  }),
  cashEnabled: PropTypes.bool.isRequired,
  creditCardEnabled: PropTypes.bool.isRequired,
  cashButtonName: PropTypes.string.isRequired,
  cardMethodActive: PropTypes.bool.isRequired,
  cashMethodActive: PropTypes.bool.isRequired,
  setCardMethod: PropTypes.func.isRequired,
  setCashMethod: PropTypes.func.isRequired,
  showModalPayments: PropTypes.func.isRequired,
};

PaymentMethod.defaultProps = {
  selectedPayment: undefined,
};

const Wrapper = styled.div`
  background: #fff;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid #eeefef;
`;

const Radio = styled.div`
  flex: 1 1 auto;
  &:not(:first-child) {
    border-left: 1px solid #eeefef;
  }
  ${media.tablet`cursor: pointer;`} ${media.desktop`cursor: pointer;`};
`;

const Bottom = styled.div`
  padding: 0px 15px 15px 5px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

// const Icon = styled.div`
//   min-width: 40px;
//   min-height: 15px;
//   // background: url(${visaIcon}) no-repeat center / contain;
// `

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  padding: 0 15px 0 0px;
  align-items: center;
`;

const HiddenNumber = styled.span`
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  max-width: 55px;
  outline: none;
  border: none;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
`;

const Number = styled.span`
  max-width: 55px;
  outline: none;
  border: none;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  margin-left: 5px;
  margin-right: 5px;
`;

const Label = styled.button`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(84 164 0);
  text-align: right;
  cursor: pointer;
`;

const AddNewCardButton = styled.button`
  margin-left: -9px;
  font-style: italic;
  color: rgb(116, 121, 122);
  cursor: pointer;
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 15px;
  display: inline-block;
  margin-right: 6px;
  margin-left: 10px;
  margin-top: 15px;
`;

const CardName = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  span {
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
    color: #424648;
    font-weight: 300;
    display: block;
    padding-bottom: 7px;
  }
`;

const CardHolder = styled.div`
  padding-left: 35px;
`;

export default PaymentMethodFormHOC(PaymentMethod);
