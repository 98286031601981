import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { media } from 'utils/mediaQueries';
import OrderCardItem from 'routes/Orders/components/OrderCardItem';

const OrderCard = ({
  deliveryTypeFormatted,
  order: {
    cart,
    data: { legacy },
  },
  price,
  id,
  deliveryTimeRepr,
  onReorderClick,
  onViewInvoiceClick,
}) => (
  <Content>
    <Wrapper>
      <Header onClick={onViewInvoiceClick}>
        <Type>
          {deliveryTypeFormatted} <span>#{id}</span>
        </Type>
        <DateInner>{deliveryTimeRepr}</DateInner>
      </Header>

      <Items>
        {Object.values(cart).map(cartItem => (
          <OrderCardItem
            key={cartItem.id}
            cartItem={cartItem}
            lineItem={cartItem.lineItem}
            legacy={legacy}
          />
        ))}
      </Items>

      <Footer>
        <Total>Total: ${price.toFixed(2)}</Total>
        {!legacy && <Button onClick={onReorderClick}>REORDER</Button>}
      </Footer>
    </Wrapper>
  </Content>
);

OrderCard.propTypes = {
  id: PropTypes.number,
  order: PropTypes.shape({
    cart: PropTypes.object,
    data: PropTypes.object,
  }).isRequired,
  price: PropTypes.number,
  deliveryTimeRepr: PropTypes.string,
  onReorderClick: PropTypes.func.isRequired,
  deliveryTypeFormatted: PropTypes.string.isRequired,
};

OrderCard.defaultProps = {};

export default OrderCard;

const Wrapper = styled.div`
  background: #fff;
`;

const Content = styled.div``;

const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eeefef;
`;

const Type = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 500;

  span {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: rgb(66, 70, 72);
    font-weight: 300;
  }
`;

const DateInner = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const Items = styled.div``;

const Footer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 15px;
  border-top: 1px solid #eeefef;
  align-items: center;
  justify-content: space-between;
`;
const Total = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 600;
`;

const Button = styled.button`
  width: auto;
  text-transform: uppercase;
  background: #54a300;
  color: #fff;
  border-radius: 2px;
  background-color: rgb(84, 163, 0);
  padding: 12px 25px;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
  transition: background 0.2s ease;

  ${media.desktop`
    cursor: pointer;

    &:hover {
      background: #67c70f;
    }
  `};
`;
