import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Item } from 'components/Card';
import Button from 'components/Button';
import Icon from 'components/Icon';

export const OrderSummaryItem = ({
  name,
  price,
  isTaxAndFees,
  enableTaxAndFees,
  isDeliveryCost,
  enableDeliveryInfo,
}) => (
  <PriceItem
    onClick={
      isTaxAndFees
        ? enableTaxAndFees
        : isDeliveryCost
        ? enableDeliveryInfo
        : undefined
    }
  >
    <Name>
      {name}
      {(isTaxAndFees || isDeliveryCost) && <InfoIcon>i</InfoIcon>}
    </Name>
    <Price>${price.toFixed(2)}</Price>
  </PriceItem>
);

OrderSummaryItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  isTaxAndFees: PropTypes.bool,
  enableTaxAndFees: PropTypes.func,
  enableDeliveryInfo: PropTypes.func,
  isDeliveryCost: PropTypes.bool,
};

export const OrderSummaryDiscountItem = ({ name, price, removePromocode }) => (
  <DiscountItem>
    <Name>{name}</Name>
    {removePromocode && (
      <Remove onClick={removePromocode} aria-label="Remove">
        Remove
      </Remove>
    )}
    <Price>
      <Icon name="icon-promotions-small" size="30px" />
      -$
      {price.toFixed(2)}
    </Price>
  </DiscountItem>
);

OrderSummaryDiscountItem.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  removePromocode: PropTypes.func,
};

const OrderSummary = ({
  prices,
  total,
  discount,
  redeemCurrency,
  removePromocode,
  enableTaxAndFees,
  disableTaxAndFees,
  TaxAndFeesEnabled,
  taxesTotal,
  ServiceFeeName,
  serviceFeeTotal,
  enableDeliveryInfo,
  DeliveryInfoEnabled,
  disableDeliveryInfo,
}) => (
  <Wrapper>
    {prices.map((price, index) => (
      <OrderSummaryItem
        key={index}
        name={price.name}
        price={price.price}
        isTaxAndFees={price.isTaxAndFees}
        enableTaxAndFees={enableTaxAndFees}
        isDeliveryCost={price.isDeliveryCost}
        enableDeliveryInfo={enableDeliveryInfo}
      />
    ))}
    {redeemCurrency && (
      <OrderSummaryDiscountItem
        name={redeemCurrency.name}
        price={redeemCurrency.price}
      />
    )}
    {discount && (
      <OrderSummaryDiscountItem
        name={discount.name}
        price={discount.price}
        removePromocode={removePromocode}
      />
    )}
    <TotalItem>
      <Name>Total</Name>
      <Price>${total.toFixed(2)} </Price>
    </TotalItem>
    {TaxAndFeesEnabled && (
      <>
        <Container>
          <SmallTitle>What's Included?</SmallTitle>
          <PriceItemPopup>
            <Name>{ServiceFeeName}</Name>
            <Price>${serviceFeeTotal.toFixed(2)}</Price>
          </PriceItemPopup>
          <NameInfo>
            This fee varies based on factors like basket size and helps cover
            costs related to your order
          </NameInfo>
          <PriceItemPopupBorderTop>
            <Name>Taxes</Name>
            <Price>${taxesTotal.toFixed(2)}</Price>
          </PriceItemPopupBorderTop>
          <ButtonWrapper>
            <Button onClick={disableTaxAndFees} dark>
              Got it
            </Button>
          </ButtonWrapper>
        </Container>
        <Overlay onClick={disableTaxAndFees} />
      </>
    )}
    {DeliveryInfoEnabled && (
      <>
        <Container>
          <SmallTitle>What’s a delivery fee?</SmallTitle>
          <DeliveryInfoCopy>
            This fee helps cover delivery costs. The amount varies for based on
            your location and availability of nearby couriers.
          </DeliveryInfoCopy>
          <ButtonWrapper>
            <Button onClick={disableDeliveryInfo} dark>
              Got it
            </Button>
          </ButtonWrapper>
        </Container>
        <Overlay onClick={disableDeliveryInfo} />
      </>
    )}
  </Wrapper>
);

OrderSummary.propTypes = {
  prices: PropTypes.array,
  total: PropTypes.number,
  discount: PropTypes.object,
  removePromocode: PropTypes.func,
  enableTaxAndFees: PropTypes.func,
  disableTaxAndFees: PropTypes.func,
  enableDeliveryInfo: PropTypes.func,
  disableDeliveryInfo: PropTypes.func,
};

const Wrapper = styled.div`
  background: #fff;
  border-top: 1px solid #eeefef;
  padding-top: 10px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
`;

const PriceItem = styled(Item)`
  justify-content: space-between;
  color: #424648;
  font-weight: 300;
  border-top: none !important;
  font-size: 13px;
  padding: 8px 15px;
`;

const PriceItemPopup = styled(Item)`
  justify-content: space-between;
  color: #424648;
  font-size: 13px;
  cursor: default;
  margin: 0px 0px 10px 0px;
  padding: 16px 0px 0px 0px;
  font-weight: 400;
  border-top: none !important;
`;

const PriceItemPopupBorderTop = styled(Item)`
  justify-content: space-between;
  color: #424648;
  font-size: 13px;
  cursor: default;
  margin: 16px 0px;
  padding: 16px 0px 0px 0px;
  font-weight: 400;
  border-top: 1px solid #e8e8e8 !important;
`;

const NameInfo = styled(Item)`
  justify-content: space-between;
  color: #787d80;
  font-weight: 300;
  border-top: none !important;
  font-size: 12px;
  padding: 0px;
  cursor: default;
  max-width: 300px;
  margin-top: 0px;
  line-height: 125%;
`;

const DiscountItem = styled(Item)`
  justify-content: space-between;
  color: rgb(79, 154, 0);
  font-weight: 400;
  padding: 8px 15px;
  border-top: none !important;
  font-size: 15px;
  position: relative;
`;

const TotalItem = styled(Item)`
  justify-content: space-between;
  border-top: none !important;
  padding: 8px 15px;
  color: #424648;
  font-weight: 300;
  font-size: 13px;
  padding-bottom: 15px;
`;

const Name = styled.div``;

const Price = styled.div`
  .icon-promotions-small {
    top: 6px;
    left: -20px;
  }
`;

const Remove = styled.div`
  font-size: 11px;
  font-family: 'Montserrat', sans-seif;
  color: #c93b32;
  line-height: 24px;
  font-weight: 300;
  display: block;
  position: absolute;
  right: 115px;
`;

const InfoIcon = styled.button`
  background: #adadad;
  font-size: 11px;
  float: right;
  border-radius: 10px;
  width: 11px;
  height: 11px;
  color: white;
  text-align: center;
  font-weight: bold;
  line-height: 11px;
  margin-left: 5px;
  margin-top: 1px;
`;

const Container = styled.div`
  padding: 16px;
  z-index: 1020;
  background-color: #ffffff;
  position: fixed !important;
  top: 50%;
  right: 15px;
  left: 15px;
  margin-top: -100px;
  border-radius: 3px;
`;

const SmallTitle = styled.div`
  text-align: center;
  font-size: 17px;
  line-height: 20px;
  font-weight: 400;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 16px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  content: '';
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  cursor: pointer;
`;

const DeliveryInfoCopy = styled.div`
  font-size: 13px;
  color: #424648;
  padding: 10px;
  font-weight: 300;
  line-height: 20px;
`;

export default OrderSummary;
