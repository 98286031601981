import React from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';

import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import cleanPhoneNumber from 'utils/cleanPhoneNumber';

// eslint-disable-next-line no-unused-vars
const TextMaskCustom = ({ inputRef, ...rest }) => (
  <MaskedInput
    {...rest}
    mask={[
      '+',
      '1',
      ' ',
      '(',
      /[2-9]/,
      // /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    // eslint-disable-next-line react/jsx-curly-brace-presence
    placeholderChar={'\u2000'}
    showMask={false}
    guide={false}
  />
);

TextMaskCustom.propTypes = {};

const PhoneNumberField = ({ label, fullWidth, error, helperText, ...rest }) => (
  <FormControl fullWidth={fullWidth}>
    <InputLabel htmlFor="formatted-text-mask-input" error={error}>
      {label}
    </InputLabel>
    <Input
      id="formatted-text-mask-input"
      placeholder="+1 (999) 999-9999"
      inputComponent={TextMaskCustom}
      error={error}
      {...rest}
      value={rest.value}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </FormControl>
);

PhoneNumberField.propTypes = {
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
};

PhoneNumberField.defaultProps = {
  helperText: '',
};

const phoneNumberFieldHOC = compose(
  withHandlers({
    onChange: ({ onChange }) => e => {
      onChange(e.target.value);
    },
  }),
);

export default phoneNumberFieldHOC(PhoneNumberField);
