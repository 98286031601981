import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';

import Icon from 'components/Icon';
import { getFixedPrice } from 'utils/currencyHelpers';

import FixedNumber from 'components/FixedNumber';
import CartItem from './containers/CartItem';

export const DesktopDishesList = ({ dishes, readOnly }) => (
  <Items style={{ background: 'white' }}>
    {dishes.map(orderDishId => (
      <Item key={orderDishId}>
        <CartItem counterType="COUNTER" id={orderDishId} readOnly={readOnly} />
      </Item>
    ))}
  </Items>
);

DesktopDishesList.propTypes = {
  dishes: PropTypes.array,
  readOnly: PropTypes.bool,
};

const DesktopCart = ({
  opened,
  toggleHandler,
  itemsCount,
  subtotal,
  minimumOrder,
  dishes,
  deliveryTimeText,
  checkoutButtonEnabled,
  minimumOrderWarningDiff,
  closeCart,
  handleCheckoutClick,
}) => (
  <Wrapper active={opened} onClick={closeCart} aria-hidden={!opened}>
    <Opacity />
    <Container>
      <FocusLock disabled={!opened}>
        <Inner onClick={event => event.stopPropagation()}>
          <Header>
            <Text>{`Your order has ${itemsCount} ${
              itemsCount === 1 ? 'item' : 'items'
            }`}</Text>
            <CloseIcon onClick={toggleHandler} aria-label="Close">
              <Icon name="icon-cross-small" size="34px" />
            </CloseIcon>
          </Header>
          <Delivery>
            <IconWrapper>
              <Icon name="icon-soonest-available-small" size="28px" />
            </IconWrapper>
            <Label>{deliveryTimeText}</Label>
          </Delivery>
          <ItemsWrapper>
            <Items>
              {dishes.map(orderDishId => (
                <Item key={orderDishId}>
                  <CartItem id={orderDishId} closeCart={closeCart} />
                </Item>
              ))}
            </Items>

            <Total>
              <Title>Subtotal</Title>
              <Price>
                $<FixedNumber>{subtotal}</FixedNumber>
              </Price>
            </Total>
            {!!minimumOrderWarningDiff && (
              <MinimumOrderWarning>
                Minimum Order: ${getFixedPrice(minimumOrder)}
              </MinimumOrderWarning>
            )}
            <ButtonWrapper>
              <Button
                disabled={
                  !checkoutButtonEnabled ||
                  deliveryTimeText == 'Select delivery option'
                }
                to="/checkout"
                onClick={handleCheckoutClick}
              >
                <ButtonLabel disabled={!checkoutButtonEnabled}>
                  {minimumOrderWarningDiff
                    ? `Add $${getFixedPrice(
                        minimumOrderWarningDiff,
                      )} to Proceed to Checkout`
                    : 'Checkout'}
                </ButtonLabel>
              </Button>
            </ButtonWrapper>
          </ItemsWrapper>
        </Inner>
      </FocusLock>
    </Container>
  </Wrapper>
);

DesktopCart.propTypes = {
  opened: PropTypes.bool,
  toggleHandler: PropTypes.func,
  itemsCount: PropTypes.number,
  subtotal: PropTypes.number,
  minimumOrder: PropTypes.number,
  deliveryTimeText: PropTypes.string,
  checkoutButtonEnabled: PropTypes.bool,
  minimumOrderWarningDiff: PropTypes.number,
  dishes: PropTypes.array,
  closeCart: PropTypes.func,
  handleCheckoutClick: PropTypes.func,
  handleEditTimeClick: PropTypes.func,
};

DesktopCart.defaultProps = {
  itemsCount: 0,
  subtotal: 0,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: ${({ active }) => (active ? '1' : '0')};
  pointer-events: ${({ active }) => (active ? 'initial' : 'none')};
  transition: opacity 0.2s ease;
  will-change: opacity;
`;

const Opacity = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
  z-index: 10;
`;

const Header = styled.div`
  padding: 28.5px 22px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const CloseIcon = styled.button`
  width: 20px;
  height: 20px;
  color: #545859;
  text-align: left;
  cursor: pointer;
`;

const Items = styled.div``;

const Item = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
`;

const Text = styled.div`
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 12px;
  color: #545859;
`;

const Inner = styled.div`
  background: #fff;
  width: 440px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  right: 0;
  top: 0;
`;

const Delivery = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 25px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`;

const Label = styled.span`
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(84, 163, 0);
  text-decoration: underline;
  margin-left: 10px;
  font-weight: 300;
`;

const Total = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background: #f7f8f8;
  padding: 15px 20px;
`;

const MinimumOrderWarning = styled.div`
  color: red;
  padding: 16px 0 2px;
  text-align: center;
`;

const Title = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const Price = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
`;

const ItemsWrapper = styled.div`
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  background: #fff;
  padding: 15px;
  z-index: 2;
  width: 100%;
`;

const Button = styled.button`
  white-space: nowrap;
  background: ${p => (p.disabled ? '#d2d3d3' : '#54a300')};
  text-align: center;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  position: relative;
  padding: 25px 15px;
  display: block;
  transition: background 0.2s ease;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: ${props => (props.disabled ? '#d2d3d3' : '#67c70f')};
  }
`;

const ButtonLabel = styled.div`
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: ${p => !p.disabled && '1px 1px 1px #4a860c'};
  color: ${p => (p.disabled ? 'rgb(66, 70, 72)' : '#fff')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default DesktopCart;
