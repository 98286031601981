import React from 'react';
import { ToastContainer, toast } from 'react-toastify';

const Notifications = () => (
  <ToastContainer
    style={{position:'relative', zIndex: 2147483648}}
    position={toast.POSITION.TOP_CENTER}
    autoClose={false}
    closeOnClick={false}
  />
);

export default Notifications;
