import { createAction, handleActions } from 'redux-actions';
import { camelizeKeys, decamelizeKeys } from 'humps';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { history } from 'routes';
import wretches from 'wretches';
import inform from 'utils/inform';
import informDuplicated from 'utils/inform/informDuplicated';
import upsellPopup from 'utils/inform/upsellPopup';
import { actions as modalActions } from 'components/ReduxModal';
import { actions as spinnerActions } from 'redux/modules/spinner';
import { actions as cartActions } from 'redux/modules/cart';
import { actions as deliveryActions } from 'redux/modules/delivery/reducer';
import backendProductsToCart from 'utils/backendProductsToCart';
import differenceInSeconds from 'date-fns/difference_in_seconds';
import { getNowTime } from 'utils/timeHelpers';
import { actions as paymentsActions } from 'redux/modules/payments';
import revalidateSelectedTime from 'redux/modules/delivery/revalidateSelectedTime';

// ---
// CONSTANTS
// ---
export const GET_ORDERS = 'orders/GET_ORDERS';
export const ADD_ORDER = 'orders/ADD_ORDER';

// ---
// ACTION CREATORS
// ---
const getOrders = createAction(GET_ORDERS);
export const addOrder = createAction(ADD_ORDER);

// ---
// INITIAL STATE
// ---
const initialState = {
  entities: {},
  fulfilled: false,
};

// ---
// REDUCER
// ---

export default handleActions(
  {
    [ADD_ORDER]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        [action.payload.clientMeta.id]: action.payload,
      },
    }),
    [GET_ORDERS]: (state, action) => ({
      ...state,
      entities: {
        ...state.entities,
        ...mapValues(action.payload, (order, key) => ({
          ...order,
          clientMeta: get(state.entities, [key, 'clientMeta']),
        })),
      },
      fulfilled: true,
    }),
  },
  initialState,
);

export const getOrdersList = (page = 0) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.ordersList
    .query({
      page,
    })
    .get()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      const camelcased = camelizeKeys(data);
      const normalized = keyBy(camelcased, 'orderId');
      dispatch(getOrders(normalized));
    })
    .catch(err => {
      console.log(err.json);
      dispatch(spinnerActions.hideSpinner());
    });
};

export const getOrderInvoice = orderId => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.orderInvoice
    .post({ id: orderId })
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      const camelcased = camelizeKeys(data);
      const normalized = keyBy(camelcased, 'orderId');
      dispatch(getOrders(normalized));
    })
    .catch(err => {
      history.push('/menu');
      console.log(err.json);
      dispatch(spinnerActions.hideSpinner());
    });
};

const reviewOrder = (
  body,
  method,
  dsktp = false,
  userLoggedIn = true,
  proceeedToCheckout = false,
  upsellItems = [],
) => dispatch => {
  // / for mobile, when user is not logged in, got to sign in
  if (!userLoggedIn) {
    if (dsktp == false)
      history.push({
        pathname: '/sign-in',
        state: {
          modal: true,
          afterSignInDo: [
            { action: 'showModal', args: ['review-order'] },
            { action: 'historyPush', args: ['/menu'] },
          ],
        },
      });
    else
      history.push({
        pathname: '/sign-in',
        state: {
          modal: true,
          afterSignInDo: [{ action: 'historyPush', args: ['/menu'] }],
        },
      });

    return;
  }

  dispatch(spinnerActions.showSpinner());
  wretches.review
    .json(decamelizeKeys(body))
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      const res = camelizeKeys(json);
      const backendFee = {
        feeAmount: res.serviceFee,
        feeName: res.name,
        feeAppliesTo: res.appliesTo,
      };
      dispatch(
        cartActions.setServiceFee({
          backendFee,
        }),
      );
      if (userLoggedIn) {
        if (dsktp == false) {
          if (proceeedToCheckout || res.upsellItems.length == 0)
            dispatch(modalActions.showModal('checkout'));
          else if (res.upsellItems && res.upsellItems.length > 0) {
            // validate if upsells ids are in notInCartCheckoutIds
            // get from upsellItems
            upsellItems = upsellItems.filter(item =>
              res.upsellItems.includes(item.id),
            );

            if (upsellItems.length == 0) {
              dispatch(modalActions.showModal('checkout'));
            } else {
              const showDishAction = promoItemId => {
                dispatch(cartActions.setUpsellItemsPopupHidden(1));
                dispatch(
                  modalActions.showModal('dish-details', {
                    dishId: promoItemId,
                  }),
                );
              };

              const onDoNotShowAction = (itemId, status) => {
                dispatch(doNotShowAgain({ product_id: itemId, status }));
                upsellPopup({
                  upsellItems,
                  onBeforeCloseClick: closeAction,
                  markedAsDoNotShow: status,
                  showAnimation: false,
                  onProceedToCheckout,
                  onBeforeButtonClick: showDishAction,
                  onDoNotShowAgain: onDoNotShowAction,
                  onNoThanksAction,
                });
              };

              const onNoThanksAction = () => {
                dispatch(cartActions.setUpsellItemsPopupHidden(3));
                dispatch(modalActions.showModal('checkout'));
              };

              const onProceedToCheckout = () => {
                dispatch(modalActions.showModal('checkout'));
              };

              const closeAction = () => {
                dispatch(cartActions.setUpsellItemsPopupHidden(1));
              };

              upsellPopup({
                upsellItems,
                markedAsDoNotShow: false,
                onBeforeCloseClick: closeAction,
                onBeforeButtonClick: showDishAction,
                onNoThanksAction,
                onDoNotShowAgain: onDoNotShowAction,
              });
            }
          }
        } else history.push('/checkout');
      } else if (dsktp == false) {
        history.push({
          pathname: '/sign-in',
          state: {
            modal: true,
            afterSignInDo: [
              { action: 'showModal', args: ['review-order'] },
              { action: 'historyPush', args: ['/menu'] },
            ],
          },
        });
      } else {
        history.push({
          pathname: '/sign-in',
          state: {
            modal: true,
            afterSignInDo: [{ action: 'historyPush', args: ['/checkout'] }],
          },
        });
      }
    })
    .catch(err => {
      console.log(err);
      dispatch(spinnerActions.hideSpinner());
      console.log(err.json);

      const recheckNoneActn = () => {
        dispatch(recheckNonce(body, method));
      };
      inform({
        title: 'Oops...',
        message: err.json.message,
        buttonText: 'OK',
        onBeforeButtonClick: recheckNoneActn,
      });
    });
};

const doNotShowAgain = (body, dsktp = false) => dispatch => {
  wretches.doNotShowAgain
    .json(decamelizeKeys(body))
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      const res = camelizeKeys(json);
      console.log(res);
    })
    .catch(err => {
      console.log(err);
    });
};

const createOrder = (
  body,
  method,
  revalidate = true,
  duplicated = false,
  attempts = 0,
) => dispatch => {
  const stillValid = dispatch(revalidateSelectedTime(history));

  if (!stillValid) return;

  dispatch(spinnerActions.showSpinner());
  wretches.checkout
    .json(decamelizeKeys(body))
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      const res = camelizeKeys(json);
      if (res.result) {
        dispatch(modalActions.closeModal());
        dispatch(
          addOrder({
            ...res.orderInformation,
            clientMeta: {
              id: res.orderInformation.orderId,
              method,
              response: res,
              request: body,
            },
          }),
        );
        history.push(`/orders/thank-you/${res.orderInformation.orderId}`);
        setTimeout(() => dispatch(cartActions.finishOrder()), 1000);
        setTimeout(() => dispatch(paymentsActions.selectPayment(0)), 1000);
      }
      console.log(json);
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());

      const recheckNoneActn = () => {
        dispatch(recheckNonce(body, method));
      };

      const openPaymentsActn = () => {
        dispatch(
          paymentsActions.updatePayments({
            json: err.json.message,
            id: body.selectedCc,
          }),
        );
        dispatch(
          modalActions.showModal('payments', { paymentsSelectable: true }),
        );
      };

      const onScheduleActn = () => {
        body.method.deliveryType = 'schedule';
        dispatch(createOrder(body, method, false));
      };

      const resetScheduleTime = () => {
        dispatch(deliveryActions.setScheduleTime(null));
        dispatch(deliveryActions.setDeliveryTimeType(null));
        dispatch(modalActions.closeModal());
        history.push('/');
      };

      const onCloseClick = () => {
        dispatch(deliveryActions.setScheduleTime(null));
        dispatch(deliveryActions.setDeliveryTimeType(null));
        dispatch(cartActions.clearCart());
        dispatch(modalActions.closeModal());
        history.push('/');
        window.location.reload();
      };

      const onConfirmDuplicateClick = () => {
        body.confirm_duplicated = true;
        dispatch(createOrder(body, method, false));
      };

      const onKeepOrderClick = () => {
        body.confirm_duplicated = false;
        dispatch(createOrder(body, method, false));
      };

      if (typeof err.json.message === 'object') {
        console.log(err);
        if (err.json.message.type && err.json.message.type == 'delivery_time') {
          body.method.date = err.json.message.next;

          if (err.json.message.next) {
            inform({
              title: 'The next available time to order is:',
              subtitle: err.json.message.message,
              message: 'Click "Continue" to accept this time OR change time.',
              buttonText: 'Continue',
              onBeforeButtonClick: onScheduleActn,
              showCancelButton: true,
              buttonChoice: true,
              cancelButtonText: 'Change Time',
              onBeforeCloseClick: resetScheduleTime,
              scheduleStyles: true,
            });
          } else {
            inform({
              title: '',
              subtitle: 'Your session has expired.',
              buttonText: 'Start a new order',
              onBeforeButtonClick: onCloseClick,
              showCancelButton: false,
              buttonChoice: false,
              onBeforeCloseClick: onCloseClick,
              scheduleStyles: true,
            });
          }
        } else {
          // first check if type is "duplicated"
          if (err.json.message.type && err.json.message.type == 'duplicated') {
            if (duplicated) {
              // if duplicated is true, then the user has already confirmed the duplication
              body.confirm_duplicated = false;
              dispatch(createOrder(body, method, false));
            } else {
              informDuplicated({
                title: '',
                subtitle: 'Order Already Placed',
                message:
                  'It looks like this order has already been submitted. To avoid a duplicate order, please confirm your next step.',
                buttonText: 'Review Order',
                onBeforeButtonClick: onKeepOrderClick,
                showCancelButton: true,
                buttonChoice: true,
                cancelButtonText: 'Place a New Order',
                scheduleStyles: true,
                onBeforeCloseClick: onConfirmDuplicateClick,
              });
            }
          } else {
            // first check if type is "processing"
            if (
              err.json.message.type &&
              err.json.message.type == 'processing'
            ) {
              if (attempts < 5) {
                dispatch(createOrder(body, method, false, true, attempts + 1));
              } else {
                inform({
                  title: 'Oops',
                  message:
                    "We're sorry, but we are unable to process your order at this time. Please try again later.",
                  buttonText: 'OK',
                });
              }
            } else {
              inform({
                title: 'Payment Failed',
                subtitle: err.json.message.message[0],
                message: err.json.message.message[1],
                secondLine: err.json.message.message[2]
                  ? err.json.message.message[2]
                  : '',
                buttonText: 'OK',
                onBeforeButtonClick: openPaymentsActn,
              });
            }
          }
        }
      } else {
        inform({
          title: 'Oops',
          message: err.json.message,
          buttonText: 'OK',
        });
      }
    });
};

const feeInform = (nonce, serviceFeeTotal, cartSubtotal) => dispatch => {
  wretches.feereview
    .json(
      decamelizeKeys({
        nonce,
        fee: serviceFeeTotal,
        subtotal: cartSubtotal,
      }),
    )
    .post()
    .json(data => {
      console.log(data);
    })
    .catch(err => {
      console.log(err.json);
    });
};

const reorder = ({
  orderId,
  type,
  deliveryTimeType,
  backendScheduleTime,
  placeId,
  history,
}) => dispatch => {
  if (type && deliveryTimeType && backendScheduleTime) {
    dispatch(spinnerActions.showSpinner());
    wretches.reorder
      .json(
        decamelizeKeys({
          id: orderId,
          orderMethod: type.toLowerCase(),
          orderType: deliveryTimeType.toLowerCase(),
          orderDate: backendScheduleTime,
          placeId,
        }),
      )
      .post()
      .json(json => {
        const {
          error,
          cart: { products },
        } = json;
        dispatch(spinnerActions.hideSpinner());
        if (!isEmpty(error)) {
          dispatch(deliveryActions.cancelReorderMode());
          const restartOrderActn = () => {
            history.push(`/`);
            dispatch(modalActions.closeModal());
          }
          inform({
            title: "Oops",
            message: error.body,
            buttonText: 'Start new order',
            onBeforeButtonClick: restartOrderActn,
            showCancelButton: false,
            buttonChoice: true,
            scheduleStyles: false,
          });
        }
        if (products.length) {
          dispatch(
            cartActions.appendCartItems(backendProductsToCart(products)),
          );
          dispatch(modalActions.closeModal());
          history.push('/');
          history.push('/menu/cart');
          dispatch(deliveryActions.cancelReorderMode());
        }
      })
      .catch(err => {
        dispatch(spinnerActions.hideSpinner());
        console.log(err.json);
        inform({
          title: 'Oops...',
          message: err.json && err.json.message,
          buttonText: 'OK',
        });
      });
  } else {
    // set reorder mode
    dispatch(deliveryActions.setReorderMode(orderId));
    history.push('/');
    dispatch(modalActions.closeModal());
    // go to delivery page
  }

  //
  // Response:
  // {
  //      "cart": cart object
  //      "method":
  //      "error" array with errors (invalid products, variations, options)
  // }
};

const recheckNonce = (body, method) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.checkout
    .json(decamelizeKeys(body))
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      const res = camelizeKeys(json);
      if (res.result) {
        dispatch(modalActions.closeModal());
        dispatch(
          addOrder({
            ...res.orderInformation,
            clientMeta: {
              id: res.orderInformation.orderId,
              method,
              response: res,
              request: body,
            },
          }),
        );
        history.push(`/orders/thank-you/${res.orderInformation.orderId}`);
        setTimeout(() => dispatch(cartActions.finishOrder()), 1000);
      }
      console.log(json);
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
    });
};

const checkOrderAge = orderInitiatedAt => dispatch => {
  const minutesSinceOrderCreated =
    differenceInSeconds(getNowTime(), orderInitiatedAt) / 60;
  if (minutesSinceOrderCreated > 50) {
    const restartOrderActn = () => {
      history.push(`/`);
      dispatch(modalActions.closeModal());
    };
    inform({
      title: 'Your session has expired',
      message: 'Please start Again',
      buttonText: 'Start new order',
      onBeforeButtonClick: restartOrderActn,
    });
    return false;
  }
  return true;
};

export const actions = {
  addOrder,
  createOrder,
  reviewOrder,
  getOrdersList,
  reorder,
  recheckNonce,
  checkOrderAge,
  feeInform,
  getOrderInvoice,
  doNotShowAgain,
};
