import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { actions as modalActions } from 'components/ReduxModal';
import { selectedPaymentSelector } from 'redux/modules/payments';
import {
  paymentMethodSelector,
  actions as cartActions,
} from 'redux/modules/cart';
import { paymentMethodsSelector } from 'redux/modules/general';
import { deliveryTypeCashButtonFormattedSelector } from 'selectors/deliveryTypeCashButtonFormattedSelector';
import { actions as userActions } from 'redux/modules/user';
import dataFetcher from 'HOC/dataFetcher';
import withConditionalCDM from 'HOC/withConditionalCDM';
import { deliverySelector } from 'redux/modules/delivery';

const PaymentMethodFormHOC = compose(
  dataFetcher(state => !state.payments.fulfilled, userActions.userInfo),
  connect(
    state => ({
      selectedPayment: selectedPaymentSelector(state),
      activeMethod: paymentMethodSelector(state),
      paymentMethods: paymentMethodsSelector(state),
      cashButtonName: deliveryTypeCashButtonFormattedSelector(state),
      delivery: deliverySelector(state),
    }),
    {
      showModal: modalActions.showModal,
      setCashMethod: cartActions.setPaymentMethodCash,
      setCardMethod: cartActions.setPaymentMethodCard,
    },
  ),
  withProps(
    ({
      paymentMethods: { cashEnabled, creditCardEnabled },
      activeMethod,
      delivery,
    }) => ({
      cardMethodActive: activeMethod === 'CARD',
      cashMethodActive: activeMethod === 'CASH',
      cashEnabled: cashEnabled && delivery.rewardLimit === undefined,
      creditCardEnabled,
    }),
  ),
  withConditionalCDM(
    ({ cashEnabled, creditCardEnabled }) => cashEnabled && !creditCardEnabled,
    ({ setCashMethod }) => {
      setCashMethod();
    },
  ),
  withConditionalCDM(
    ({ cashEnabled, creditCardEnabled }) => !cashEnabled && creditCardEnabled,
    ({ setCardMethod }) => {
      setCardMethod();
    },
  ),
  withHandlers({
    showModalPayments: ({ showModal }) => () => {
      showModal('payments', { paymentsSelectable: true });
    },
  }),
);

export default PaymentMethodFormHOC;
