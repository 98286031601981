import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import {
  compose,
  withProps,
  withHandlers,
  withStateHandlers,
  lifecycle,
} from 'recompose';
import { withRouter } from 'react-router';
import sumBy from 'lodash/sumBy';
import pick from 'lodash/pick';
import throttle from 'lodash/throttle';
import {
  actions as dishConfiguratorActions,
  configuredDishSelector,
  dishConfiguratorSelector,
  dishVariationsWithOptionsSelector,
  configuredDishVariationsSelector,
  configuredDishPriceSelector,
} from 'redux/modules/dishConfigurator';
import { cartItemsSelector, actions as cartActions } from 'redux/modules/cart';
import { actions as modalActions, modalSelector } from 'components/ReduxModal';
import {
  actions as favoritesActions,
  favoriteEntitiesSelector,
} from 'redux/modules/favorites';
import { isUserLoggedInSelector } from 'redux/modules/user';
import inform from 'utils/inform';
import DishDetails from '../components/DishDetails';
import { hideSpecialInstructionsSelector } from 'redux/modules/general';
import { getDeliveryType } from 'selectors/getDeliveryType';
import ownDriversSelector from 'selectors/ownDriversSelector';

const DishDetailsHOC = compose(
  withRouter,
  connect(
    state => ({
      modal: modalSelector(state),
      dish: configuredDishSelector(state),
      variations: dishVariationsWithOptionsSelector(state),
      configuration: dishConfiguratorSelector(state),
      configuredDishVariations: configuredDishVariationsSelector(state),
      favorites: favoriteEntitiesSelector(state),
      cartItems: cartItemsSelector(state),
      isUserLoggedIn: isUserLoggedInSelector(state),
      dishPrice: configuredDishPriceSelector(state),
      hideSpecialInstructions: hideSpecialInstructionsSelector(state),
      deliveryType: getDeliveryType(state),
      ownDrivers: ownDriversSelector(state),
    }),
    {
      addItem: cartActions.addItem,
      updateItem: cartActions.updateItem,
      updateVariation: dishConfiguratorActions.updateVariation,
      updateConfiguration: dishConfiguratorActions.updateConfiguration,
      create: dishConfiguratorActions.setUp,
      clearDishConfigurator: dishConfiguratorActions.clear,
      showModal: modalActions.showModal,
      modalGoBack: modalActions.modalGoBack,
      addFavorite: favoritesActions.addFavorite,
      removeFavorite: favoritesActions.removeFavorite,
    },
  ),
  withProps(({ modal: { payload = {} } }) => ({
    cartItemId: payload && payload.cartItemId,
    dishId: payload && payload.dishId,
  })),
  withStateHandlers(
    { submitButtonTouched: false },
    {
      touchSubmitButton: () => () => ({ submitButtonTouched: true }),
      untouchSubmitButton: () => () => ({ submitButtonTouched: false }),
    },
  ),
  withHandlers(() => {
    let contentNode = null;
    return {
      onContentNodeRef: () => ref => {
        contentNode = ref;
      },
      // To prevent rerendering
      handleScroll: () =>
        throttle(() => {
          if (contentNode) {
            const { top } = contentNode.getBoundingClientRect();
            const opacity = Math.min(-top, 150) / 150;
            const transparency = 1 - opacity;
            [...document.getElementsByClassName('js-opacity-receiver')].forEach(
              el => {
                el.style.opacity = opacity;
              },
            );
            [
              ...document.getElementsByClassName('js-transparency-receiver'),
            ].forEach(el => {
              el.style.opacity = transparency;
            });
            [
              ...document.getElementsByClassName(
                'js-interpolated-color-receiver',
              ),
            ].forEach(el => {
              el.style.color = `rgb(${Math.round(
                255 * transparency,
              )},${Math.round(255 * transparency)},${Math.round(
                255 * transparency,
              )})`;
            });
          }
        }, 50),
    };
  }),
  withHandlers(() => {
    let scrollableNode = null;
    const variationRefs = {};
    return {
      onScrollableNodeRef: () => ref => {
        scrollableNode = ref;
      },
      onVariationRef: () => id => ref => {
        if (ref) {
          variationRefs[id] = ref;
        }
      },
      resetVariationRefs: () => () => {
        Object.keys(variationRefs).forEach(k => {
          delete variationRefs[k];
        });
      },
      scrollToInvalidVariation: ({ configuredDishVariations }) => () => {
        // eslint-disable-next-line no-unused-vars
        const invalidVariation = configuredDishVariations.find(
          ({ valid }) => !valid,
        );
        if (invalidVariation) {
          const invalidVariationRef = variationRefs[invalidVariation.id];
          // eslint-disable-next-line react/no-find-dom-node
          const invalidVariationDOMNode = findDOMNode(invalidVariationRef);
          // eslint-disable-next-line react/no-find-dom-node
          const container = findDOMNode(scrollableNode);
          const offset = invalidVariationDOMNode.offsetTop;
          container.style['-webkit-overflow-scrolling'] = 'auto';
          container.scrollTop = offset - 200;
          container.style['-webkit-overflow-scrolling'] = 'touch';
        }
      },
    };
  }),
  lifecycle({
    // HACK. Because we actually never unmount dish details modal.
    componentWillReceiveProps(nextProps) {
      if (
        (nextProps.show && !this.props.show) ||
        (!nextProps.show && this.props.show)
      ) {
        const { resetVariationRefs } = this.props;
        resetVariationRefs();
      }
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { untouchSubmitButton, cartItems, create } = this.props;
      const { dishId, cartItemId } = nextProps;
      if (
        (dishId || cartItemId) &&
        (dishId !== this.props.dishId || cartItemId !== this.props.cartItemId)
      ) {
        if (dishId) {
          create({
            dishId,
            specialInstructions: '',
            count: 1,
            variations: {},
          });
        } else {
          create(
            pick(cartItems[cartItemId], [
              'dishId',
              'specialInstructions',
              'count',
              'variations',
            ]),
          );
        }
        untouchSubmitButton();
      }
    },
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const {
        dish: { minCount },
        configuration: { count },
        updateConfiguration,
      } = nextProps;
      if (count < minCount) {
        updateConfiguration({ count: minCount });
      }
    },
  }),
  withProps(({ dish, configuredDishVariations, favorites, cartItemId }) => ({
    image: dish.photoUrl,
    variationsPrice: sumBy(
      Object.values(configuredDishVariations),
      ({ variationPrice }) => variationPrice,
    ),
    isFavorite: favorites.includes(dish.id),
    mode: cartItemId ? 'UPDATE' : 'CREATE',
  })),
  withProps(({ configuration, dish, variationsPrice, mode }) => ({
    price: configuration.count * (+dish.price + variationsPrice),
    submitButtonText: {
      [true]: mode,
      [mode === 'CREATE']: `Add ${configuration.count} to Order`,
      [mode === 'UPDATE']: 'Update',
    }.true,
  })),
  withProps(({ configuredDishVariations }) => ({
    isValid: Object.values(configuredDishVariations).every(
      ({ valid }) => valid,
    ),
  })),
  withProps(({ isValid, configuredDishVariations }) => {
    if (!isValid) {
      let variation;
      // eslint-disable-next-line no-restricted-syntax
      for (variation of Object.values(configuredDishVariations)) {
        const { type, optionsLength } = variation;
        if (type === 'mix_and_match' && optionsLength === 0) {
          return {
            mixAndMatchValidationError:
              'Please select two different half to make a full pizza.',
          };
        } else if (type === 'mix_and_match' && optionsLength === 1) {
          return {
            mixAndMatchValidationError:
              'Only one side have been chosen. \n' +
              'You need to choose two sides to make a full pizza.',
          };
        }
      }
    }
    return { mixAndMatchValidationError: undefined };
  }),
  withHandlers({
    showFlavorsModal: ({ showModal, cartItemId, dishId }) => payload => {
      showModal('flavors', {
        ...(cartItemId && { cartItemId }),
        ...(dishId && { dishId }),
        ...payload,
      });
    },
    showLoginModal: ({ history }) => () => {
      history.push({
        pathname: '/sign-in',
        state: {
          modal: true,
        },
      });
    },
  }),
  withHandlers({
    onAddClick: ({ addItem, updateItem, cartItemId }) => (mode, payload) => {
      if (mode === 'CREATE') {
        addItem(payload);
      } else {
        updateItem({ ...payload, id: cartItemId });
      }
    },
    closeDishDetailsModal: ({ modalGoBack, clearDishConfigurator }) => () => {
      modalGoBack();
      clearDishConfigurator();
    },
    onFavoriteClick: ({
      isUserLoggedIn,
      showLoginModal,
      addFavorite,
      removeFavorite,
    }) => (dishId, isFavorite) => {
      if (!isUserLoggedIn) {
        showLoginModal();
      }
      if (isFavorite) {
        removeFavorite(dishId);
      } else {
        addFavorite(dishId);
      }
    },
    updateCounter: ({
      configuration,
      dish: { minCount },
      updateConfiguration,
    }) => val => {
      const count = Math.max(configuration.count + val, minCount);
      updateConfiguration({ count });
    },
  }),
  withProps(({ price, isValid }) => ({
    submitButtonActive: !!price && isValid,
  })),
  withHandlers({
    handleSpecialInstructionsChange: ({ updateConfiguration }) => value => {
      updateConfiguration({ specialInstructions: value });
    },
    handleSubmitClick: ({
      mode,
      configuration,
      onAddClick,
      closeDishDetailsModal,
      isValid,
      mixAndMatchValidationError,
      touchSubmitButton,
      scrollToInvalidVariation,
    }) => () => {
      touchSubmitButton();
      if (mixAndMatchValidationError) {
        inform({
          title: 'Oops...',
          message: mixAndMatchValidationError,
          buttonText: 'OK',
        });
      }
      if (isValid) {
        onAddClick(mode, configuration);
        closeDishDetailsModal();
      } else {
        scrollToInvalidVariation();
      }
    },
  }),
);

export default DishDetailsHOC(DishDetails);
