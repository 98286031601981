import React, { Fragment } from 'react';
import Card, { Edit } from 'components/Card';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import StaticMapService from 'utils/StaticMapService';

import Icon from 'components/Icon';
import { reformatAddress } from 'utils/reformatAddress';

import { media } from 'utils/mediaQueries';

const MAP_SIZE_X = 120;
const MAP_SIZE_Y = 400;

const MapAddress = ({
  address: {
    name,
    saveAs,
    aptSuiteFloor,
    place,
    address,
    deliveryAddress,
    fullAddress,
    city,
    zipcode,
    addressDetails,
    businessName,
    notes,
  },
  withIcon,
  editButtonEnabled,
  onEditClick,
  titleText,
  method,
}) => {
  const mapService = new StaticMapService(MAP_SIZE_X, MAP_SIZE_Y);
  const addr = address || deliveryAddress || '';

  const delivertoTitle =
    method === 'DELIVERY'
      ? name
        ? 'Delivery To:'
        : 'Delivery Address:'
      : 'Pickup Address:';
  if (method === 'PICKUP') name = '';

  return (
    <Wrapper>
      <Information tabIndex={0}>
        <Title>
          {withIcon && (
            <SmallIconWrapper>
              <Icon name="icon-address-small" size="28px" />
            </SmallIconWrapper>
          )}
          <SmallIconWrapper>
            <Icon name="icon-address-small" size="28px" />
          </SmallIconWrapper>
          <Name>
            {delivertoTitle} {capitalize(name || saveAs)}
            {editButtonEnabled && (
              <EditWithCursor
                onClick={onEditClick}
                aria-label="Edit Address and Time"
              >
                <Icon name="icon-edit-small" size="22px" />
              </EditWithCursor>
            )}
          </Name>
        </Title>
        <Description>
          <p>{reformatAddress(addr)[0]}</p>
          <p>{reformatAddress(addr)[1]}</p>
        </Description>
      </Information>
      <MapWrapper>
        <img
          alt="Your position"
          src={mapService.getPicture(fullAddress || address || deliveryAddress)}
        />
      </MapWrapper>
      {(addressDetails || aptSuiteFloor || businessName || notes) && (
        <ExtraAddressInfo>
          {addressDetails && <Label>{addressDetails}</Label>}
          {aptSuiteFloor && (
            <Fragment>
              <Label>
                <span>Apt / Floor / Suite:</span> {aptSuiteFloor}
              </Label>
            </Fragment>
          )}
          {businessName && (
            <Fragment>
              <Label>
                <span>Business name:</span> {businessName}
              </Label>
            </Fragment>
          )}
          {notes && <Label>{notes}</Label>}
        </ExtraAddressInfo>
      )}
    </Wrapper>
  );
};

MapAddress.propTypes = {
  address: PropTypes.shape({
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    id: PropTypes.number,
    placeId: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }),
  withIcon: PropTypes.bool,
  editButtonEnabled: PropTypes.bool,
  onEditClick: PropTypes.func,
  titleText: PropTypes.string,
};

MapAddress.defaultProps = {
  address: {},
  withIcon: false,
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  background: #fff;
  flex-wrap: wrap;
`;

const Information = styled.div`
  flex: 1 1 auto;
  padding: 15px 15px 0px 15px;
`;

const SmallIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #424648;
  margin-right: 15px;
`;

const Title = styled.div`
  display: flex;
`;

const Name = styled.div`
  font-size: 15px;
  font-family: 'Roboto';
  color: #424648;
  width: 100%;
  position: relative;
`;

const MapWrapper = styled.div`
  position: relative;
  max-height: 85px;
  width: 85px;
  > img {
    position: absolute;
    object-fit: none;
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  margin-top: 6px;
  font-weight: 300;
  line-height: 19px;
  padding-left: 29px;
  float: left;
  margin-bottom: 10px;
`;

const AddressValue = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;

  margin-top: 6px;
  font-weight: 300;
  line-height: 19px;
  padding-left: 3px;
  float: left;
`;

const Label = styled.div`
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 5px;
  float: left;
  clear: left;
  margin-left: 29px;
  line-height: 19px;
  color: #747778;
  margin-left: 29px;
  span {
    color: #000000;
    font-weight: 400;
  }
`;

const ExtraAddressInfo = styled.div`
  display: block;
  padding: 0px 15px 15px 15px;
`;

const EditWithCursor = Edit.extend`
  position: absolute;
  right: 0px;
  top: 8px;
  ${media.desktop`
    cursor: pointer;
  `};
`;

export const styles = {
  Wrapper,
  Information,
  Name,
  MapWrapper,
  Description,
};

export default MapAddress;
