import { createSelector } from 'reselect';
import { promocodeActionSelector, promocodeSelector } from 'redux/modules/cart';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';

const promocodeDiscountSelector = createSelector(
  [promocodeSelector, promocodeActionSelector, cartSubtotalSelector],
  (promocode, { type, amount }, cartSubtotal) =>
    (promocode &&
      {
        [true]: 0,
        [type === 'percentage']: (cartSubtotal * amount) / 100,
        [type === 'fixed']: amount > cartSubtotal ? cartSubtotal : amount,
      }.true) ||
    0,
);

export default promocodeDiscountSelector;
