import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import ModalComponents from 'routes/Modals/ModalComponents';
import QRCode from 'qrcode.react';
import { myRewardsHOC } from 'HOC/myRewardsHOC';
import { formatCents } from 'utils/formatCents';
import IconClean from 'components/IconClean';
import { commaSplit } from 'utils/commaSplit';
import { Stamp } from 'routes/Modals/Stamp';

const QRCodeWrapper = styled(QRCode)`
  height: 100% !important;
  width: 100% !important;
  padding: 16px;
`;

const HeadingIS = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 59px;
  padding: 0 16px 0 8px;
  background-color: #54A31C;
  border-bottom: 1px solid hsla(0, 0%, 0%, 10%);
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  font-weight: 400;
  color: white;

`;

const PointsTitle = styled.div`
  font-size:13px;
  font-weight: 200;
  line-height: 18px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 59px;
  padding: 0 16px 0 8px;
  background-color: white;
  border-bottom: 1px solid hsla(0, 0%, 0%, 10%);
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  font-weight: 400;
  ${p => p.inStorePoints < 1 &&  'border-top: 1px solid #7877771a;'}
`;

const PointsAvailable = styled.div`
  font-weight: 200;
  width: 100%;
  .IconClean {
    float: left;
    height: 40px;
    line-height: 40px;
    margin-right: 8px;
  }
`;

const PointsInCurrency = styled.div`
  font-weight: 600;
`; 

const StampsInfo = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
`;

const StampsTilNextReward = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 300;
  padding: 24px 0;
  text-align: center;
  b {
    font-weight: 400;
  }
`;

const StampsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 8px;
`;

const MyRewardsComponent = ({
  hideLoyaltyQrcode,
  loyalty: {
    consumer: { points, pointsInCurrency},
  },
  loyaltyEnabled,
  user: { foce } = {},
  ordersTillNextReward,
  stamps,
  inStorePoints,
  inStorePointsInCurrency
}) => (
  <ModalComponents.ModalContent forceFront>
    <ModalComponents.ContentContainer noPadding>
      {inStorePoints > 0 && (
        <HeadingIS>
          <PointsAvailable>
            <IconClean name="icon-favorite-address-small" size="35px" className="IconClean" />
            <PointsTitle>Bonus points (IN-STORE ONLY)</PointsTitle>
            {inStorePoints}
          </PointsAvailable>
          <PointsInCurrency>{formatCents(inStorePointsInCurrency)}</PointsInCurrency>
        </HeadingIS>
      )}
      <Heading inStorePoints={inStorePoints}>
        <PointsAvailable>
          <IconClean name="icon-favorite-address-small" size="35px" className="IconClean"/>
          <PointsTitle>Your Points</PointsTitle>
          { isNaN(points - inStorePoints) ? 0 : commaSplit(points - inStorePoints)}
        </PointsAvailable>
        <PointsInCurrency>{ isNaN(pointsInCurrency - inStorePointsInCurrency) ? 0 : formatCents(pointsInCurrency - inStorePointsInCurrency)}</PointsInCurrency>
      </Heading>
      <div>
        {loyaltyEnabled && foce && !hideLoyaltyQrcode && (
          <QRCodeWrapper
            value={foce}
            size={640}
            aria-label="QR Code"
            tabIndex={0}
          />
        )}
      </div>
      <StampsInfo>
        {!!ordersTillNextReward && ordersTillNextReward !== Infinity && (
          <StampsTilNextReward>
            Stamps required til next reward: <b>{ordersTillNextReward}</b>
          </StampsTilNextReward>
        )}
        <div>
          <StampsRow>
            {stamps.slice(0, 5).map(({ orderNumber, ...stamp }) => (
              <Stamp key={orderNumber} {...{ orderNumber, ...stamp }} />
            ))}
          </StampsRow>
          <StampsRow>
            {stamps.slice(5).map(({ orderNumber, ...stamp }) => (
              <Stamp key={orderNumber} {...{ orderNumber, ...stamp }} />
            ))}
          </StampsRow>
        </div>
      </StampsInfo>
    </ModalComponents.ContentContainer>
  </ModalComponents.ModalContent>
);

MyRewardsComponent.propTypes = {
  loyalty: PropTypes.shape({
    points: PropTypes.number,
  }).isRequired,
  ordersTillNextReward: PropTypes.number.isRequired,
  stamps: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.number,
    }),
  ).isRequired,
  inStorePoints: PropTypes.number,
  inStorePointsInCurrency: PropTypes.number,
};

export const MyRewards = myRewardsHOC(MyRewardsComponent);
