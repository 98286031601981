import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';
import orderEstimatesHOC from 'HOC/orderEstimatesHOC';

const DeliveryTime = ({ estimateText, method }) => (
  <Wrapper>
    <Top>
      <CalendarIcon>
        <Icon name="icon-soonest-available-small" size="32px" />
      </CalendarIcon>
      <Title>{method} Time: Soonest available</Title>
      <IconEdit aria-label="Edit Time">
        <Icon name="icon-edit-small" size="26px" />
      </IconEdit>
    </Top>
    <Bottom>
      <Description>{estimateText}</Description>
    </Bottom>
  </Wrapper>
);

DeliveryTime.propTypes = {
  estimateText: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  background: #fff;
  padding: 15px 15px 15px 11px;
  border-radius: 2px;
  border-top: 1px solid #eeefef;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const CalendarIcon = styled.div`
  min-width: 22px;
  min-height: 20px;
  width: 22px;
  height: 20px;
  margin-right: 10px;
  color: #5f6263;
`;

const Title = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  flex: 1 1 auto;
  padding-right: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  margin-top: 10px;
  margin-left: 32px;
  font-weight: 300;
`;

const IconEdit = styled.div`
  width: 10px;
  height: 10px;
  color: #5f6263;
  ${media.desktop`
    cursor: pointer;
  `};
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Bottom = styled.div``;

export default orderEstimatesHOC(DeliveryTime);
