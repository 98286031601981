import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import FocusLock from 'react-focus-lock';
import Overlay from 'utils/Overlay';
import { css } from 'glamor';
import UpsellComponent from './UpsellComponent';
import Transition from 'react-transition-group/Transition';


const upsellPopup = ({
  onBeforeCloseClick,
  onBeforeButtonClick,
  upsellItems,
  onNoThanksAction,
  markedAsDoNotShow = false,
  showAnimation = true,
  onProceedToCheckout,
  onDoNotShowAgain

},
) => {

  let timer_id = 0;

  const onCloseClick = closeFunc => () => {
    if (onBeforeCloseClick) {
      onBeforeCloseClick();
    }
    closeFunc();
  };
  const onButtonClick = closeFunc => () => {
    if (onBeforeButtonClick) {
      onBeforeButtonClick(upsellItems[0].id);
    }
    closeFunc();
  };
  const onBeforeNoThanksAction = closeFunc => () => {
    onNoThanksAction();
    closeFunc();
    
  };
  const onBeforeDoNotShowAgain = () => {
    onDoNotShowAgain(upsellItems[0].id, true);
  };
  const onBeforeShowAgain = () => {
    onDoNotShowAgain(upsellItems[0].id, false);
  };


  
  toast.dismiss();
  toast(
    ({ closeToast }) => (
      <Fragment>
        <Overlay onClick={onCloseClick(closeToast)} />
        <FocusLock>
          <UpsellComponent
            upsellItem={upsellItems[0]}
            onCloseClick={onCloseClick(closeToast)}
            onButtonClick={onButtonClick(closeToast)}
            hideupsellItemPopup={onCloseClick(closeToast)}
            handleNoThanksAction={onBeforeNoThanksAction(closeToast)}
            handleDishClick={onButtonClick(closeToast)}
            handleCheckoutClick={onCloseClick(closeToast)}
            handleDoNotShowClick={onCloseClick(closeToast)}
            markedAsDoNotShow={markedAsDoNotShow}
            doNotShowAgain={onBeforeDoNotShowAgain}
            showAgain={onBeforeShowAgain}
          />
        </FocusLock>
      </Fragment>
    ),
    {
      closeButton: false,
      transition: (markedAsDoNotShow || !showAnimation) ? directIn : ZoomInAndOut,
      className: css({
        // width: "500px",
        // colorDefault: "#fff",
        // colorInfo: "#3498db",
        // colorSuccess: "#07bc0c",
        // colorWarning: "#f1c40f",
        // colorError: "#e74c3c",
        // colorProgressDefault: "linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55)",
        // mobile: "only screen and (max-width : 480px)",
        // fontFamily: "sans-serif",
        
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        background: 'inherit',
        color: 'inherit',
        maxHeight: '100%',
        padding: '0',
        marginBottom: '0',
        pointerEvents: 'none',
        borderRadius: 'inherit',
        boxShadow: 'none',
        // define transition duration for in  

        
      }),
      position: toast.POSITION.BOTTOM_CENTER, // Add this line
    },
  );
};

// Any transition created with react-transition-group/Transition will work !
const ZoomInAndOut = ({ children, position, ...props }) => (
    <Transition
      {...props}
      duration={3000}
      onEnter={ node => 
        //node.style.cssText = 'color:red'; // replace with your actual CSS rules
        // find child element with class 'UpsellItemsContentHolder' and apply css to it
        {
          setTimeout(() => {
            node.querySelector('.UpsellItemsContentHolder').style.cssText = 'bottom:0px'
          }, 1);
        
      }}
    >
      {children}
    </Transition>
  );

  // Any transition created with react-transition-group/Transition will work !
const directIn = ({ children, position, ...props }) => (
  <Transition
    {...props}
    duration={3000}
    onEnter={ node => 
      //node.style.cssText = 'color:red'; // replace with your actual CSS rules
      // find child element with class 'UpsellItemsContentHolder' and apply css to it
      {
        
          node.querySelector('.UpsellItemsContentHolder').style.cssText = 'bottom:0px'
        
      
    }}
  >
    {children}
  </Transition>
);


  

  export default upsellPopup;