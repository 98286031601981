import { actions as cartActions, cartItemsSelector } from 'redux/modules/cart';
import { actions as modalActions } from 'components/ReduxModal';
import inform from 'utils/inform';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import map from 'lodash/map';
import get from 'lodash/get';
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';
import availableItemIdsSelector from 'selectors/availableItemIdsSelector';
import { lastDeliverySettingsSelector } from 'selectors/lastDeliverySettingsSelector';
import addMinutes from 'date-fns/add_minutes';
import { getNowTime } from 'utils/timeHelpers';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';
import itemsWithOverwrittenPriceSelector from 'selectors/itemsWithOverwrittenPriceSelector';
import startsWith from 'lodash/startsWith';
import { actions as deliveryActions } from 'redux/modules/delivery';
import { ageVerificationSelector } from 'redux/modules/general';
import ownDriversSelector from 'selectors/ownDriversSelector';

const formatTime = (time, deliveryType) =>
  deliveryType === 'SOONEST'
    ? 'Soonest Available'
    : `${isToday(time) ? 'Today' : format(time, 'MM-DD-YYYY')} at ${format(
        time,
        'hh:mm A',
      )}`;

const revalidateSelectedTime = history => (dispatch, getState) => {
  const state = getState();
  const asapPreparationTime = asapPreparationTimeSelector(state);
  const cartItemIds = map(cartItemsSelector(state), 'dishId');
  const items = itemsWithOverwrittenPriceSelector(state);
  const {
    lastDeliveryTimeType,
    lastScheduleTime,
  } = lastDeliverySettingsSelector(state);

  const stateClone = cloneDeep(state);
  const time = stateClone.delivery.scheduleTime;

  const ageVerification = ageVerificationSelector(state);
  const ownDrivers = ownDriversSelector(state);

  if (time && cartItemIds && cartItemIds.length) {
    const stateClone = cloneDeep(state);
    const deliveryTimeType = state.delivery.deliveryTimeType;

    let withNewTime;

    if (deliveryTimeType === 'SOONEST')
      withNewTime = set(
        stateClone,
        'delivery.scheduleTime',
        addMinutes(getNowTime(), asapPreparationTime).toISOString(),
      );
    else withNewTime = stateClone;

    const availableItemIds = availableItemIdsSelector(withNewTime);

    const invalidItems = cartItemIds.filter(
      cartItemId => !availableItemIds.includes(cartItemId),
    );

    const ageVerificationIds = [];
    let ageVerified = false;
    if (state.delivery.type === 'DELIVERY' && !ownDrivers) {
      const cartItems = cartItemsSelector(state);
      const keys = Object.keys(cartItems);
      const cartItems2 = keys.map(key => cartItems[key]);
      cartItems2.map(item => {
        if (item.variations && item.variations[ageVerification]) {
          invalidItems.push(item.dishId);
          ageVerified = true;
        }
      });
    }

    if (invalidItems.length) {
      const onBeforeButtonClick = () => {
        dispatch(cartActions.removeItemByDishId(invalidItems));
        dispatch(
          deliveryActions.setSoonestTime(
            addMinutes(getNowTime(), asapPreparationTime).toISOString(),
            history,
          ),
        );

        if (startsWith(window.location.pathname, '/checkout')) {
          history.push(`/menu`);
        } else {
          dispatch(modalActions.closeModal());
          dispatch(modalActions.showModal('review-order'));
        }
      };

      const onCloseClick = () => false;

      let message =
        invalidItems.length == 1
          ? 'The following item is not longer available:'
          : 'The following items are not longer available:';

      if (ageVerified)
        message = message.replace(
          'not longer available',
          'not available for delivery',
        );

      const item = get(items, invalidItems[0], {});

      const messageList = [];

      invalidItems.forEach(itemId => {
        messageList.push(get(items, itemId, {}).title);
      });

      inform({
        title:
          invalidItems.length == 1
            ? 'Item Not Available'
            : 'Items not available',
        message,
        buttonText: 'Review Order',
        onBeforeButtonClick,
        messagelist: messageList,
        onCloseClick,
      });
      return false;
    }
    return true;
  }
};

export default revalidateSelectedTime;
