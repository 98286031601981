import React from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFixedPrice } from 'utils/currencyHelpers';

const CounterItem = ({
  name,
  price,
  value,
  soldOut,
  incDisabled,
  decDisabled,
  onIncClick,
  onDecClick,
}) => (
  <Wrapper>
    <Name active={!soldOut} soldOut={soldOut} withValue={!!value}>
      <p>{name}</p>
      <Price>+ ${getFixedPrice(price)}</Price>
    </Name>
    {soldOut ? (
      <SoldOut>SOLD OUT</SoldOut>
    ) : (
      <CounterWrapper>
        <IncDec
          disabled={decDisabled}
          onClick={onDecClick}
          aria-label="Decrease quantity"
        >
          –
        </IncDec>
        <Count>{value}</Count>
        <IncDec
          disabled={incDisabled}
          onClick={onIncClick}
          aria-label="Increase quantity"
        >
          +
        </IncDec>
      </CounterWrapper>
    )}
  </Wrapper>
);

CounterItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  price: PropTypes.number,
  soldOut: PropTypes.bool,
  incDisabled: PropTypes.bool,
  decDisabled: PropTypes.bool,
  onIncClick: PropTypes.func,
  onDecClick: PropTypes.func,
};

const CounterItemHOC = compose(
  withProps(({ value, maxValue, allItemsCount }) => ({
    decDisabled: value === 0,
    incDisabled: allItemsCount === maxValue,
  })),
  withHandlers({
    onDecClick: ({ decDisabled, onDecClick }) => () => {
      if (!decDisabled) {
        onDecClick();
      }
    },
    onIncClick: ({ incDisabled, onIncClick }) => () => {
      if (!incDisabled) {
        onIncClick();
      }
    },
  }),
);

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  border-top: 1px solid #efefef;
`;

const Name = styled.div`
  padding: 16px 15px;
  flex: 1 1 auto;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: ${({ soldOut }) =>
    soldOut ? 'rgb(122, 126, 129)' : 'rgb(66, 70, 72)'};
  font-weight: ${({ active, withValue }) =>
    ({ [true]: '400', [!active]: '300', [withValue]: '500' }.true)};
  
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Price = styled.p`
  font-size: 15px;
  margin-top: 8px;
`;

const CounterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
`;

const IncDec = styled.button`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  width: 50px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #eeefef;
  color: ${({ disabled }) =>
    disabled ? 'rgb(152, 156, 159)' : 'rgb(66, 70, 72)'};
  cursor: ${p => !p.disabled && 'pointer'};
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Count = styled.div`
  height: 100%;
  width: 50px;
  background: #fff;
  border-left: 1px solid #eeefef;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SoldOut = styled.div`
  height: 50px;
  width: 150px;
  text-align: center;
  vertical-align: middle;
  border-left: 1px solid #eeefef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: rgb(122, 126, 129);
  font-weight: 400;
`;

export default CounterItemHOC(CounterItem);
