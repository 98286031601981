import { createSelector } from 'reselect';

import round from 'lodash/round';

import { serviceFeeSelector } from 'redux/modules/general';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { paymentMethodSelector, backendFeeSelector } from 'redux/modules/cart';
import { getDeliveryType } from 'selectors/getDeliveryType';
import extraServiceFeeSelector from 'selectors/extraServiceFeeSelector';

const serviceFeeTotalSelector = createSelector(
  [
    cartSubtotalSelector,
    serviceFeeSelector,
    paymentMethodSelector,
    getDeliveryType,
    extraServiceFeeSelector,
    backendFeeSelector,
  ],
  (
    cartSubtotal,
    {
      fee,
      type,
      minimum,
      reducedLimit,
      reducedFee,
      appliesToPayment, // both/card/cash
      appliesToMethod, // both/delivery/pickup
    },
    paymentMethod,
    deliveryType,
    extraServiceFeeSelector,
    backendFeeSelector,
  ) => {
    appliesToPayment = backendFeeSelector.feeAppliesTo;
    if (
      appliesToPayment &&
      appliesToPayment !== 'both' &&
      paymentMethod &&
      appliesToPayment.toLowerCase() !== paymentMethod.toLowerCase()
    ) {
      return 0;
    }
    /* if (
      appliesToMethod &&
      appliesToMethod !== 'both' &&
      deliveryType &&
      appliesToMethod.toLowerCase() !== deliveryType.toLowerCase()
    ) {
      return 0;
    } */
    // const newFee = cartSubtotal < reducedLimit ? reducedFee : fee;
    const newFee = backendFeeSelector.feeAmount + extraServiceFeeSelector;

    // const feePlusExtra = newFee > 0 ? newFee + extraServiceFeeSelector : newFee;
    /* const resultingFee = round(
      Math.max(
        0,
        minimum,
        type === 'amount' ? feePlusExtra : (cartSubtotal * feePlusExtra) / 100,
      ),
      2,
    ); */

    return round(newFee, 2);
  },
);

export default serviceFeeTotalSelector;
