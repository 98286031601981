import React from 'react';
import { compose, lifecycle, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { actions as userActions } from 'redux/modules/user';
import addMilliseconds from 'date-fns/add_milliseconds';
import inform from 'utils/inform';
import { getNowTime } from 'utils/timeHelpers';
import { SMS_TIMEOUT } from 'utils/constants';
import { first, last } from 'lodash';

const enterVerificationCodeHOC = compose(
  connect(null, {
    validateSMSCode: userActions.validateSMSCode,
    requestSMSCode: userActions.requestSMSCode,
    resetPasswordSMS: userActions.resetPasswordSMS,
  }),
  withStateHandlers(
    {
      retryTimerTill: null,
      secondSMSsent: false,
      changedToEmail: false,
      noMoreAttempts: false,
      currentMethod: '',
      lastName: '',
    },
    {
      resetRetryTimer: () => () => ({
        retryTimerTill: addMilliseconds(getNowTime(), SMS_TIMEOUT),
      }),
      setSecondSMSSent: () => () => ({
        secondSMSsent: true,
      }),
      resetSecondSMSSent: () => () => ({
        secondSMSsent: false,
      }),
      setChangedToEmail: () => () => ({
        changedToEmail: true,
        secondSMSsent: false,
      }),
      setNoMoreAttempts: () => () => ({
        noMoreAttempts: true,
      }),
      setCurrentMethod: () => method => ({
        currentMethod: method,
      }),
      setLastName: () => lastName => ({
        lastName,
      }),
    },
  ),
  withHandlers({
    requestCode: ({
      firstName,
      lastName,
      requestSMSCode,
      phoneNumber,
      action,
      resetRetryTimer,
      onRequestCodeFail,
      resetPasswordSMS,
      sentVia,
      changeToEmail,
      setChangedToEmail,
      resetSecondSMSSent,
      setNoMoreAttempts,
      setCurrentMethod,
      currentMethod,
    }) => (call = false) => {
      
      if (action === 'reset_password') {
          
        const handleEmailChange = () => {

              changeToEmail();
              setChangedToEmail();
            

          const res = resetPasswordSMS(
            {
              login:phoneNumber,
              type: 'email',
              onFailure
            }
            
          );

        };

        const handleSMSChange = () => {
          changeToEmail();
         
        };

        const handleCallChange = () => {
          
          const res = resetPasswordSMS(
            {
              login:phoneNumber,
              lastName,
              type: 'call',
              onFailure
            }
          );
        };
        
        const onFailure = message => {
          setCurrentMethod(message.channel);
          
          if (message.channel)
            {
            inform({
              title: 'Resend Code',
              message:
                "If you didn't get receive your verification code, we'll resend it to you via " + message.channel.toUpperCase(),
              buttonText: (
                <span>
                  {message.channel == 'call' ? <i className="icon-phone" />  : <i className="icon-email" />  } RESEND VIA {message.channel.toUpperCase()}
                </span>
              ),
              showCancelButton: true,
              buttonChoice: true,
              onBeforeButtonClick: message.channel == 'email' ? handleEmailChange : message.channel == 'sms' ? handleSMSChange : handleCallChange,
              alternativeStyles: true,
            });
          }
        else
          {
            setNoMoreAttempts();
            inform({
              title: 'Oops...',
              message:
                message.message,
              buttonText: 'OK',
            });
          }

        };

        
        const res = resetPasswordSMS(
          {
            login:phoneNumber,
            lastName,
            type: sentVia == 'email' ? 'email' : currentMethod == 'call' ? 'call' : 'sms',
            onFailure
          },
          response => {
            console.log("resetPasswordSMS response");
            resetRetryTimer();
            console.log(response);
          },
          message => {
            console.log("resetPasswordSMS MESS");
            if (onRequestCodeFail) {
              onRequestCodeFail();
            } else {
              inform({
                title: 'Oops...',
                message:
                  message ||
                  (currentMethod == 'call' ? "We couldn't make a call" : "We couldn't sent an SMS"),
                buttonText: 'OK',
              });
            }
          },
        );
        resetRetryTimer();
      }
      else {
      requestSMSCode(
          {
            phoneNumber,
            action,
            type: call ? 'call' : 'sms',
            firstName: firstName || '',
            lastName: lastName || '',
          },
          response => {
            resetRetryTimer();
            console.log(response);
          },
          message => {
            if (onRequestCodeFail) {
              onRequestCodeFail();
            } else {
              inform({
                title: 'Oops...',
                message:
                  message ||
                  (call ? "We couldn't make a call" : "We couldn't sent an SMS"),
                buttonText: 'OK',
              });
            }
          },
      );
      }
    },
  }),
  withHandlers({
    callMe: ({ requestCode }) => () => {
      requestCode(true);
    },
    smsTryAgain: ({ requestCode, setSecondSMSSent }) => () => {
      setSecondSMSSent();
      requestCode();
    },
  }),
  withHandlers({
    requestCodeRetryClick: ({ secondSMSsent, callMe, smsTryAgain,sentVia,action }) => () => {
      if (action === 'reset_password') 
        {
          secondSMSsent = false;
          smsTryAgain();
          return;
        }
     
      if (secondSMSsent) {
        inform({
          title: 'Resend Code',
          message:
            "If you didn't get recieve your verification code, we'll send it to you through an automated phone call.",
          buttonText: (
            <span>
              <i className="icon-phone" /> RESEND VIA PHONE CALL
            </span>
          ),
          showCancelButton: true,
          buttonChoice: true,
          onBeforeButtonClick: callMe,
          alternativeStyles: true,
        });
      } else {
        inform({
          title: 'Resend Code',
          message:
            "If you didn't get receive your verification code, we'll resend it to you via SMS.",
          buttonText: (
            <span>
              <i className="icon-email" /> RESEND VIA SMS
            </span>
          ),
          showCancelButton: true,
          buttonChoice: true,
          onBeforeButtonClick: smsTryAgain,
          alternativeStyles: true,
        });
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { requestCode,resetRetryTimer } = this.props;
      // get lastName from values
      const { lastName } = this.props.values;
      this.props.setLastName(lastName);
      if (!lastName) {
        requestCode();
        resetRetryTimer();
      }
    },
  }),
  withStateHandlers(
    { codeError: '' },
    { setCodeError: () => error => ({ codeError: error }) },
  ),
  withHandlers({
    submitCode: ({
      validateSMSCode,
      phoneNumber,
      onCodeValidated,
      setCodeError,
    }) => code => {
      validateSMSCode(
        {
          phoneNumber,
          smsCode: code,
        },
        data => {
          onCodeValidated(data);
          setCodeError('');
        },
        (message) => {
          // message is string?
          if (typeof message === 'string' && message !== 'Please enter the valid code') {
            setCodeError(message);

          }
          else
          {
            // split code var which is 6 digits like  123456 to a 123-456
            setCodeError('Incorrect verification code ('+code.substring(0,3)+'-'+code.substring(3,6)+')');
          }
        },
      );
    },
  }),
);

export default enterVerificationCodeHOC;
