import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Link, Route, withRouter } from 'react-router-dom';

import Icon from 'components/Icon';
import { activePromotionsSelector } from 'redux/modules/general';

const PromotionsTabBarButton = ({ promotionsCount }) => (
  <Route path="/promotions">
    {({ match }) => (
      <TabBarItemLink to="/promotions">
        <IconWrapper active={match && match.isExact}>
          <Icon name="icon-promotions-small" size="34px" />
        </IconWrapper>
        <Label active={match && match.isExact}>Promotions</Label>
        {promotionsCount && promotionsCount > 0 && (
          <PromotionsCircle>{promotionsCount}</PromotionsCircle>
        )}
      </TabBarItemLink>
    )}
  </Route>
);

PromotionsTabBarButton.propTypes = {
  promotionsCount: PropTypes.number,
};

PromotionsTabBarButton.defaultProps = {
  promotionsCount: undefined,
};

const TabBarItemLink = styled(Link)`
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 5px 10px;
  text-decoration: none;
  position: relative;
`;

const Label = styled.div`
  text-align: center;
  font-size: 11px;
  font-family: 'Roboto';
  color: ${({ active }) => (active ? '#54a300' : 'rgba(66, 70, 72, 0.549)')};
  margin-top: 6px;
`;

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  color: ${({ active }) => (active ? '#54a300' : 'rgba(66, 70, 72, 0.549)')};
`;

const PromotionsCircle = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  background: #fa4741;
  border-radius: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  font-size: 12px;
  right: 21px;
`;

export default withRouter(
  connect(state => ({
    promotionsCount: activePromotionsSelector(state),
  }))(PromotionsTabBarButton),
);
