import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Item } from 'components/Card';

const DishItem = ({ count, name, price, children, onClick, showBottom }) => (
  <DishItemWrapper>
    <Top>
      <CounterWrapper onClick={onClick}>
        <Counter>{count}</Counter>
      </CounterWrapper>
      <Title onClick={onClick} aria-label={name}>
        {name}
      </Title>
      <Price>${price.toFixed(2)}</Price>
    </Top>
    {showBottom && <Bottom>{children}</Bottom>}
  </DishItemWrapper>
);

DishItem.propTypes = {
  count: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
  children: PropTypes.node,
  onClick: PropTypes.func,
  showBottom: PropTypes.bool.isRequired,
};

DishItem.defaultProps = {
  variations: [],
  price: 0,
};

const DishItemWrapper = styled(Item)`
  display: block;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const CounterWrapper = styled.div``;

const Title = styled.button`
  flex: 1 1 auto;
  padding: 0 15px;
  font-size: 15px;
  font-family: 'Roboto';
  color: #424648;
  white-space: nowrap;
  overflow-x: hidden;
  line-height: 22px;
  text-overflow: ellipsis;
`;

const Price = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  font-weight: 100;
`;

const Counter = styled.div`
  background: #54a30a;
  padding: 3px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 14px;
  font-weight: lighter;
  color: #fff;
  display: inline-block;
  font-weight: 300;
  width: 20px;
  text-align: center;
`;

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-left: 36px;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
`;

export const Information = styled.div`
  width: 100%;
`;
export const Controls = styled.div``;

export const Remove = styled.button`
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(116, 119, 120);
  margin-top: 3px;
`;

export default DishItem;
