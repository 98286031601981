import { createSelector } from 'reselect';

import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import getISODay from 'date-fns/get_iso_day';
import isBefore from 'date-fns/is_before';
import itemsWithOverwrittenPriceSelector from 'selectors/itemsWithOverwrittenPriceSelector';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import scheduleFilteredMenuSelector from 'selectors/scheduleFilteredMenuSelector';
import { ageVerificationSelector } from 'redux/modules/general';

const availableAtDateItemsSelector = createSelector(
  [
    itemsWithOverwrittenPriceSelector,
    scheduleTimeSelector,
    scheduleFilteredMenuSelector,
    ageVerificationSelector,
  ],
  (items, date, availableMenu, ageVerification) =>
    mapValues(
      pickBy(
        items,
        ({ availableOn, menus }) =>
          availableOn
            .split(',')
            .map(v => +v)
            .includes(getISODay(date)) && menus.includes(availableMenu.id),
      ),
      item => ({
        ...item,
        soldOut: isBefore(date, item.temporarySoldout),
        notAllowedForDelivery:
          item.variations &&
          Array.isArray(item.variations) &&
          item.variations.includes(ageVerification),
      }),
    ),
);

export default availableAtDateItemsSelector;
