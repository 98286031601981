import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';

export const deliveryTimeTypeAsapHOC = compose(
  connect((state, { deliveryType, deliveryConditions }) => ({
    preparationTime: asapPreparationTimeSelector(state, {
      deliveryType,
      deliveryConditions,
    }),
  })),
  withProps(({ deliveryType, preparationTime }) => ({
    estimateText: {
      [true]: `${Math.floor(preparationTime / 5) * 5 - 5}-${Math.floor(
        preparationTime / 5,
      ) *
        5 +
        5} Minutes`,
      [deliveryType ===
      'PICKUP']: `Approx. ${preparationTime} minutes after your order is placed`,
    }.true,
  })),
);
