import { createSelector } from 'reselect';
import { checkoutOptionsSelector } from 'redux/modules/general';
import { getDeliveryType } from 'selectors/getDeliveryType';

const checkoutOptionsAllowedSelector = createSelector(
  [checkoutOptionsSelector, getDeliveryType],
  ({ appliesTo }, deliveryType) =>
    Boolean(
      appliesTo == 'both' ||
        (deliveryType === 'PICKUP' && appliesTo == 'pickup') ||
        (deliveryType === 'DELIVERY' && appliesTo == 'delivery'),
    ),
);

export default checkoutOptionsAllowedSelector;
