import { createSelector } from 'reselect';
import { paymentMethodSelector } from 'redux/modules/cart';
import {
  defaultPaymentSelector,
  selectedPaymentSelector,
} from 'redux/modules/payments';

const paymentValidSelector = createSelector(
  [paymentMethodSelector, defaultPaymentSelector, selectedPaymentSelector],
  (method, defaultPayment, selectedPayment) =>
    method === 'CASH' ||
    (method === 'CARD' && (!!defaultPayment || !!selectedPayment)),
);

export default paymentValidSelector;
