import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { orderingSettingsSelector } from 'redux/modules/general';
import { actions as modalActions } from 'components/ReduxModal';
import CartHOC from 'HOC/Cart';
import ReviewOrder from '../components/ReviewOrder';
import { actions as orderActions } from 'redux/modules/orders';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import {
  cartItemsSelector,
  upsellItemsPopupHiddenSelector,
  sessionIdSelector,
} from 'redux/modules/cart';
import upsellItemsSelector from 'selectors/upsellItemsSelector';

const ReviewOrderHOC = compose(
  CartHOC,
  withRouter,
  connect(
    state => ({
      disclaimerText: orderingSettingsSelector(state)?.disclaimerText,
      cartSubtotal: cartSubtotalSelector(state),
      type: getDeliveryType(state),
      hiddenupsellItems: upsellItemsPopupHiddenSelector(state),
      upsellItems: upsellItemsSelector(state),
      orderDishes: cartItemsSelector(state),
      sessionId: sessionIdSelector(state),
    }),
    {
      reviewOrder: orderActions.reviewOrder,
      closeModal: modalActions.closeModal,
      showModal: modalActions.showModal,
    },
  ),
  withHandlers({
    handleCheckoutClick: ({
      userLoggedIn,
      checkoutButtonEnabled,
      reviewOrder,
      cartSubtotal,
      type,
      upsellItems,
      orderDishes,
      hiddenupsellItems,
      sessionId,
    }) => () => {
      // extract array of dishId from orderDishes
      const orderDishesIds = [];
      Object.keys(orderDishes).forEach(key => {
        orderDishesIds.push(orderDishes[key].dishId);
      });

      const notInCartUpsellItems = [];
      // remove all items that are already in the cart
      upsellItems.forEach(item => {
        if (!orderDishesIds.includes(item.id)) notInCartUpsellItems.push(item);
      });

      if (checkoutButtonEnabled) {
        const method = {
          type: type.toLowerCase(),
        };
        const body = {
          method,
          cartTotal: cartSubtotal,
          cartItems: orderDishesIds,
          cartId: sessionId,
        };
        const myMethod = {
          type,
        };

        const proceeedToCheckout = hiddenupsellItems > 1;

        // wait for revieworder request to finish
        reviewOrder(
          body,
          myMethod,
          false,
          userLoggedIn,
          proceeedToCheckout,
          notInCartUpsellItems,
        );

        // wait for revieworder request to finish
      }
    },
  }),
);

export default ReviewOrderHOC(ReviewOrder);
