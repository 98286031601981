import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dotdotdot from 'react-dotdotdot';
import reactStringReplace from 'react-string-replace';
import isArray from 'lodash/isArray';

import { getFixedPrice } from 'utils/currencyHelpers';
import { media } from 'utils/mediaQueries';

const ProductCard = ({
  item: {
    image,
    description,
    title,
    price,
    count,
    soldOut,
    minCount,
    notAllowedForDelivery,
  },
  onClick,
}) => (
  <Wrapper onClick={onClick} soldOut={soldOut}>
    {image && <Image img={image} />}
    <Information>
      <Top>
        <Title>{title}</Title>
        {count !== 0 && <Counter>{count}</Counter>}
      </Top>
      {minCount && minCount > 1 && (
        <MinimumCount>Minimum {minCount}</MinimumCount>
      )}
      <Bottom>
        <MobileDescription>{description}</MobileDescription>
        <Dotdotdot clamp={3}>
          <Description>
            {reactStringReplace(description, /(\\n)/gm, (match, i, offset) => (
              <br key={match + i + offset} />
            ))}
          </Description>
        </Dotdotdot>
      </Bottom>
      <BottomItems>
        <PriceInner>
          {!!price && (
            <Price>
              {isArray(price)
                ? price.map(p => `$${getFixedPrice(p)}`).join(' - ')
                : `$${getFixedPrice(price)}`}
            </Price>
          )}
        </PriceInner>
        {soldOut && <SoldOut> SOLD OUT </SoldOut>}
        {notAllowedForDelivery && (
          <SoldOut> NOT AVAILABLE FOR DELIVERY </SoldOut>
        )}
      </BottomItems>
    </Information>
  </Wrapper>
);

ProductCard.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    count: PropTypes.number,
    soldOut: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

const Wrapper = styled.button`
  user-select: none;
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  ${media.tablet`
    display: flex;
    flex-flow: row-reverse nowrap;
    height: 170px;
    min-width: 360px;
  `} ${media.desktop`
    display: flex;
    flex-flow: row-reverse nowrap;
    height: 170px;


    &:hover {
      &::before {
        opacity: 1;
      }
    }
  `};
`;

const Image = styled.div`
  height: 170px;
  background: url(${({ img }) => img}) no-repeat center / cover;

  ${media.tablet`
    height: 170px;
    min-width: 170px;
  `} ${media.desktop`
    height: 170px;
    min-width: 160px;
  `};
`;

const Information = styled.div`
  padding: 15px 15px 20px;

  ${media.tablet`
    padding: 25px;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column nowrap;
  `};
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const MinimumCount = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 200;
  color: #323537;
  margin-top: 6px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  flex: 1 1 auto;
  padding-right: 10px;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: #000;
  line-height: 24px;

  ${media.tablet`
    font-size: 16px;
  `};
`;

const Price = styled.div`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: #000;
`;

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-top: 7px;
  justify-content: space-between;

  ${media.tablet`
    flex-flow: column nowrap;
    flex: 1 1 auto;
  `};
`;

const Description = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #414141;
  padding-right: 10px;
  line-height: 21px;
  font-weight: 400;
  display: none;

  ${media.tablet`
    display: block;
    font-size: 12px;
    line-height: 18px;
  `};
`;

const MobileDescription = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #414141;
  padding-right: 10px;
  line-height: 20px;
  font-weight: 300;

  ${media.tablet`
    display: none;
  `};
`;

const Counter = styled.div`
  background: #54a30a;
  padding: 5px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 14px;
  font-weight: lighter;
  color: #fff;
  margin-left: 18px;
`;

const SoldOut = styled.div`
  font-size: 11px;
  font-family: 'Roboto', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  background: #939393;
  font-weight: 400;
  letter-spacing: 0.2px;
  padding: 3px 5px;
  white-space: nowrap;
`;

const BottomItems = styled.div`
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // ${media.tablet`
  //   display: flex;
  //   flex-flow: row nowrap;
  //   align-items: center;
  //   width: 100%;
  // `};
`;

const PriceInner = styled.div`
  display: flex;
  align-items: center;

  // ${media.tablet`
  //   display: flex;
  //   flex-flow: row nowrap;
  //   align-items: center;
  //   flex: 1 1 auto;
  // `};
`;

export default ProductCard;
