import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';

const orderEstimatesHOC = compose(
  connect(state => ({
    preparationTime: asapPreparationTimeSelector(state),
    deliveryType: getDeliveryType(state),
  })),
  withProps(({ deliveryType, preparationTime }) => ({
    method: {
      [deliveryType === 'PICKUP']: `Pickup`,
      [deliveryType === 'DELIVERY']: `Delivery`,
    }.true,
    estimateText: {
      [deliveryType ===
      'PICKUP']: `Approx. ${preparationTime} minutes after your order is placed`,
      [deliveryType === 'DELIVERY']: `${Math.floor(preparationTime / 5) * 5 -
        5}-${Math.floor(preparationTime / 5) * 5 + 5} Minutes`,
    }.true,
  })),
);

export default orderEstimatesHOC;
