import wretch from 'wretch';
import { SUBDOMAIN, MOLTENCORE_HOST } from 'utils/hostname';
import uuidv4 from 'uuid/v4';

const API_ROOT_URL = `//api-${SUBDOMAIN}.foodieordersecure.com/public/v1`;

const MOLTENCORE_API_ROOT_URL = `//${MOLTENCORE_HOST}/api`;

const authToken = localStorage.getItem('authToken');

const getStoredSession = () => {
  const session = localStorage.getItem('foData');
  if (session) {
    const { sID, timestamp } = JSON.parse(session);
    const age = (Date.now() - timestamp) / (1000 * 60 * 60); // Convert ms to hours
    if (age < 3) return sID;
  }
  const newSID = uuidv4();
  localStorage.setItem(
    'foData',
    JSON.stringify({ sID: newSID, timestamp: Date.now() }),
  );
  return newSID;
};

const sID = getStoredSession();

const wretchBase = wretch()
  .headers({ sID })
  .errorType('json');

const wretchBaseNoSID = wretch().errorType('json');


const moltencoreApiRoot = wretchBaseNoSID.url(MOLTENCORE_API_ROOT_URL);

const sIDQueryString = `?v=2.5&fos=${sID}`;

const apiRoot = wretchBase.url(API_ROOT_URL);
const apiRootNoSID = wretchBaseNoSID.url(API_ROOT_URL);
const addressValidate = apiRoot.url('/delivery/validate');
const general = apiRootNoSID.url('/general'+sIDQueryString);
const generalPromotions = apiRoot.url('/order/coupon');
const recoverPassword = apiRoot.url('/user/recover');
const requestSMSCode = apiRoot.url('/user/request_sms_code');
const setPassword = apiRoot.url('/user/password');
const userCreate = apiRoot.url('/user/create/');
const userLogin = apiRoot.url('/user/login');
const validateEmail = apiRoot.url('/user/validate_email');
const validateSMSCode = apiRoot.url('/user/validate_sms_code');
const forgotPassword = apiRoot.url('/user/forgot_password');

const authenticatedApiRoot = apiRoot.auth(authToken && `Bearer ${authToken}`);
const feereview = authenticatedApiRoot.url('/order/feereview');
const checkout = authenticatedApiRoot.url('/orders');
const review = authenticatedApiRoot.url('/order/review');
const reorder = authenticatedApiRoot.url('/orders/reorder');
const favorites = authenticatedApiRoot.url('/favorites');
const ordersList = authenticatedApiRoot.url('/orders/list');
const orderInvoice = authenticatedApiRoot.url('/orders/invoice');
const payments = authenticatedApiRoot.url('/user/payments');
const paymentsMakeDefault = authenticatedApiRoot.url('/user/payments/default');
const promocodeValidate = authenticatedApiRoot.url('/order/coupon/validate');
const promotions = authenticatedApiRoot.url('/order/coupon');
const userAddresses = authenticatedApiRoot.url('/user/addresses/');
const userInfo = authenticatedApiRoot.url('/user/login');
const userUpdate = authenticatedApiRoot.url('/user/update');
const doNotShowAgain = authenticatedApiRoot.url('/doNotShowAgain/');

const foc = moltencoreApiRoot.url(`/foc`);

export default {
  authenticatedApiRoot,
  addressValidate,
  apiRoot,
  checkout,
  review,
  foc,
  favorites,
  general,
  ordersList,
  generalPromotions,
  payments,
  paymentsMakeDefault,
  promocodeValidate,
  promotions,
  recoverPassword,
  reorder,
  requestSMSCode,
  setPassword,
  userAddresses,
  userCreate,
  userInfo,
  userLogin,
  userUpdate,
  validateEmail,
  validateSMSCode,
  feereview,
  orderInvoice,
  doNotShowAgain,
  forgotPassword
};
