import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';

import {
  paymentsEntitiesSelector,
  actions as paymentsActions,
  selectedPaymentSelector,
} from 'redux/modules/payments';
import Payments from 'routes/MyAccount/routes/Payments';
import { actions as modalActions, modalSelector } from 'components/ReduxModal';
import { actions as userActions } from 'redux/modules/user';
import dataFetcher from 'HOC/dataFetcher';

const PaymentsManagementHOC = compose(
  connect(
    state => ({
      paymentsEntities: paymentsEntitiesSelector(state),
      selectedPayment: selectedPaymentSelector(state),
      modal: modalSelector(state),
    }),
    {
      makeDefaultPayment: paymentsActions.makeDefaultPayment,
      deletePayment: paymentsActions.deletePayment,
      selectPayment: paymentsActions.selectPayment,
      modalGoBack: modalActions.modalGoBack,
    },
  ),
  dataFetcher(state => !state.payments.fulfilled, userActions.userInfo),
  withProps(({ paymentsEntities, modal: { payload } }) => ({
    payments: Object.values(paymentsEntities),
    paymentsSelectable: payload && payload.paymentsSelectable,
  })),
  withStateHandlers(
    ({ selectedPayment }) => ({
      expandedPayment: selectedPayment && selectedPayment.id,
      selectorTouched: false,
    }),
    {
      expandPayment: () => (id, mode) => ({
        expandedPayment: id,
        selectorTouched: true,
        expandedMode: mode,
      }),
    },
  ),
  withHandlers({
    deletePaymentId: ({
      deletePayment,
      expandPayment,
      selectedPayment,
    }) => id => () => {
      deletePayment(id);
      if (id !== selectedPayment.id) expandPayment(selectedPayment.id);
      else expandPayment(0);
    },
    makeDefaultPaymentId: ({
      makeDefaultPayment,
      selectPayment,
      modalGoBack,
      expandPayment,
    }) => id => () => {
      makeDefaultPayment(id);
      expandPayment(id);
      // selectPayment(id);
      // modalGoBack();
    },
    selectPaymentId: ({ selectPayment, modalGoBack }) => id => () => {
      selectPayment(id);
      modalGoBack();
    },
    expandPaymentId: ({ expandPayment }) => (id, mode = false) => () => {
      expandPayment(id, mode);
    },
  }),
);

export default PaymentsManagementHOC(Payments);
