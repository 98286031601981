import { handleActions, createAction } from 'redux-actions';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isFuture from 'date-fns/is_future';
import jwtDecode from 'jwt-decode';
import { camelizeKeys, decamelizeKeys } from 'humps';
import wretches from 'wretches';
import { actions as spinnerActions } from 'redux/modules/spinner/reducer';
import inform from 'utils/inform';
import cleanPhoneNumber from 'utils/cleanPhoneNumber';
import isEmail from 'utils/isEmail';
import { isUserLoggedInSelector } from 'redux/modules/user/index';
import { history } from 'routes';
import { FINISH_ORDER } from 'redux/modules/cart';

// ---
// CONSTANTS
// ---
export const SET_USER_POSITION = 'user/SET_USER_POSITION';
export const GEOLOCATION_USER_POSITION = 'user/GEOLOCATION_USER_POSITION';
export const ADD_DELIVERY_ADDRESS = 'user/ADD_DELIVERY_ADDRESS';
export const GET_USER = 'user/GET_USER';
export const SET_DEFAULT_ADDRESS = 'user/SET_DEFAULT_ADDRESS';
export const ADD_ADDRESS = 'user/ADD_ADDRESS';
export const SET_ADDRESSES = 'user/SET_ADDRESSES';
export const UPDATE_USER = 'user/UPDATE_USER';
export const LOG_IN = 'user/LOG_IN';
export const DISCARD_TOKEN = 'user/DISCARD_TOKEN';

// ---
// ACTION CREATORS
// ---
export const setUserPosition = createAction(SET_USER_POSITION);
export const geolocationUserPosition = createAction(GEOLOCATION_USER_POSITION);
export const addDeliveryAddress = createAction(ADD_DELIVERY_ADDRESS);
// export const getUser = (username, password) => post(USER, {}, { params: { username, password } })
export const setDefaultAddress = createAction(SET_DEFAULT_ADDRESS);
export const addAddress = createAction(ADD_ADDRESS);
export const setAddresses = createAction(SET_ADDRESSES);
export const updateUserSuccess = createAction(UPDATE_USER);
export const logIn = createAction(LOG_IN, payload => {
  localStorage.setItem('authToken', payload.token);
  localStorage.setItem(
    'authTokenExp',
    new Date(jwtDecode(payload.token).exp * 1000).toISOString(),
  );

  // For WebView in iOS app
  // callNativeApp declared in index.html
  window.callNativeApp(window.location.hostname);

  // For WebView in Android app
  console.log(`registerTag:${window.location.hostname}`);

  // HACK
  // eslint-disable-next-line no-underscore-dangle
  wretches.authenticatedApiRoot._options.headers.Authorization = `Bearer ${
    payload.token
  }`;
  return payload;
});
export const discardToken = createAction(DISCARD_TOKEN, () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('authTokenExp');
});

// ---
// INITIAL STATE
// ---

const initialState = (({ token, tokenExp }) => ({
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  defaultAddress: '',
  addresses: {},
  token:
    (token &&
      isFuture(jwtDecode(token).exp * 1000) &&
      (!tokenExp || isFuture(tokenExp)) &&
      token) ||
    '',
  foce: '',
  fulfilled: false,
}))({
  token: localStorage.getItem('authToken'),
  tokenExp: localStorage.getItem('authTokenExp'),
});

const BACKEND_FIELD_KEYS_MAPPING = {
  userEmail: 'email',
  phoneNumber: 'phone',
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [SET_USER_POSITION]: (state, action) => ({
      ...state,
      location: action.payload,
    }),
    [ADD_DELIVERY_ADDRESS]: (state, action) => ({
      ...state,
      addresses: {
        ...pickBy(state.addresses, v => v.name !== action.payload.name),
        [action.payload.id]: action.payload,
      },
    }),
    [SET_DEFAULT_ADDRESS]: (state, action) => ({
      ...state,
      defaultAddress: action.payload,
    }),
    [ADD_ADDRESS]: (state, action) => ({
      ...state,
      addresses: {
        ...state.addresses,
        [action.payload.id]: action.payload,
      },
    }),
    [SET_ADDRESSES]: (state, action) => ({
      ...state,
      addresses: {
        ...state.addresses,
        ...camelizeKeys(
          mapValues(action.payload[0], (v, k) => ({ ...v, id: k })),
        ),
      },
    }),
    [UPDATE_USER]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [LOG_IN]: (state, action) => ({
      ...state,
      ...pick(
        mapKeys(
          camelizeKeys(action.payload),
          (_, k) => BACKEND_FIELD_KEYS_MAPPING[k] || k,
        ),
        [
          'email',
          'firstName',
          'lastName',
          'phone',
          'favorites',
          'payments',
          'foce',
          'addresses',
        ],
      ),
      defaultAddress: action.payload.default_address,
      token: action.payload.token,
      fulfilled: true,
    }),
    [FINISH_ORDER]: state => ({
      ...state,
      fulfilled: false,
    }),
    [DISCARD_TOKEN]: state => ({
      ...state,
      token: undefined,
    }),
  },
  initialState,
);

const createAddress = data => () => {
  wretches.userAddresses
    .json(
      decamelizeKeys(
        pick(data, [
          'name',
          'address',
          'aptSuiteFloor',
          'businessName',
          'specialInstructions',
          'placeId',
        ]),
      ),
    )
    .post();
};

const userInfo = () => (dispatch, getState) => {
  if (isUserLoggedInSelector(getState())) {
    dispatch(spinnerActions.showSpinner());
    wretches.userInfo
      .get()
      .forbidden(() => {
        dispatch(spinnerActions.hideSpinner());
        dispatch({ type: 'RESET' });
        dispatch(discardToken);
        history.push('/');
        window.location.reload();
      })
      .json(data => {
        dispatch(spinnerActions.hideSpinner());
        dispatch(logIn(data));
      })
      .catch(() => {
        dispatch(spinnerActions.hideSpinner());
      });
  }
};

const createUser = (
  { token, email, password, firstName, lastName },
  onSuccess,
  onFailure,
) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.userCreate
    .json(
      decamelizeKeys({
        token,
        email,
        password,
        firstName,
        lastName,
      }),
    )
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(logIn({ ...json }));
      onSuccess(camelizeKeys(json));
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      onFailure(camelizeKeys(err));
    });
};

const updateUser = values => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.userUpdate
    .json(decamelizeKeys(values))
    .post()
    .json(() => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(
        updateUserSuccess(
          pick(mapKeys(values, (_, k) => BACKEND_FIELD_KEYS_MAPPING[k] || k), [
            'firstName',
            'lastName',
            'email',
            'phone',
          ]),
        ),
      );
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      inform({
        title: 'Oops...',
        message: err.json.message,
        buttonText: 'OK',
      });
    });
};

const resetPassword = ({ email, onSuccess }) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.recoverPassword
    .json({ email })
    .post()
    .json(() => {
      dispatch(spinnerActions.hideSpinner());
      onSuccess();
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      inform({
        title: 'Oops...',
        message: err.json.message,
        buttonText: 'OK',
      });
    });
};

const resetPasswordSMS = ({ login,type,onFailure,onSuccess,lastName }) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.recoverPassword
    .json({ phone_number: cleanPhoneNumber(login), type, last_name: lastName })
    .post()
    .json(() => {
      dispatch(spinnerActions.hideSpinner());
      if (onSuccess) {
        onSuccess();
      }
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      onFailure(err.json.message);
    });
};

const setPassword = ({ password, token, onSuccess, onFailure }) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.setPassword
    .json({
      token,
      password,
    })
    .post()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(logIn(data));
      onSuccess();
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      onFailure(err);
    });
};

const validateEmail = (
  { email, silent = false },
  onSuccess,
  onFailure,
) => dispatch => {
  if (!silent) {
    dispatch(spinnerActions.showSpinner());
  }
  wretches.validateEmail
    .query({
      email: isEmail(email) ? email : cleanPhoneNumber(email),
    })
    .get()
    .json(() => {
      if (!silent) {
        dispatch(spinnerActions.hideSpinner());
      }
      onSuccess();
    })
    .catch(err => {
      if (!silent) {
        dispatch(spinnerActions.hideSpinner());
      }
      onFailure(err.json.message);
    });
};

const requestSMSCode = (
  { phoneNumber, firstName, lastName, email, password, action, type },
  onSuccess,
  onFailure,
) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.requestSMSCode
    .json(
      decamelizeKeys({
        phoneNumber: cleanPhoneNumber(phoneNumber),
        firstName,
        lastName,
        email,
        password,
        action,
        type,
      }),
    )
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      onSuccess(camelizeKeys(json));
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      onFailure(err.json.message);
    });
};

const validateSMSCode = (
  { phoneNumber, smsCode },
  onSuccess,
  onFailure,
) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.validateSMSCode
    .json(
      decamelizeKeys({
        phoneNumber: cleanPhoneNumber(phoneNumber),
        smsCode,
      }),
    )
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      console.log(camelizeKeys(json));
      onSuccess(camelizeKeys(json));
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      onFailure(err.json.message);
    });
};

const forgotPassword = (
  { phoneNumber },
  onSuccess,
  onFailure,
) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.forgotPassword
    .json(
      decamelizeKeys({
        phoneNumber: cleanPhoneNumber(phoneNumber),
      }),
    )
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      onSuccess(camelizeKeys(json));
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      onFailure(err.json.message);
    });
};



export const actions = {
  setUserPosition,
  geolocationUserPosition,
  addDeliveryAddress,
  setDefaultAddress,
  addAddress,
  setAddresses,
  createUser,
  updateUser,
  resetPassword,
  resetPasswordSMS,
  setPassword,
  logIn,
  discardToken,
  createAddress,
  userInfo,
  validateEmail,
  requestSMSCode,
  validateSMSCode,
  forgotPassword,
};
