import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions as modalActions } from 'components/ReduxModal';

import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { isUserLoggedInSelector } from 'redux/modules/user';
import logOutAction from 'redux/modules/user/logout';
import goToLandingAction from 'redux/modules/user/goToLanding';
import { loyaltyEnabledSelector } from 'redux/modules/general';
import fixedLoyaltySelector from 'selectors/fixedLoyaltySelector';

import DesktopMenu from '../components/DesktopMenu';

const DesktopMenuHOC = compose(
  withRouter,
  connect(
    state => ({
      deliveryTimeType: deliveryTimeTypeSelector(state),
      isUserLoggedIn: isUserLoggedInSelector(state),
      loyaltyEnabled: loyaltyEnabledSelector(state),
      fixedLoyalty: fixedLoyaltySelector(state),
    }),
    {
      showModal: modalActions.showModal,
      logOut: logOutAction,
      goToLanding: goToLandingAction,
    },
  ),
  withHandlers({
    showModalPromo: ({ showModal, toggleHandler }) => () => {
      showModal('promotions');
      toggleHandler();
    },
    showModalOrders: ({ showModal, toggleHandler }) => () => {
      showModal('orders');
      toggleHandler();
    },
    showModalAddresses: ({ showModal, toggleHandler }) => () => {
      showModal('my-addresses');
      toggleHandler();
    },
    showModalSecurity: ({ showModal, toggleHandler }) => () => {
      showModal('security');
      toggleHandler();
    },
    showModalPayments: ({ showModal, toggleHandler }) => () => {
      showModal('payments');
      toggleHandler();
    },
    showModalLogin: ({ history, toggleHandler }) => () => {
      history.push({
        pathname: '/sign-in',
        state: {
          modal: true,
        },
      });
      toggleHandler();
    },
    showModalRegister: ({ history, toggleHandler }) => () => {
      history.push({
        pathname: '/register',
        state: {
          modal: true,
        },
      });
      toggleHandler();
    },
  }),
);

export default DesktopMenuHOC(DesktopMenu);
