import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SettingsCard from 'components/SettingsCard';
import Icon from 'components/Icon';
import OrderDishesList from 'containers/OrderDishesListContainer';

import SpecialInstructionsCard from 'components/SpecialInstructionsCard';

import Modal from 'components/Modal';
import TouchHandler from 'components/TouchHandler';
import PaymentMethod from 'routes/Checkout/components/PaymentMethod/PaymentMethod';
import ScheduleSettings from 'routes/Checkout/containers/ScheduleSettings';

import DeliveryMethod from '../../../Checkout/containers/DeliveryMethod';
import Tip from './containers/TipContainer';
import OrderSummaryContainer from './containers/OrderSummaryContainer';
import { RedeemPoints } from 'routes/Checkout/RedeemPoints';
import { TableNumberInput } from 'routes/Checkout/components/TableNumberInput';
import Button from 'components/Button';

import Choises from './containers/ChoisesContainer';

const Checkout = ({
  price,
  onPlaceOrderClick,
  promocode,
  show,
  closeModal,
  showModalPromocode,
  paymentInvalid,
  onScrollableNodeRef,
  onPaymentsNodeRef,
  tipsAllowed,
  specialInstructions,
  onSpecialInstructionsChange,
  loyalty,
  redeemCurrency,
  toggleRedeemCurrency,
  dineInEnabled,
  dineInTable,
  setDineInTable,
  tableNumberInvalid,
  onTableNumberInputNodeRef,
  removePromocode,
  hideLoyaltyPopup,
  hidePromoApplied,
  promoPopupHidden,
  discountPrice,
  hideSpecialInstructions,
  hidePickupBox,
  checkoutOptionsAllowed,
  onChoisesNodeRef,
  checkoutOptionInvalid,
  checkoutOptions,
  deliveryType,
}) => (
  <Modal show={show} onCloseClick={closeModal} title="Your order">
    <ScrollableContentWrapper>
      <TouchHandler ref={onScrollableNodeRef}>
        <Content>
          {dineInEnabled ? (
            <ItemWrapper
              showError={tableNumberInvalid}
              ref={onTableNumberInputNodeRef}
            >
              <SettingsCard
                title="Dine-in table"
                showError={tableNumberInvalid}
              >
                <TableNumberInput
                  value={dineInTable}
                  onChange={setDineInTable}
                  error={tableNumberInvalid}
                />
              </SettingsCard>
            </ItemWrapper>
          ) : (
            <>
              <ItemWrapper>
                <DeliveryMethod ismobile hidePickupBox={hidePickupBox} />
              </ItemWrapper>
            </>
          )}
          <Inner>
            {checkoutOptionsAllowed && (
              <ItemWrapper
                showError={checkoutOptionInvalid}
                ref={onChoisesNodeRef}
              >
                <SettingsCard
                  whiteBg
                  title={checkoutOptions.title}
                  showError={checkoutOptionInvalid}
                >
                  <Choises />
                </SettingsCard>
              </ItemWrapper>
            )}

            {checkoutOptions.disclaimer && deliveryType && (
              <ItemWrapper>
                <Disclaimer>
                  {checkoutOptions.disclaimer.replace(
                    '{method}',
                    deliveryType.toLowerCase(),
                  )}
                </Disclaimer>
              </ItemWrapper>
            )}

            <ItemWrapper showError={paymentInvalid} ref={onPaymentsNodeRef}>
              <SettingsCard
                whiteBg
                title="Payment method"
                showError={paymentInvalid}
              >
                <PaymentMethod />
              </SettingsCard>
            </ItemWrapper>

            {tipsAllowed && (
              <ItemWrapper>
                <SettingsCard whiteBg title="Tip">
                  <Tip />
                </SettingsCard>
              </ItemWrapper>
            )}

            <ItemWrapper>
              <ItemsListContainer>
                <OrderDishesList readOnly />
              </ItemsListContainer>
            </ItemWrapper>

            {hideSpecialInstructions != 'both' &&
              hideSpecialInstructions != 'checkout' && (
                <ItemWrapper>
                  <SpecialInstructionsCard
                    value={specialInstructions}
                    onChange={onSpecialInstructionsChange}
                  />
                </ItemWrapper>
              )}

            <ItemWrapper>
              <Promo onClick={!promocode ? showModalPromocode : undefined}>
                <IconWrapper className={promocode ? 'promoApplied' : undefined}>
                  <Icon name="icon-promotions-small" size="34px" />
                </IconWrapper>
                <PromoButton>
                  {promocode ? (
                    <>
                      <span>Promotion Applied</span>
                      <PromocodeName>{promocode}</PromocodeName>
                      <RemoveButton onClick={removePromocode}>
                        Remove
                      </RemoveButton>
                    </>
                  ) : (
                    <>
                      <span>Enter Promo code</span>
                      <IconWrapperRight>
                        <Icon name="icon-arrow-right-small" size="25px" />
                      </IconWrapperRight>
                    </>
                  )}
                </PromoButton>
              </Promo>
              {promocode && !promoPopupHidden && (
                <>
                  <Container>
                    <SuccessWrapper>
                      <Icon name="icon-thank-you-small" size="175px" />
                    </SuccessWrapper>
                    <PromoAppliedCopy>
                      Discount Applied!
                      <p>
                        A discount of{' '}
                        <strong>${discountPrice.toFixed(2)}</strong> has been{' '}
                        <br /> applied to your order
                      </p>
                    </PromoAppliedCopy>
                    <Button onClick={hidePromoApplied} dark>
                      Got it
                    </Button>
                  </Container>
                  <Overlay onClick={hidePromoApplied} />
                </>
              )}
            </ItemWrapper>
            <ItemWrapper>
              <RedeemPoints
                loyalty={loyalty}
                redeemCurrency={redeemCurrency}
                toggleRedeemCurrency={toggleRedeemCurrency}
                hideLoyaltyPopup={hideLoyaltyPopup}
              />
            </ItemWrapper>

            <ItemWrapper>
              <OrderSummaryContainer />
            </ItemWrapper>
          </Inner>
        </Content>
      </TouchHandler>
    </ScrollableContentWrapper>

    <ButtonWrapper>
      <Button onClick={onPlaceOrderClick}>
        <Label>Place order · </Label>
        <Amount>${price.toFixed(2)}</Amount>
      </Button>
    </ButtonWrapper>
  </Modal>
);

Checkout.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  onPlaceOrderClick: PropTypes.func,
  price: PropTypes.number,
  promocode: PropTypes.string,
  showModalPromocode: PropTypes.func,
  paymentInvalid: PropTypes.bool.isRequired,
  tipsAllowed: PropTypes.bool.isRequired,
  onScrollableNodeRef: PropTypes.func.isRequired,
  onPaymentsNodeRef: PropTypes.func.isRequired,
  onTableNumberInputNodeRef: PropTypes.func.isRequired,
  specialInstructions: PropTypes.string.isRequired,
  onSpecialInstructionsChange: PropTypes.func.isRequired,
  loyalty: PropTypes.shape({}),
  redeemCurrency: PropTypes.number,
  toggleRedeemCurrency: PropTypes.func,
  dineInEnabled: PropTypes.bool.isRequired,
  dineInTable: PropTypes.number.isRequired,
  setDineInTable: PropTypes.func.isRequired,
  tableNumberInvalid: PropTypes.bool.isRequired,
  removePromocode: PropTypes.func,
  hideLoyaltyPopup: PropTypes.func,
  hidePromoApplied: PropTypes.func,
  hideSpecialInstructions: PropTypes.string,
  hidePickupBox: PropTypes.bool,
  deliveryType: PropTypes.string,
};

Checkout.defaultProps = {
  loyalty: undefined,
  redeemCurrency: null,
  toggleRedeemCurrency: undefined,
  hideLoyaltyPopup: undefined,
  hidePromoApplied: undefined,
};

const ScrollableContentWrapper = styled.div`
  position: relative;
  height: calc(100% - 83px);
`;

const Content = styled.div``;

const ItemsListContainer = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Inner = styled.div`
  width: 100%;
  max-width: 440px;
  margin: 0 auto;
`;

const Amount = styled.div`
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  font-size: 17px;
  padding-left: 6px;
`;

const Label = styled.div`
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  display: inline-block;
  font-size: 19px;
  font-weight: normal;
`;

const ItemWrapper = styled.div`
  margin: 17px 15px;
  border-radius: 2px;
  border: ${p => p.showError && '2px solid red'};
`;

const Promo = styled.button`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 15px;
  position: relative;
  span {
    text-transform: initial;
  }

  .promoApplied {
    margin-top: -20px;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #424648;
`;

const PromoButton = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  outline: none;
  border: none;
  padding: 5px 0;
  margin-left: 15px;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }

  span {
    color: #424648;
  }
`;

const ButtonWrapper = styled.div`
  background: #fff;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 11000;
  transform: translate3d(0, 0, 0);

  button {
    padding: 17px 10px;
  }
`;

const PromocodeName = styled.div`
  font-size: 13px;
  font-weight: 300;
  text-transform: capitalize;
  padding-top: 10px;
  color: #424648;
`;
const IconWrapperRight = styled.div`
  position: absolute;
  top: 22px;
  right: 25px;
  color: #424648;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 23px;
  right: 1px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: #c93b32;
  text-transform: none;
`;

const Container = styled.div`
  padding: 16px;
  z-index: 11002;
  background-color: #ffffff;
  position: fixed !important;
  margin-top: -162px;
  border-radius: 3px;
  width: 350px;
  max-width: 90%;
  top: 50%;
  left: 50%;
  margin-left: -175px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11001;
  content: '';
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
`;

const PromoAppliedCopy = styled.div`
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  display: block;
  clear: both;
  font-family: 'Montserrat';
  font-weight: 300;
  color: #424648;

  p {
    color: #424648;
    font-size: 16px;
    display: block;
    margin: 15px 0px 32px 0px;
    line-height: 24px;
    font-family: 'Roboto';
  }

  strong {
    font-weight: 500;
  }
`;

const SuccessWrapper = styled.div`
  clear: both;
  display: block;
  height: 100px;
  color: #54a300;
  margin-bottom: 10px;
`;

const Disclaimer = styled.div`
  clear: both;
  display: block;
  margin: 15px 0px 10px 0px;
  padding: 10px;
  background: #ffe503;
  line-height: 150%;
  text-align: center;
  color: #0d0e0e;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
`;

export default Checkout;
