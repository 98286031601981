import { createSelector } from 'reselect';
import { orderingSettingsSelector } from 'redux/modules/general';
import { deliveryTypes, deliverySelector } from 'redux/modules/delivery';
import { getDeliveryType } from 'selectors/getDeliveryType';

const maxLoyaltySelector = createSelector(
  [getDeliveryType, deliverySelector],
  (deliveryType, { rewardLimit, deliveryCharge }) =>
    deliveryType === deliveryTypes.DELIVERY && rewardLimit ? +rewardLimit : 0,
);

export default maxLoyaltySelector;
