import React from 'react';
import PropTypes from 'prop-types';
import forgotPasswordHOC from 'HOC/forgotPasswordHOC';
import SubFooter from 'components/SubFooter';
import ModalComponents from './ModalComponents';
import styled from 'styled-components';
import { ReactComponent as EmailIcon } from 'assets/reset-email.svg';
import { ReactComponent as PhoneIcon } from 'assets/reset-phone.svg';
import TextField from '@material-ui/core/TextField';
import Button from 'components/Button';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import InvisibleSubmit from 'components/InvisibleSubmit';

const ForgotPassword = ({
  title,
  login,
  values: { makedEmail, lastName },
  sentVia,
  handleSubmit,
  sendSMScode,
  sendEmailCode,
  errors,
  touched,
  handleChange,
  handleBlur,
  enableSMSMethod
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
          
          <SelectText>Select which contact method should we use to recover your password</SelectText>

          <ResetPasswordButton onClick={enableSMSMethod}>
            <IconHolder><PhoneIcon /></IconHolder>
            <ResetPasswordVia>Via SMS</ResetPasswordVia>
            <ResetPasswordValue>•••• •••• {login.slice(-4)}</ResetPasswordValue>
          </ResetPasswordButton>
          {sentVia === 'sms' && (
            <ModalComponents.Form onSubmit={sendSMScode}>
                <AutoFocusIfNonTouchDevice>
                  {autoFocus => (
                    <TextField
                      label="Enter your Last Name"
                      placeholder="Last Name"
                      value={lastName}
                      name="lastName"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.lastName &&
                        errors.lastName &&
                        errors.lastName.length &&
                        errors.lastName.map(err => <p>{err}</p>)
                      }
                      error={
                        touched.lastName && errors.lastName && errors.lastName.length
                      }
                      autofocus={autoFocus}
                      fullWidth
                    />
                  )}
                </AutoFocusIfNonTouchDevice>
                <ModalComponents.FormButton>
                <Button fullWidth onClick={handleSubmit} disabled={lastName.length == 0}>
                    CONTINUE
                </Button>
                </ModalComponents.FormButton>
                <InvisibleSubmit />
            </ModalComponents.Form>
          )}
          {sentVia !== 'sms' && (
          <ResetPasswordButton onClick={sendEmailCode}>
            <IconHolder><EmailIcon /></IconHolder>
            <ResetPasswordVia>Via Email</ResetPasswordVia>
            <ResetPasswordValue>{makedEmail ? makedEmail : '••••••@••••••.•••'}</ResetPasswordValue>
          </ResetPasswordButton>
          )}
        
      
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);


const IconHolder = styled.div`
    float: left;
    margin-right: 20px;
`;

const ResetPasswordButton = styled.div`
    display: block;
    background: white;
    margin: 10px 0px;
    padding: 23px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0px 2px 10px #00000012;
    
`;

const ResetPasswordVia = styled.div`
      text-align: left;
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      letter-spacing: 0px;
      color: #5E6263;
      font-weight: 200;
      margin-bottom: 10px;
      padding-top: 5px;
`;

const ResetPasswordValue = styled.div`
      text-align: left;
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 200;
`;

const SelectText = styled.div`
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      letter-spacing: 0px;
      color: #5E6263;
      font-weight: 100;
      margin:20px 10px;
`;

ForgotPassword.propTypes = {
  title: PropTypes.string.isRequired,
  login: PropTypes.string,
  values: PropTypes.shape({ lastName: PropTypes.string }).isRequired,
  errors: PropTypes.shape({ lastName: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ lastName: PropTypes.bool }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sendSMScode: PropTypes.func.isRequired,
  sendEmailCode: PropTypes.func.isRequired,
  onEmailOrNumberFieldChange: PropTypes.func.isRequired,
  makedEmail: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  enableSMSMethod: PropTypes.func.isRequired,
};

ForgotPassword.defaultProps = {
  login: undefined,
  makedEmail: '',
};

export default forgotPasswordHOC(ForgotPassword);
