import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/Icon';
import NumericInput from 'react-numeric-input';

const Choise = ({ id, checked, title, value, description, onClick }) => (
  <Button key={id} checked={checked} onClick={onClick}>
    <Circle active={checked} />
    <Name active={checked}>{value}</Name>
    <ChoiseDescription>{description}</ChoiseDescription>
  </Button>
);

Choise.propTypes = {
  id: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.node.isRequired,
};

const Choises = ({
  checkoutOptions,
  onTipInputClick,
  onChoiseClick,
  selectedOptionIndex,
  valid,
}) => (
  <Wrapper>
    <Top>
      {checkoutOptions.map((optionsChoice, index) => (
        <Choise
          id={optionsChoice}
          checked={selectedOptionIndex === index}
          description={optionsChoice.description}
          value={optionsChoice.value}
          onClick={onChoiseClick(optionsChoice)}
        />
      ))}
    </Top>
  </Wrapper>
);

Choises.propTypes = {
  valid: PropTypes.bool.isRequired,
  checkoutOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedOptionIndex: PropTypes.number,
  onChoiseClick: PropTypes.func.isRequired,
};

Choises.defaultProps = {
  selectedOptionIndex: undefined,
};

const Wrapper = styled.div`
  background: #fff;
`;

const ChoiseDescription = styled.div`
  background: #fff;
  flex: 0 0 100%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  font-weight: 300;
  padding-left: 30px;
  padding-top: 10px;
`;

const Top = styled.div`
  display: block;
`;

const Button = styled.button`
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  border-bottom: 1px solid #eeefef;
`;

const Circle = styled.div`
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  border: ${({ active }) => (active ? 'none' : '2px solid #929292')};
  background: ${({ active }) => (active ? '#54a300' : 'transparent')};
`;

const Name = styled.div`
  margin-left: 14px;
  flex: 1 1 auto;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: ${p => (p.disabled ? '#BDBDBD' : 'rgb(66, 70, 72)')};
  font-weight: ${({ active }) => (active ? 'bold' : '400')};
`;

const Label = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  padding: 15px;
  border-right: 1px solid #eeefef;
  min-width: 98px;
`;

const Value = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  :focus-within {
    border: 1px solid #54a300;
  }
`;

const Checked = styled.div`
  flex: 1 1 auto;
  min-width: 20px;
  min-height: 15px;
  width: 20px;
  height: 15px;
  color: #55a30b;
`;

export default Choises;
