import {
  compose,
  branch,
  renderNothing,
  withProps,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import {
  actions as cartActions,
  redeemCurrencySelector,
  loyaltyHiddenSelector,
  promocodeSelector,
} from 'redux/modules/cart';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';

import withLoyalty from 'HOC/withLoyalty';

import inform from 'utils/inform';

import deliveryCostSelector from 'selectors/deliveryCostSelector';
import maxLoyaltySelector from 'selectors/maxLoyaltySelector';
import fixedLoyaltySelector from 'selectors/fixedLoyaltySelector';

export const redeemPointsHOC = compose(
  withLoyalty,
  branch(({ loyaltyEnabled }) => !loyaltyEnabled, renderNothing),
  connect(
    state => ({
      redeemCurrency: redeemCurrencySelector(state),
      cartSubtotal: cartSubtotalSelector(state),
      deliveryCost: deliveryCostSelector(state),
      promocode: promocodeSelector(state),
      loyaltyPopupHidden: promocodeSelector(state)
        ? true
        : loyaltyHiddenSelector(state),
      maxLoyaltyDiscount: maxLoyaltySelector(state),
      fixedLoyalty: fixedLoyaltySelector(state),
    }),
    {
      setRedeemCurrency: cartActions.setRedeemCurrency,
      setLoyaltyPopupHidden: cartActions.setLoyaltyPopupHidden,
    },
  ),
  withProps(
    ({
      loyalty: {
        consumer: { pointsInCurrency,pointsOnlineInCurrency },
        restaurantSettings: { earnPointsRate },
      },
      cartSubtotal,
      deliveryCost,
      maxLoyaltyDiscount,
      fixedLoyalty,
    }) => ({
      maxApplicablePoints: !fixedLoyalty
        ? Math.max(
            0,
            Math.min(
              pointsOnlineInCurrency,
              (cartSubtotal - maxLoyaltyDiscount - 2) * 100,
            ),
          )
        : pointsOnlineInCurrency >= fixedLoyalty * 100
        ? fixedLoyalty * 100
        : 0,
      remainingPoints: fixedLoyalty
        ? fixedLoyalty * 100 - pointsOnlineInCurrency
        : -1,
      minOrderForReward: fixedLoyalty
        ? fixedLoyalty
          ? (fixedLoyalty + 2) * 100
          : 0
        : 0,
      minOrderReached: !fixedLoyalty || cartSubtotal >= fixedLoyalty + 2,
      consumerPoints: (pointsOnlineInCurrency * 100) / earnPointsRate / 100,
      poinstToReach: Math.round((fixedLoyalty / earnPointsRate) * 100),
    }),
  ),
  withHandlers({
    toggleRedeemCurrency: ({
      maxApplicablePoints,
      redeemCurrency,
      setRedeemCurrency,
      promocode,
      fixedLoyalty,
      remainingPoints,
      minOrderReached,
    }) => () => {
      if (!promocode) {
        if (minOrderReached)
          setRedeemCurrency(redeemCurrency ? null : maxApplicablePoints);
        else
          inform({
            title: 'Oops...',
            message: `To apply this reward, minimum order must be $${fixedLoyalty +
              2}`,
            buttonText: 'OK',
          });
      } else
        inform({
          title: 'Oops...',
          message:
            'Discount codes and Rewards cannot be combined. Only one discount at a time can apply to a single order.',
          buttonText: 'OK',
        });
    },
    hideLoyaltyPopup: ({ setLoyaltyPopupHidden }) => () => {
      setLoyaltyPopupHidden();
    },
  }),
);
