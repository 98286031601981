import { createSelector } from 'reselect';
import values from 'lodash/values';
import omit from 'lodash/omit';

import {
  orderingSettingsSelector,
  checkoutOptionsSelector,
} from 'redux/modules/general';
import { deliverySelector } from 'redux/modules/delivery';

const cartSelector = state => state.cart;
export const cartItemsSelector = createSelector(
  cartSelector,
  cart => cart.items,
);

export const orderDishesKeysSelector = createSelector(cartItemsSelector, cart =>
  Object.keys(omit(cart, 'removing')),
);

export const autoPromocodeSelector = createSelector(
  cartSelector,
  cart => cart.autoPromocode,
);

export const promocodeSelector = createSelector(
  cartSelector,
  cart => cart.promocode,
);

export const redeemCurrencySelector = createSelector(
  cartSelector,
  cart => cart.redeemCurrency,
);

export const promocodeActionSelector = createSelector(
  cartSelector,
  cart => cart.promocodeAction,
);

export const tipSelector = createSelector(
  [orderingSettingsSelector, cartSelector, deliverySelector],
  ({ defaultTip }, cart, delivery) =>
    cart.tip !== undefined
      ? cart.tip
      : cart.tipApplied
      ? undefined
      : delivery.type == 'DELIVERY'
      ? defaultTip
      : undefined,
);

export const tipAppliedSelector = createSelector(
  cartSelector,
  cart => cart.tipApplied,
);

export const choiseSelector = createSelector(
  [cartSelector, checkoutOptionsSelector],
  (cart, { defaultOption }) =>
    cart.choise !== undefined && cart.choise !== null
      ? cart.choise
      : cart.choiseApplied
      ? undefined
      : defaultOption,
);

export const specialInstructionsSelector = createSelector(
  cartSelector,
  cart => cart.specialInstructions,
);

export const paymentMethodSelector = createSelector(
  cartSelector,
  cart => cart.paymentMethod,
);

export const getDishesCount = createSelector(cartItemsSelector, cartItems =>
  values(cartItems).reduce((count, dishInfo) => count + dishInfo.count, 0),
);

export const backendCartProductsSelector = createSelector(
  cartItemsSelector,
  cartItems =>
    cartItems &&
    Object.values(cartItems).map(
      ({ dishId: id, count: quantity, specialInstructions, variations }) => ({
        id,
        quantity,
        specialInstructions,
        variations: Object.values(variations).map(
          ({ id: variationId, type, value }) => ({
            id: variationId,
            type,
            options: Object.keys(value).map(k => +k),
            ...(['pizza_toppings', 'mix_and_match'].includes(type) && {
              left: Object.entries(value)
                .filter(([_, v]) => v === -0.5)
                .map(([k]) => +k),
              right: Object.entries(value)
                .filter(([_, v]) => v === 0.5)
                .map(([k]) => +k),
            }),
            ...(['pizza_toppings', 'pizza_toppings_small'].includes(type) && {
              full: Object.entries(value)
                .filter(([_, v]) => v === 1)
                .map(([k]) => +k),
            }),
            ...(type === 'combo' && {
              options: [].concat(
                ...Object.entries(value).map(([k, v]) => Array(v).fill(+k)),
              ),
            }),
          }),
        ),
      }),
    ),
);

export const loyaltyHiddenSelector = createSelector(
  cartSelector,
  cart => cart.loyaltyPopupHidden,
);

export const promoPopupHiddenSelector = createSelector(
  cartSelector,
  cart => cart.promoPopupHidden,
);

export const upsellItemsPopupHiddenSelector = createSelector(
  cartSelector,
  cart => cart.upsellItemsPopupHidden,
);

export const nonceSelector = createSelector(cartSelector, cart => cart.nonce);

export const backendFeeSelector = createSelector(
  cartSelector,
  cart => cart.backendFee,
);

export const sessionIdSelector = createSelector(
  cartSelector,
  cart => cart.sessionId,
);
