import {
  compose,
  withProps,
  defaultProps,
  branch,
  renderNothing,
} from 'recompose';
import has from 'lodash/has';
import { connect } from 'react-redux';
import availableAtDateItemsSelector from 'selectors/availableAtDateItemsSelector';
import { cartItemsSelector } from 'redux/modules/cart';
import DishCard from '../components/ProductCard';
import { ageVerificationSelector } from 'redux/modules/general';
import { getDeliveryType } from 'selectors/getDeliveryType';
import ownDriversSelector from 'selectors/ownDriversSelector';

const DishCardHOC = compose(
  connect(state => ({
    items: availableAtDateItemsSelector(state),
    basketDishes: cartItemsSelector(state),
    ageVerification: ageVerificationSelector(state),
    deliveryType: getDeliveryType(state),
    ownDrivers: ownDriversSelector(state),
  })),
  defaultProps({
    items: {},
    basketDishes: {},
  }),
  withProps(({ id, items, basketDishes }) => ({
    item: {
      ...items[id],
      count: Object.values(basketDishes)
        .filter(item => item.dishId === id)
        .reduce((sum, orderItem) => sum + orderItem.count, 0),
    },
  })),
  withProps(({ item, ageVerification, deliveryType, ownDrivers }) => ({
    item: {
      ...item,
      price: item.overwrittenPriceRange || item.price,
      notAllowedForDelivery:
        deliveryType == 'DELIVERY' &&
        !ownDrivers &&
        item.variations &&
        item.variations.includes(ageVerification),
    },
  })),
  defaultProps({
    item: {},
  }),
  branch(({ item }) => !has(item, 'id'), renderNothing),
);

export default DishCardHOC(DishCard);
