import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { media } from 'utils/mediaQueries';

import Icon from 'components/Icon';

import { ThankYouPageDeliveryTimeInfo } from 'components/DishesList';
import MapAddress, { styles as mapAddressStyles } from 'components/MapAddress';
import { Item } from 'components/Card';
import OrderCardItemDetails from 'routes/Orders/components/OrderCardItemDetails';
import IconClean from 'components/IconClean';

const OrderDetails = ({
  orderId,
  history,
  deliveryDay,
  deliveryTime,
  deliveryType,
  address,
  dineInEnabled,
  lineI,
  total,
  totals,
  paidwith,
  restaurantInfo,
  firstName,
  SpecialInstructions,
  discounts,
  status,
}) => (
  <Content>
    {total > 0 && (
      <>
        <ButtonWrapper>
          <Button
            onClick={() => {
              history.action !== 'POP' && history.lenght > 1
                ? history.goBack()
                : history.push('/menu');
            }}
          >
            <IconClean name="icon-cross-thin-small" size="36px" />
          </Button>
        </ButtonWrapper>

        <Container>
          <LogoName>
            <Logo src={restaurantInfo.logo} alt={restaurantInfo.name} />
            <OrderDay>
              <TotalTop>
                <span>Total:</span>
                {status == 'cancelled' ? (
                  <LineThrough>${total.toFixed(2)}</LineThrough>
                ) : discounts > 0 ? (
                  <>
                    <LineThrough>${total.toFixed(2)}</LineThrough>
                    <TotalBold>${(total - discounts).toFixed(2)}</TotalBold>
                  </>
                ) : (
                  <>
                    <TotalBold>${total.toFixed(2)}</TotalBold>
                  </>
                )}
              </TotalTop>
              {deliveryDay}
            </OrderDay>
            <ThanksFor>Thanks for ordering, </ThanksFor>
            <ConsumerName>{firstName}</ConsumerName>
          </LogoName>

          <Greetings>
            <Message>
              <SmallIconWrapper>
                <Icon name="icon-orders-small" size="28px" />
              </SmallIconWrapper>
              <Label tabIndex={0}>
                Order #{orderId}
                {status == 'cancelled' && (
                  <CancelledTag>Cancelled</CancelledTag>
                )}
                {status != 'cancelled' && discounts > 0 && (
                  <PartialTag>Partial Refund</PartialTag>
                )}
              </Label>
            </Message>
            <ThankYouPageDeliveryTimeInfo
              deliveryDay=""
              deliveryTime={deliveryTime}
              deliveryType={deliveryType}
              dineInEnabled={dineInEnabled}
            />
            {!!Object.keys(address).length && deliveryType == 'DELIVERY' && (
              <MapItem>
                <MapAddressWrapper>
                  <MapAddress method={deliveryType} address={address} />
                </MapAddressWrapper>
              </MapItem>
            )}
          </Greetings>

          {status !== 'cancelled' ? (
            discounts == 0 ? (
              <OrderTotal>
                <FeeLabel>Total</FeeLabel>
                <FeeTotal>${total.toFixed(2)}</FeeTotal>
                <PaidWith>Paid with {paidwith} </PaidWith>
              </OrderTotal>
            ) : (
              <OrderTotal>
                <FeeLabel>Total</FeeLabel>
                <FeeTotal>
                  <LineThrough>${total.toFixed(2)}</LineThrough> $
                  {(total - discounts).toFixed(2)}
                </FeeTotal>
                <PaidWith>Paid with {paidwith} </PaidWith>
              </OrderTotal>
            )
          ) : (
            <OrderTotal>
              <FeeLabel>Total</FeeLabel>
              <FeeTotal>
                <LineThrough>${total.toFixed(2)}</LineThrough>
              </FeeTotal>
              {paidwith == 'Credit Card' && (
                <>
                  <PaidWith>Refunded</PaidWith>
                  <RefundDisclosure>
                    Refund can take 5-10 business days, depending upon your
                    bank.
                  </RefundDisclosure>
                </>
              )}
            </OrderTotal>
          )}
          {status !== 'cancelled' && (
            <>
              <Items>
                {Object.values(lineI).map(cartItem => (
                  <OrderCardItemDetails
                    key={cartItem.id}
                    cartItem={cartItem}
                    lineItem={cartItem.lineItem}
                    legacy={false}
                    specialInstructions={cartItem.specialInstructions}
                  />
                ))}
              </Items>

              {SpecialInstructions != '' && (
                <GlobalSpecialInstructions>
                  {SpecialInstructions}
                </GlobalSpecialInstructions>
              )}

              {Object.values(totals).map(totalItem =>
                totalItem.label == 'Subtotal:' ? (
                  <FeeItemSubtotal>
                    <FeeLabel>{totalItem.label}</FeeLabel>
                    <FeeTotal>${totalItem.total.toFixed(2)}</FeeTotal>
                  </FeeItemSubtotal>
                ) : totalItem.total > 0 ? (
                  <FeeItem>
                    <FeeLabel>{totalItem.label}</FeeLabel>
                    <FeeTotal>${totalItem.total.toFixed(2)}</FeeTotal>
                  </FeeItem>
                ) : totalItem.isRefund ? (
                  <FeeItem>
                    <FeeLabelRefund>
                      Partial Refund ({totalItem.label}):
                    </FeeLabelRefund>
                    <FeeTotalRefund>
                      -${(totalItem.total * -1).toFixed(2)}
                    </FeeTotalRefund>
                  </FeeItem>
                ) : (
                  <FeeItem>
                    <FeeLabel>{totalItem.label}</FeeLabel>
                    <FeeTotalDiscount>
                      -${(totalItem.total * -1).toFixed(2)}
                    </FeeTotalDiscount>
                  </FeeItem>
                ),
              )}
            </>
          )}

          <OrderClose />
        </Container>
      </>
    )}
  </Content>
);

OrderDetails.propTypes = {
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  deliveryType: PropTypes.string,
  deliveryDay: PropTypes.string,
  deliveryTime: PropTypes.string,
  history: PropTypes.object,
  address: PropTypes.shape({
    address: PropTypes.string,
    aptSuiteFloor: PropTypes.string,
    businessName: PropTypes.string,
    id: PropTypes.number,
    placeId: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool,
    specialInstructions: PropTypes.string,
  }),
  dineInEnabled: PropTypes.bool.isRequired,
  lineI: PropTypes.object,
  total: PropTypes.number,
  totals: PropTypes.object,
  paidwith: PropTypes.string,
};

export default OrderDetails;

const Content = styled.div`
  padding: 0px 15px 95px 15px;
  width: 100%;
  max-width: 440px;
  margin: -30px auto 0 auto;

  ${media.tablet`
    margin-top: 100px;
  `};

  ${media.tablet`
    margin-top: 20px;
  `};
`;

const MapAddressWrapper = styled.div`
  flex: 1 1 auto;
  font-weight: 300;

  ${mapAddressStyles.Name} {
    margin-bottom: 6px;
  }

  ${mapAddressStyles.Description} {
    font-size: 14px;

    > p {
      margin-bottom: 4px;
    }
  }
`;

const Container = styled.div`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Greetings = styled.div`
  padding: 0px;
  border-bottom: 1px solid #eeefef;
`;

const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 auto;
  color: #54a30a;
`;

const Items = styled.div``;

const Text = styled.div`
  font-size: 23px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  text-align: center;
  margin-top: 25px;
`;

const Message = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 18px 15px;
  border-top: 1px solid #eeefef;
`;

const SmallIconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #97999b;
`;

const MapItem = styled(Item)`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  padding: 0px;
`;

const Label = styled.div`
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  margin-left: 15px;
  width: 100%;
`;

const FeeItem = styled.div`
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: 300;
  clear: both;
  padding: 0px 16px;
`;

const FeeItemSubtotal = styled.div`
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: bold;
  clear: both;
  padding: 0px 16px;
`;

const FeeLabel = styled(Item)`
  display: inline-block;
  float: left;
  border: none !important;
  padding: 5px 0px;
`;

const FeeTotal = styled(Item)`
  display: inline-block;
  float: right;
  border: none !important;
  padding: 5px 0px;
`;

const FeeTotalDiscount = styled(Item)`
  display: inline-block;
  float: right;
  border: none !important;
  padding: 5px 0px;
  color: rgb(84, 163, 0);
`;

const FeeLabelRefund = styled(Item)`
  display: inline-block;
  float: left;
  border: none !important;
  padding: 5px 0px;
  color: red;
`;

const FeeTotalRefund = styled(Item)`
  display: inline-block;
  float: right;
  border: none !important;
  padding: 5px 0px;
  color: red;
`;

const OrderTotal = styled.div`
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  font-weight: bold;
  clear: both;
  float: left;
  width: 100%;
  padding: 0px 14px 10px 14px;
  margin-top: 10px;
  margin-bottom: 3px;
  border-bottom: 1px solid #eeefef;
`;

const PaidWith = styled(Item)`
  display: block;
  text-align: right;
  border: none !important;
  font-size: 14px;
  clear: both;
  padding: 10px 0px;
  font-weight: normal;
`;

const RefundDisclosure = styled(Item)`
  display: block;
  text-align: center;
  border: none !important;
  font-size: 11px;
  clear: both;
  padding: 10px 0px;
  font-weight: normal;
`;

const Button = styled.button`
  font-family: 'foodieorder';
  font-weight: bold;
  text-align: left;
  ${media.tablet`
    cursor: pointer;
  `};
  i:before {
    font-weight: bold;
  }
`;

const ButtonWrapper = styled.div`
  margin: 10px 0px;
  background: #f3f4f5;
  text-align: right;
`;

const Logo = styled.div`
  background: url(${p => p.src}) no-repeat center top / contain;
  height: 100px;
  width: 150px;
  float: left;
`;
const OrderDay = styled.div`
  display: block;
  height: 15px;
  float: right;
  font-size: 15px;
  text-align: right;
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const TotalTop = styled.div`
  clear: both;
  display: block;
  span {
    padding-right: 10px;
  }
  margin-bottom: 8px;
`;

const CancelledTag = styled.div`
  color: red;
  float: right;
  font-size: 18px;
  font-weight: bold;
`;

const PartialTag = styled.div`
  color: rgb(66, 70, 72);
  float: right;
  font-size: 18px;
  font-weight: bold;
`;

const LogoName = styled.div`
  padding: 20px 16px 0px 16px;
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 20px;
`;
const ThanksFor = styled.div`
  padding: 20px 0px 10px 0px;
  clear: both;
`;
const ConsumerName = styled.div``;

const GlobalSpecialInstructions = styled.div`
  padding: 0px 15px 10px 15px;
  font-size: 12px;
  color: #777a7b;
  font-style: italic;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
`;

const LineThrough = styled.div`
  text-decoration: line-through;
  color: red;
  display: inline-block;
  padding-right: 5px;
`;

const OrderClose = styled.div`
  display: block;
  width: 100%;
  clear: both;
  height: 15px;
`;

const TotalBold = styled.div`
  font-weight: bold;
  display: inline-block;
`;
