import {
  compose,
  withStateHandlers,
  withHandlers,
  branch,
  renderComponent,
} from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import userIsNotAuthenticatedHOC from 'HOC/userIsNotAuthenticatedHOC';
import { actions as userActions } from 'redux/modules/user';
import { actions as modalActions } from 'components/ReduxModal';

import EnterPassword from 'routes/Modals/EnterPassword';
import cleanPhoneNumber from 'utils/cleanPhoneNumber';

const signInHOC = compose(
  withRouter,
  userIsNotAuthenticatedHOC,
  connect(null, {
    validateEmail: userActions.validateEmail,
    showModal: modalActions.showModal,
  }),
  // I put that in state in order to prevent function disappearing when history.state changes
  withStateHandlers(
    ({ history, showModal, location: { state: { afterSignInDo } = {} } }) => {
      if (afterSignInDo) {
        const actionMapping = {
          historyPush: history.push,
          showModal,
        };
        return {
          afterSignInDo: () =>
            afterSignInDo.forEach(({ action, args }) =>
              actionMapping[action](...args),
            ),
        };
      }
      return {};
    },
  ),
  withFormik({
    mapPropsToValues: ({ location: { state: { login } = {} } }) => ({
      loginField: login || '',
      login: login || '',
    }),
    validate: ({ loginField }) => {
      const errors = {};
      const cleanedPhoneNumber = cleanPhoneNumber(loginField);
      if (!cleanedPhoneNumber) {
        errors.loginField = 'Required';
      }
      if (cleanedPhoneNumber.length < 10) {
        errors.loginField = 'Enter 10 digits';
      }
      return errors;
    },
    handleSubmit: (
      { loginField },
      { props: { validateEmail }, setFieldValue, setFieldError },
    ) => {
      validateEmail(
        { email: loginField },
        () => {
          setFieldValue('login', loginField);
        },
        err => {
          setFieldError('loginField', err);
        },
      );
    },
  }),
  withHandlers({
    onLoginFieldChange: ({ setFieldValue }) => v =>
      setFieldValue('loginField', v),
    onLoginChange: ({ resetForm, setValues, history }) => () => {
      // history.push({ state: {} });
      setValues({
        loginField: '',
        login: '',
      });
      resetForm();
    },
  }),
  branch(({ values: { login } }) => !!login, renderComponent(EnterPassword)),
);

export default signInHOC;
