import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ShowModeAsap from './Asap';
import DeliveryTime from './DeliveryTime';
import { applicationTypeSelector } from 'redux/modules/general';

const SCHEDULE_TYPES = ['SOONEST', 'SCHEDULE'];

const ScheduleSettings = ({
  scheduleType,
  scheduleDate,
  enableEditMode,
  cantinaWeek,
  goToEditSchedule,
  externalDelivery,
  method,
  hidePickupBox,
}) => (
  <Wrapper
    style={{ zIndex: 1 }}
    onClick={
      (externalDelivery && method == 'DELIVERY') || hidePickupBox
        ? goToEditSchedule
        : enableEditMode
    }
  >
    {scheduleType === 'SOONEST' ? (
      <ShowModeAsap />
    ) : (
      <DeliveryTime
        description={scheduleDate}
        cantinaEnabled={Boolean(cantinaWeek)}
      />
    )}
  </Wrapper>
);

ScheduleSettings.propTypes = {
  scheduleType: PropTypes.oneOf(SCHEDULE_TYPES).isRequired,
  enableEditMode: PropTypes.func.isRequired,
  goToEditSchedule: PropTypes.func.isRequired,
  scheduleDate: PropTypes.string.isRequired,
  cantinaWeek: PropTypes.shape({
    start: PropTypes.object,
    stop: PropTypes.object,
  }),
  method: PropTypes.string,
  hidePickupBox: PropTypes.bool,
};

ScheduleSettings.defaultProps = {
  cantinaWeek: null,
};

export default ScheduleSettings;

const Wrapper = styled.div`
  position: relative;
  z-index: 20;
`;
