import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import startsWith from 'lodash/startsWith';
import { Link } from 'react-router-dom';
import FocusLock from 'react-focus-lock';

import MenuSearch from 'components/MenuSearch';
import CategoriesScrollSpy from 'components/CategoriesScrollSpy';
import IconClean from 'components/IconClean';
import NavigationHeaderHOC from './NavigationHeaderHOC';

const NavigationHeader = ({
  location: { pathname },
  children,
  backButtonHandler,
  backButtonVisible,
  favoritesButtonActive,
  showLoginModal,
  searchBarVisible,
  showSearchBar,
  hideSearchBar,
}) =>
  searchBarVisible ? (
    <FocusLock>
      <MenuSearch hideSearchBar={hideSearchBar} />
    </FocusLock>
  ) : (
    <Wrapper>
      <HeaderBlock>
        <HeaderLine>
          <Left>
            {backButtonVisible && (
              <IconButton onClick={backButtonHandler} aria-label="Back">
                <IconClean name="icon-arrow-left-nomargin" size="20px" />
              </IconButton>
            )}
          </Left>
          <Title>{children}</Title>
          <Right>
            {startsWith(pathname, '/menu') && (
              <>
                <FavoritesButtonWrapper>
                  {favoritesButtonActive ? (
                    <Link to="/my-account/favorites">
                      <IconClean
                        name="icon-favorite-small"
                        size="40px"
                        aria-label="Favorites"
                        role="button"
                      />
                    </Link>
                  ) : (
                    <IconButton onClick={showLoginModal} aria-label="Favorites">
                      <IconClean name="icon-favorite-small" size="40px" />
                    </IconButton>
                  )}
                </FavoritesButtonWrapper>
                <IconButton onClick={showSearchBar} aria-label="Search">
                  <IconClean name="icon-search" size="20px" />
                </IconButton>
              </>
            )}
          </Right>
        </HeaderLine>
        {startsWith(pathname, '/menu') && <CategoriesScrollSpy />}
      </HeaderBlock>
    </Wrapper>
  );

NavigationHeader.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  backButtonHandler: PropTypes.func.isRequired,
  backButtonVisible: PropTypes.bool.isRequired,
  favoritesButtonActive: PropTypes.bool.isRequired,
  showLoginModal: PropTypes.func.isRequired,
  searchBarVisible: PropTypes.bool.isRequired,
  showSearchBar: PropTypes.func.isRequired,
  hideSearchBar: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0 15px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 30;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const HeaderBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;
const HeaderLine = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  text-align: center;
  font-weight: 300;
  letter-spacing: 0.2px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Left = styled.div``;

const Right = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  a {
    color: black;
  }
`;

const FavoritesButtonWrapper = styled.div`
  ${IconClean} {
    padding-left: 4px;
    margin-right: -12px;
    transform: translateY(-1px);
  }
`;

const IconButton = styled.button`
  width: auto;
`;

export default NavigationHeaderHOC(NavigationHeader);
