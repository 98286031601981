import React from 'react';
import PropTypes from 'prop-types';
import validateLastNameHOC from 'HOC/validateLastNameHOC';
import SubFooter from 'components/SubFooter';
import ModalComponents from './ModalComponents';
import styled from 'styled-components';
import { ReactComponent as EmailIcon } from 'assets/reset-email.svg';
import { ReactComponent as PhoneIcon } from 'assets/reset-phone.svg';
import TextField from '@material-ui/core/TextField';
import Button from 'components/Button';
import AutoFocusIfNonTouchDevice from 'components/AutoFocusIfNonTouchDevice';
import InvisibleSubmit from 'components/InvisibleSubmit';

const ValidateLastName = ({
  login,
  values: { lastName },
  handleSubmit,
  sendSMScode,
  errors,
  touched,
  handleChange,
  handleBlur,
  enableSMSMethod,
  makedEmail
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <LastNameTitle>Confirm Your Identity</LastNameTitle>
          
          <SelectText>For security purposes, enter your last name to verify vour identity.</SelectText>

            <ModalComponents.Form onSubmit={sendSMScode}>
                <AutoFocusIfNonTouchDevice>
                  {autoFocus => (
                    <TextField
                      label="Enter your Last Name"
                      placeholder="Last Name"
                      value={lastName}
                      name="lastName"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.lastName &&
                        errors.lastName &&
                        errors.lastName.length &&
                        errors.lastName.map(err => <p>{err}</p>)
                      }
                      error={
                        touched.lastName && errors.lastName && errors.lastName.length
                      }
                      autofocus={autoFocus}
                      fullWidth
                    />
                  )}
                </AutoFocusIfNonTouchDevice>
                <ModalComponents.FormButton>
                <Button fullWidth onClick={handleSubmit} disabled={lastName.length == 0}>
                        Send Verification Code
                </Button>
                </ModalComponents.FormButton>
                <InvisibleSubmit />
            </ModalComponents.Form>

            <WillSendTo onClick={enableSMSMethod}>
            <IconHolder>
                <PhoneIcon width="20" />
            </IconHolder>
            <ResetPasswordValue>We'll send it to •••• •••• {login.slice(-4)}</ResetPasswordValue>
          </WillSendTo>
      
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);


const IconHolder = styled.div`
    margin-right: 20px;
    display: inline-block;
    float: left;
`;

const WillSendTo = styled.div`
    float: left;
    display: block;
    margin: 0px auto;
`;

const ResetPasswordValue = styled.div`
      text-align: left;
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      letter-spacing: 0px;
      color: #000000;
      font-weight: 200;
      line-height: 50px;
      display: inline-block;
      float: left;
`;

const SelectText = styled.div`
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 19px;
      letter-spacing: 0px;
      color: #5E6263;
      font-weight: 100;
      margin: 20px 20px 0px 20px;
      line-height: 160%;
`;

const LastNameTitle = styled.div`
    text-align: center;
    font-size: 33px;
    font-family: 'Montserrat', sans-serif;
    color: #424648;
    font-weight: 300;
    margin-top: 20px;
`;      

ValidateLastName.propTypes = {
  title: PropTypes.string.isRequired,
  login: PropTypes.string,
  values: PropTypes.shape({ lastName: PropTypes.string }).isRequired,
  errors: PropTypes.shape({ lastName: PropTypes.string }).isRequired,
  touched: PropTypes.shape({ lastName: PropTypes.bool }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sendSMScode: PropTypes.func.isRequired,
  sendEmailCode: PropTypes.func.isRequired,
  onEmailOrNumberFieldChange: PropTypes.func.isRequired,
  makedEmail: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  enableSMSMethod: PropTypes.func.isRequired,
};

ValidateLastName.defaultProps = {
  login: undefined,
  makedEmail: '',
};

export default validateLastNameHOC(ValidateLastName);
