import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';
import logoIcon from 'assets/logo-footer-black.svg';

const DesktopMenu = ({
  opened,
  toggleHandler,
  isUserLoggedIn,
  goToLanding,
  showModalPromo,
  showModalOrders,
  showModalAddresses,
  showModalSecurity,
  showModalPayments,
  showModalLogin,
  showModalRegister,
  logOut,
  loyaltyEnabled,
  fixedLoyalty,
}) => (
  <Wrapper active={opened} aria-hidden={!opened}>
    <Container>
      <Opacity onClick={toggleHandler} />
      <FocusLock disabled={!opened}>
        <Inner>
          <Top>
            <CloseIcon onClick={toggleHandler}>
              <Icon name="icon-cross-small" size="32px" />
            </CloseIcon>
          </Top>
          <Items>
            <ItemLink to="." onClick={goToLanding}>
              <IconWrapper>
                <Icon name="icon-locations-small" size="28px" />
              </IconWrapper>
              <Label>Locations</Label>
            </ItemLink>
            {isUserLoggedIn ? (
              <Fragment>
                <Item onClick={showModalOrders}>
                  <IconWrapper>
                    <Icon name="icon-orders-small" size="28px" />
                  </IconWrapper>
                  <Label>Orders</Label>
                </Item>
                <Item onClick={showModalAddresses}>
                  <IconWrapper>
                    <Icon name="icon-address-small" size="28px" />
                  </IconWrapper>
                  <Label>My addresses</Label>
                </Item>
                <Item onClick={showModalPromo}>
                  <IconWrapper>
                    <Icon name="icon-promotions-small" size="28px" />
                  </IconWrapper>
                  <Label>Promotions</Label>
                </Item>
                {loyaltyEnabled && !fixedLoyalty && (
                  <ItemLink to="/myrewards">
                    <IconWrapper>
                      <Icon name="icon-promotions-small" size="28px" />
                    </IconWrapper>
                    <Label>My Rewards</Label>
                  </ItemLink>
                )}
                <Item onClick={showModalPayments}>
                  <IconWrapper>
                    <Icon name="icon-payments-small" size="28px" />
                  </IconWrapper>
                  <Label>Payments</Label>
                </Item>
                <Item onClick={showModalSecurity}>
                  <IconWrapper>
                    <Icon name="icon-sign-in-and-security-small" size="28px" />
                  </IconWrapper>
                  <Label>Sign in & Security</Label>
                </Item>
                <Item onClick={logOut}>
                  <IconWrapper>
                    <Icon name="icon-logout--small" size="28px" />
                  </IconWrapper>
                  <Label>Sign out</Label>
                </Item>
              </Fragment>
            ) : (
              <Fragment>
                <Item onClick={showModalPromo}>
                  <IconWrapper>
                    <Icon name="icon-promotions-small" size="28px" />
                  </IconWrapper>
                  <Label>Promotions</Label>
                </Item>
                <Item onClick={showModalLogin}>
                  <IconWrapper>
                    <Icon name="icon-sign-in-and-security-small" size="28px" />
                  </IconWrapper>
                  <Label>Sign in</Label>
                </Item>
                <Item onClick={showModalRegister}>
                  <IconWrapper>
                    <Icon name="icon-sign-in-and-security-small" size="28px" />
                  </IconWrapper>
                  <Label>Register</Label>
                </Item>
              </Fragment>
            )}
          </Items>
          <Bottom aria-hidden>
            <Powered>POWERED BY</Powered>
            <Logo />
          </Bottom>
        </Inner>
      </FocusLock>
    </Container>
  </Wrapper>
);

DesktopMenu.propTypes = {
  opened: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  goToLanding: PropTypes.func.isRequired,
  toggleHandler: PropTypes.func.isRequired,
  showModalPromo: PropTypes.func.isRequired,
  showModalOrders: PropTypes.func.isRequired,
  showModalAddresses: PropTypes.func.isRequired,
  showModalSecurity: PropTypes.func.isRequired,
  showModalPayments: PropTypes.func.isRequired,
  showModalLogin: PropTypes.func.isRequired,
  showModalRegister: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  loyaltyEnabled: PropTypes.bool.isRequired,
  fixedLoyalty: PropTypes.number,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: ${({ active }) => (active ? '1' : '0')};
  pointer-events: ${({ active }) => (active ? 'initial' : 'none')};
  transition: opacity 0.2s ease;
  will-change: opacity;
`;

const Opacity = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const Container = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
  z-index: 10;
`;

const Top = styled.div`
  padding: 28.5px 15px;
`;

const CloseIcon = styled.button`
  width: 20px;
  height: 20px;
  color: #545859;
  text-align: left;
  cursor: pointer;
`;

const Items = styled.div`
  flex: 1 1 auto;
`;

const Item = styled.button`
  padding: 25px 30px;
  border-top: 1px solid #ededed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease;

  &:last-child {
    border-bottom: 1px solid #ededed;
  }

  &:hover {
    background: #f7f8f8;
  }
`;

const ItemLink = styled(Link)`
  padding: 25px 30px;
  border-top: 1px solid #ededed;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease;
  text-decoration: none;

  &:last-child {
    border-bottom: 1px solid #ededed;
  }

  &:hover {
    background: #f7f8f8;
  }
`;

const Label = styled.div`
  margin-left: 17px;
  font-size: 16px;
  font-family: 'Roboto';
  color: rgb(66, 70, 72);
  font-weight: 300;
`;

const Logo = styled.div`
width: 70px;
height: 25px;
background: url('${logoIcon}') no-repeat center / contain;
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 12px;
  color: #545859;
`;

const Inner = styled.div`
  background: #fff;
  width: 255px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 20;
`;

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 35px;
`;

const Powered = styled.div`
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  margin-right: 15px;
`;

export default DesktopMenu;
