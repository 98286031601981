const HOSTNAME = window.location.hostname;

const isLocalhost = Boolean(
  HOSTNAME === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    HOSTNAME === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    HOSTNAME.match(
      /^.*127(?:[.-](?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}.*$/,
    ) ||
    HOSTNAME.includes('lvh.me'),
);

const isDevEnvironment = Boolean(HOSTNAME.startsWith('dev-'));

const { CI_ENVIRONMENT_NAME = 'dev' } = process.env;

export const SUBDOMAIN = {
  [true]: HOSTNAME.split('.', 1)[0],
  // [isLocalhost]: 'dev-location1',
  // [HOSTNAME.startsWith('everbloom')]: 'dev-location1',
  // [isLocalhost && CI_ENVIRONMENT_NAME === 'dev']: 'dev-location1',
}.true;

export const MOLTENCORE_HOST = {
  [true]: 'moltencore.foodieordersecure.com',
  [isDevEnvironment]: 'dev-moltencore.foodieordersecure.com',
  [isLocalhost]: 'moltencore.127-0-0-1.sslip.io:8012',
}.true;

console.log('SUBDOMAIN: ', SUBDOMAIN);

export default HOSTNAME;
