import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';
import Icon from 'components/Icon';

import AsyncLocationSelectInput from 'components/AsyncLocationSelectInput';
import PopUp from 'components/PopUp';
import Footer from 'components/Footer';
import ScrollableContent from 'components/ScrollableContent';
import Header from './Header';
import LocationCard from './LocationCard';

const Locations = ({
  logo,
  locations,
  showAddressInput,
  addressInputVisible,
  handlePredictionClick,
  handlePopUpOutsideClick,
  userLocation,
  restaurantName,
  offline,
  phone,
}) => (
  <ScrollableContent>
    <Wrapper>
      <HeaderWrapper>
        <Header logoUrl={logo} restaurantName={restaurantName} />
      </HeaderWrapper>

      {offline !== true ? (
        <Content>
          <Heading onClick={showAddressInput}>
            <Title>Choose your location</Title>
            <Sort active>Sort by distance</Sort>
          </Heading>

          {addressInputVisible && (
            <PopUp onOutsideClick={handlePopUpOutsideClick} />
          )}
          {(userLocation || addressInputVisible) && (
            <InputWrapper>
              <AsyncLocationSelectInput
                initialLocation={userLocation}
                placeholder="Enter your zipcode"
                onPredictionClick={handlePredictionClick}
                // onGeolocationCompleted={() => {}}
                locationsPage
              />
            </InputWrapper>
          )}

          <CardsWrapper>
            {locations.map(location => (
              <LocationCardWrapper key={location.url}>
                <LocationCard
                  name={location.name}
                  address={location.address}
                  distance={location.distance}
                  url={location.url}
                />
              </LocationCardWrapper>
            ))}
          </CardsWrapper>
        </Content>
      ) : (
        <Content>
          <WarningBox>
            <IconContainer>
              <Icon name="icon-menu-availability-small" size="28px" />
            </IconContainer>
            <WarningText>
              Sorry, we are not taking online orders right now.
              {phone && (
                <>
                  <PleaseCall>To order please call</PleaseCall>
                  <PhoneNumber>{phone}</PhoneNumber>
                </>
              )}
            </WarningText>
          </WarningBox>
        </Content>
      )}
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Wrapper>
  </ScrollableContent>
);

Locations.propTypes = {
  logo: PropTypes.string.isRequired,
  restaurantName: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  userLocation: PropTypes.string.isRequired,
  addressInputVisible: PropTypes.bool.isRequired,
  showAddressInput: PropTypes.func.isRequired,
  handlePredictionClick: PropTypes.func.isRequired,
  handlePopUpOutsideClick: PropTypes.func.isRequired,
  offline: PropTypes.bool,
  phone: PropTypes.string,
};

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
`;

const Content = styled.div`
  flex: 1 1 auto;
  padding: 32px 20px 32px 20px;
  max-width: 440px;
  width: 100%;
  align-self: center;
`;

const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: rgb(66, 70, 72);

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Sort = styled.div`
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: ${({ active }) => (active ? 'rgb(84, 163, 0)' : 'rgb(116, 121, 122)')};
  font-weight: bold;
  text-transform: uppercase;

  ${media.desktop`
    cursor: pointer;
  `};
`;

const Heading = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  ${media.tablet`
    margin-top: 37px;
  `};

  ${media.desktop`
    margin-top: 37px;
  `};
`;

const HeaderWrapper = styled.div`
  box-shadow: 1px 1px 1px 1px #e6e7e8;
`;

const FooterWrapper = styled.div``;

const InputWrapper = styled.div`
  margin-top: 15px;

  :focus-within {
    border: 1px solid #54a300;
  }

  ${media.tablet`
    margin-top: 22px;
  `};

  ${media.desktop`
    margin-top: 22px;
  `};
`;

const CardsWrapper = styled.div`
  margin-top: 25px;

  ${media.tablet`
    margin-top: 35px;
  `};

  ${media.desktop`
    margin-top: 35px;
  `};
`;

const LocationCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }

  ${media.desktop`
    cursor: pointer;
  `};
`;

const WarningBox = styled.div`
  display: flex;
  font: 18px 'Montserrat', sans-serif;
  color: #424648;
  font-weight: 300;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  align-items: flex-start;
`;

const IconContainer = styled.div`
  padding: 10px 20px 8px 8px;
  color: gray;
`;

const WarningText = styled.div``;

const PleaseCall = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: rgb(66, 70, 72);
  padding: 10px 28px 5px 0px;
  text-align: center;
`;

const PhoneNumber = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: rgb(66, 70, 72);
  padding-right: 18px;
  text-align: center;
`;

export default Locations;
