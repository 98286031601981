import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { commaSplit } from 'utils/commaSplit';
import { formatCents } from 'utils/formatCents';
import IconClean from 'components/IconClean';
import { redeemPointsHOC } from 'routes/Checkout/redeemPointsHOC';
import Button from 'components/Button';

const RedeemPointsStyled = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-bottom: 15px;
  align-items: stretch;
`;

const ProgressBox = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 15px;
`;

const Info = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  flex: 1;
  margin: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Points = styled.div``;

const Currency = styled.div`
  margin-top: 12px;
  font-size: 30px;
`;

const MinOrder = styled.div`
  margin-top: 12px;
  font-size: 15px;
  font-weight: 400;
  color: #c93d32;
`;

const ButtonContainer = styled.button`
  width: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  min-width: 120px;
`;

const RedeemButton = styled.div`
  background-color: #54a300;
  color: white;
  padding: 0 24px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const RedeemCancelButton = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 11px;
  flex: 1;

  ${IconClean} {
    margin: -12px -28px;
    color: #54a300;
  }
`;

const Container = styled.div`
  padding: 16px;
  z-index: 11002;
  background-color: #ffffff;
  position: fixed !important;
  margin-top: -100px;
  border-radius: 3px;
  width: 350px;
  max-width: 90%;
  top: 50%;
  left: 50%;
  margin-left: -175px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11001;
  content: '';
  background: rgba(0, 0, 0, 0.5);
  cursor: default;
`;

const ButtonWrapper = styled.div`
  margin-top: 15px;
  cursor: pointer;
`;

const RewardCopy = styled.div`
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  display: block;
  clear: both;
  font-family: 'Montserrat';
  font-weight: 300;
  color: #424648;

  p {
    color: #424648;
    font-size: 16px;
    display: block;
    margin: 15px 0px 32px 0px;
    line-height: 24px;
    font-family: 'Roboto';
  }
  strong {
    font-weight: 500;
  }
`;
const RewardCloseWrapper = styled.div`
  display: block;
  text-align: right;
  width: 100%;
`;

const ConsumerCurrentPoints = styled.div`
  color: #54a300;
  font-family: 'Roboto';
  font-size: 30px;
  line-height: 39px;
  font-weight: normal;
  text-align: center;
`;

const ConsumerCurrentPointsProgress = styled.div`
  background: #d9d9d9;
  display: block;
  height: 11px;
  border-radius: 5px;
  overflow: hidden;
  margin: 15px;
  &::after {
    display: block;
    width: ${props => props.progress}%;
    background: #54a300;
    content: ' ';
    height: 11px;
  }
`;

const PointsDetails = styled.div`
  font-size: 12px;
  color: #000000;
  margin: 10px 0px;
  text-align: center;
`;

const RemainingPoints = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: bold;
`;

const RedeemPointsComponent = ({
  loyalty: {
    consumer: { points },
  },
  maxApplicablePoints,
  redeemCurrency,
  toggleRedeemCurrency,
  loyaltyPopupHidden,
  hideLoyaltyPopup,
  remainingPoints,
  minOrderForReward,
  minOrderReached,
  consumerPoints,
  poinstToReach,
  earnPointsRate,
  fixedLoyalty,
}) => (
  <>
    {maxApplicablePoints > 0 && (
      <RedeemPointsStyled>
        <Info tabIndex={0}>
          <Points>Available Reward</Points>
          <Currency>{formatCents(maxApplicablePoints)}</Currency>
          {!minOrderReached && (
            <MinOrder>Min. Order: {formatCents(minOrderForReward)}</MinOrder>
          )}
        </Info>
        <ButtonContainer
          onClick={function() {
            toggleRedeemCurrency();
            hideLoyaltyPopup();
          }}
          aria-label="APPLY REWARD"
        >
          {redeemCurrency ? (
            <RedeemCancelButton>
              <div>
                <IconClean name="icon-thank-you-small" size="96px" />
              </div>
              <div>REMOVE</div>
            </RedeemCancelButton>
          ) : (
            <RedeemButton>REDEEM</RedeemButton>
          )}
        </ButtonContainer>

        {!loyaltyPopupHidden &&
          !redeemCurrency &&
          maxApplicablePoints > 0 &&
          minOrderReached && (
            <>
              <Container>
                <RewardCloseWrapper>
                  <IconClean
                    onClick={hideLoyaltyPopup}
                    name="icon-cross-thin-small"
                    size="30px"
                  />
                </RewardCloseWrapper>
                <RewardCopy>
                  You have a Reward!
                  <p>
                    A discount of{' '}
                    <strong>{formatCents(maxApplicablePoints)}</strong> can be{' '}
                    <br /> applied to your order
                  </p>
                </RewardCopy>
                <ButtonWrapper>
                  <Button
                    onClick={function() {
                      toggleRedeemCurrency();
                      hideLoyaltyPopup();
                    }}
                  >
                    Apply to order
                  </Button>
                </ButtonWrapper>
              </Container>
              <Overlay onClick={hideLoyaltyPopup} />
            </>
          )}
      </RedeemPointsStyled>
    )}
    {remainingPoints > 0 && (
      <ProgressBox>
        <ConsumerCurrentPoints>
          {parseInt(consumerPoints)}/{poinstToReach} Points
        </ConsumerCurrentPoints>
        <ConsumerCurrentPointsProgress
          progress={(parseInt(consumerPoints) / poinstToReach) * 100}
        />
        <RemainingPoints>
          {' '}
          {poinstToReach - parseInt(consumerPoints)} Points until your next $
          {fixedLoyalty} reward!
        </RemainingPoints>
        <PointsDetails>Earn 1 point every $1 you spend</PointsDetails>
      </ProgressBox>
    )}
  </>
);

RedeemPointsComponent.propTypes = {
  loyalty: PropTypes.shape({
    points: PropTypes.number,
    pointsInCurrency: PropTypes.number,
  }).isRequired,
  redeemCurrency: PropTypes.number.isRequired,
  maxApplicablePoints: PropTypes.number.isRequired,
  toggleRedeemCurrency: PropTypes.func.isRequired,
  loyaltyPopupHidden: PropTypes.bool,
  hideLoyaltyPopup: PropTypes.func.isRequired,
  remainingPoints: PropTypes.number.isRequired,
  consumerPoints: PropTypes.number.isRequired,
  poinstToReach: PropTypes.number.isRequired,
  earnPointsRate: PropTypes.number.isRequired,
  fixedLoyalty: PropTypes.number.isRequired,
};

export const RedeemPoints = redeemPointsHOC(RedeemPointsComponent);
