import { createSelector } from 'reselect';
import pick from 'lodash/pick';

export const generalSelector = state => state.general;

export const applicationTypeSelector = createSelector(
  generalSelector,
  general => general.application,
);

export const restaurantInfoSelector = createSelector(
  generalSelector,
  general => general.restaurantInfo,
);

export const loyaltyEnabledSelector = createSelector(
  generalSelector,
  general => general.restaurantInfo.loyaltyEnabled,
);

export const hideLoyaltyQrcodeSelector = createSelector(
  restaurantInfoSelector,
  restaurantInfo => restaurantInfo.hideLoyaltyQrcode,
);

export const pickupEnabledSelector = createSelector(
  generalSelector,
  general => general.orderingSettings.pickupEnabled,
);

export const globalMinimumQuantitySelector = createSelector(
  generalSelector,
  general => general.restaurantInfo.globalMinimumQuantity,
);

export const landingUrlSelector = createSelector(
  restaurantInfoSelector,
  restaurantInfo => restaurantInfo.landingUrl,
);

export const deliveryInfoSelector = createSelector(
  generalSelector,
  general => general.deliveryInfo,
);

export const canPickupSelector = createSelector(
  pickupEnabledSelector,
  pickupEnabled => pickupEnabled,
);

export const canDeliverySelector = createSelector(
  deliveryInfoSelector,
  deliveryInfo => deliveryInfo.deliveryEnabled,
);

export const orderAheadInfoSelector = createSelector(
  generalSelector,
  ({ orderAheadInfo }) => orderAheadInfo,
);

export const cantinaInfoSelector = createSelector(
  generalSelector,
  ({ cantinaInfo }) => cantinaInfo,
);

export const orderingSettingsSelector = createSelector(
  generalSelector,
  ({ orderingSettings }) => orderingSettings,
);

export const paymentMethodsSelector = createSelector(
  generalSelector,
  ({ paymentMethods }) => paymentMethods,
);

export const restaurantAddressSelector = createSelector(
  generalSelector,
  general =>
    pick(general.restaurantInfo, [
      'name',
      'address',
      'city',
      'state',
      'zipcode',
    ]),
);

export const taxPercentageSelector = createSelector(
  generalSelector,
  ({ tax }) => tax,
);

export const activePromotionsSelector = createSelector(
  generalSelector,
  ({ activePromotions }) => activePromotions,
);

export const serviceFeeSelector = createSelector(
  generalSelector,
  ({ serviceFee }) => serviceFee,
);

export const temporaryClosuresSelector = createSelector(
  generalSelector,
  ({ temporaryClosures }) => temporaryClosures,
);

export const externalDeliverySelector = createSelector(
  generalSelector,
  ({ externalDelivery }) => externalDelivery,
);

export const hideSpecialInstructionsSelector = createSelector(
  generalSelector,
  general => general.restaurantInfo.hideSpecialInstructions,
);

export const hidePickupBoxSelector = createSelector(
  generalSelector,
  general => general.restaurantInfo.hidePickupbox,
);

export const checkoutOptionsSelector = createSelector(
  generalSelector,
  ({ checkoutOptions }) => checkoutOptions,
);

export const ageVerificationSelector = createSelector(
  generalSelector,
  ({ ageVerification }) => ageVerification,
);
