import { findDOMNode } from 'react-dom';
import { withRouter } from 'react-router-dom';
import {
  compose,
  withProps,
  withHandlers,
  withStateHandlers,
  lifecycle,
  branch,
} from 'recompose';
import { connect } from 'react-redux';

import keys from 'lodash/keys';

import { selectedPaymentSelector } from 'redux/modules/payments';
import {
  promocodeSelector,
  cartItemsSelector,
  backendCartProductsSelector,
  tipSelector,
  specialInstructionsSelector,
  paymentMethodSelector,
  autoPromocodeSelector,
  redeemCurrencySelector,
  promoPopupHiddenSelector,
  nonceSelector,
  choiseSelector,
  actions as cartActions,
  sessionIdSelector,
} from 'redux/modules/cart';
import withPromocodeValidation from 'HOC/withPromocodeValidation';
import withPromocodeRevalidator from 'HOC/withPromocodeRevalidator';
import { actions as orderActions } from 'redux/modules/orders';
import { actions as modalActions } from 'components/ReduxModal';
import placeIdSelector from 'selectors/placeIdSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import {
  actions as deliveryActions,
  deliverySelector,
  orderInitiatedSelector,
} from 'redux/modules/delivery';
import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { selectedUserAddressSelector } from 'selectors/selectedUserAddressSelector';
import {
  scheduleTimeSelector,
  backendScheduleTimeSelector,
} from 'selectors/scheduleTimeSelector';
import destinationAddressSelector from 'selectors/destinationAddressSelector';
import tipsAllowedSelector from 'selectors/tipsAllowedSelector';
import tipAmountSelector from 'selectors/tipAmountSelector';
import tipTypeSelector from 'selectors/tipTypeSelector';
import paymentValidSelector from 'selectors/paymentValidSelector';
import totalPriceSelector from 'selectors/totalPriceSelector';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';
import Checkout from './Checkout';
import inform from 'utils/inform';
import promocodeDiscountSelector from 'selectors/promocodeDiscountSelector';
import {
  hideSpecialInstructionsSelector,
  hidePickupBoxSelector,
  checkoutOptionsSelector,
} from 'redux/modules/general';

import serviceFeeTotalSelector from 'selectors/serviceFeeTotalSelector';
import extraServiceFeeSelector from 'selectors/extraServiceFeeSelector';

import checkoutOptionsAllowedSelector from 'selectors/checkoutOptionsAllowedSelector';

export const CheckoutHOC = compose(
  withRouter,
  connect(
    state => ({
      type: getDeliveryType(state),
      promocode: promocodeSelector(state),
      dishes: keys(cartItemsSelector(state)),
      cartSubtotal: cartSubtotalSelector(state),
      selectedUserAddress: selectedUserAddressSelector(state),
      scheduleTime: scheduleTimeSelector(state),
      backendScheduleTime: backendScheduleTimeSelector(state),
      deliveryTimeType: deliveryTimeTypeSelector(state),
      backendCartProducts: backendCartProductsSelector(state),
      specialInstructions: specialInstructionsSelector(state),
      tipsAllowed: tipsAllowedSelector(state),
      tipType: tipTypeSelector(state),
      tipAmount: tipAmountSelector(state),
      tipValue: tipSelector(state),
      paymentMethod: paymentMethodSelector(state),
      selectedPayment: selectedPaymentSelector(state),
      destinationAddress: destinationAddressSelector(state),
      paymentValid: paymentValidSelector(state),
      price: totalPriceSelector(state),
      placeId: placeIdSelector(state),
      autoPromocode: autoPromocodeSelector(state),
      redeemCurrency: redeemCurrencySelector(state),
      dineInEnabled: dineInEnabledSelector(state),
      dineInTable: deliverySelector(state)?.dineInTable,
      loyaltyPopupHidden: false,
      nonce: nonceSelector(state),
      promoPopupHidden: promoPopupHiddenSelector(state),
      discountPrice: promocodeDiscountSelector(state),
      hideSpecialInstructions: hideSpecialInstructionsSelector(state),
      orderInitiatedAt: orderInitiatedSelector(state),
      serviceFeeTotal: serviceFeeTotalSelector(state),
      extraServiceFee: extraServiceFeeSelector(state),
      hidePickupBox: hidePickupBoxSelector(state),
      checkoutOptions: checkoutOptionsSelector(state),
      checkoutOptionsAllowed: checkoutOptionsAllowedSelector(state),
      choiseValue: choiseSelector(state),
      deliveryType: getDeliveryType(state),
      sessionId: sessionIdSelector(state),
    }),
    {
      showModal: modalActions.showModal,
      closeModal: modalActions.closeModal,
      createOrder: orderActions.createOrder,
      setSpecialInstructions: cartActions.setSpecialInstructions,
      setDineInTable: deliveryActions.setDineInTable,
      removePromocode: cartActions.removePromocode,
      setPromoPopupHidden: cartActions.setPromoPopupHidden,
      checkOrderAge: orderActions.checkOrderAge,
      reviewOrder: orderActions.reviewOrder,
    },
  ),
  withStateHandlers(
    {
      placeOrderButtonTouched: false,
    },
    {
      touchPlaceOrderButton: () => () => ({ placeOrderButtonTouched: true }),
    },
  ),
  withHandlers({
    setDineInTable: ({ setDineInTable }) => e => {
      setDineInTable(e.target.value);
    },
  }),
  withProps(
    ({
      placeOrderButtonTouched,
      paymentValid,
      cartSubtotal,
      dineInEnabled,
      dineInTable,
      choiseValue,
      checkoutOptionsAllowed,
    }) => ({
      orderValid: Boolean(
        paymentValid &&
          cartSubtotal &&
          (!dineInEnabled || dineInTable) &&
          (!checkoutOptionsAllowed ||
            (choiseValue !== undefined && choiseValue !== null)),
      ),
      tableNumberInvalid: Boolean(
        dineInEnabled && placeOrderButtonTouched && !dineInTable,
      ),
      paymentInvalid: Boolean(placeOrderButtonTouched && !paymentValid),
      checkoutOptionInvalid: Boolean(
        placeOrderButtonTouched &&
          checkoutOptionsAllowed &&
          (choiseValue === undefined || choiseValue === null),
      ),
    }),
  ),
  withHandlers(() => {
    let scrollableNode = null;
    let paymentsNode = null;
    let tableNumberInputNode = null;
    let choisesNode = null;
    return {
      onScrollableNodeRef: () => ref => {
        scrollableNode = ref;
      },
      onPaymentsNodeRef: () => ref => {
        paymentsNode = ref;
      },
      onChoisesNodeRef: () => ref => {
        choisesNode = ref;
      },
      onTableNumberInputNodeRef: () => ref => {
        tableNumberInputNode = ref;
      },
      scrollToInvalidCard: ({
        tableNumberInvalid,
        paymentInvalid,
        checkoutOptionInvalid,
      }) => () => {
        if (tableNumberInvalid || paymentInvalid || checkoutOptionInvalid) {
          // eslint-disable-next-line react/no-find-dom-node
          const invalidDOMNode = findDOMNode(
            (tableNumberInvalid && tableNumberInputNode) ||
              (paymentInvalid && paymentsNode) ||
              (checkoutOptionInvalid && choisesNode),
          );
          // eslint-disable-next-line react/no-find-dom-node
          const container = findDOMNode(scrollableNode);
          const offset = invalidDOMNode ? invalidDOMNode.offsetTop : 0;
          if (container) container.scrollTop = offset - 200;
        }
      },
    };
  }),
  withHandlers({
    showModalPromocode: ({ showModal, redeemCurrency }) => () => {
      if (!redeemCurrency) showModal('promocode');
      else
        inform({
          title: 'Oops...',
          message:
            'Discount codes and Rewards cannot be combined. Only one discount at a time can apply to a single order.',
          buttonText: 'OK',
        });
    },
    hidePromoApplied: ({ setPromoPopupHidden }) => () => {
      setPromoPopupHidden();
    },
    onSpecialInstructionsChange: ({ setSpecialInstructions }) => value => {
      setSpecialInstructions(value);
    },
    onPlaceOrderClick: ({
      cartSubtotal,
      type,
      deliveryTimeType,
      selectedUserAddress,
      scheduleTime,
      backendScheduleTime,
      backendCartProducts,
      promocode,
      specialInstructions,
      tipsAllowed,
      tipType,
      tipAmount,
      tipValue,
      paymentMethod,
      selectedPayment,
      destinationAddress,
      createOrder,
      orderValid,
      touchPlaceOrderButton,
      scrollToInvalidCard,
      placeId,
      redeemCurrency,
      dineInTable,
      nonce,
      orderInitiatedAt,
      finishOrder,
      checkOrderAge,
      serviceFeeTotal,
      reviewOrder,
      extraServiceFee,
      checkoutOptionsAllowed,
      choiseValue,
      sessionId,
    }) => () => {
      touchPlaceOrderButton();
      setTimeout(() => {
        if (orderValid) {
          const address = selectedUserAddress && {
            place: selectedUserAddress.address,
            addressDetails: selectedUserAddress.aptSuiteFloor,
            notes: selectedUserAddress.specialInstructions,
            businessName: selectedUserAddress.businessName,
            placeId,
            ...(selectedUserAddress.name && { name: selectedUserAddress.name }),
          };
          const method = {
            type: type.toLowerCase(),
            deliveryType: deliveryTimeType.toLowerCase(),
            ...(address && { address }),
            // HACK: timezone is incorrect. But backend requires it this way.
            date: backendScheduleTime,
          };
          const body = {
            nonce,
            cart: {
              products: backendCartProducts,
            },
            serviceFee: serviceFeeTotal,
            eSF: extraServiceFee,
            checkoutOption: choiseValue,
            method,
            ...(tipAmount &&
              tipsAllowed && {
                tip: {
                  type: tipType,
                  value:
                    tipType === 'amount'
                      ? tipAmount
                      : Math.round(tipValue * 10000),
                },
              }),
            ...(promocode && { coupons: [promocode] }),
            payment: {
              [paymentMethod === 'CASH']: 'cash',
              [paymentMethod === 'CARD']: 'card',
            }.true,
            ...(paymentMethod === 'CARD' && { selectedCc: selectedPayment.id }),
            cartTotal: cartSubtotal,
            ...(redeemCurrency && { redeemCurrency: redeemCurrency / 100 }),
            specialInstructions,
            dineInTable,
            cartId: sessionId,
          };
          const myMethod = {
            type,
            deliveryTimeType,
            destinationAddress,
            scheduleTime,
          };

          createOrder(body, myMethod);
        } else {
          scrollToInvalidCard();
        }
      }, 0);
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        show,
        match: { url },
        history,
        dishes,
        reviewOrder,
      } = this.props;
      if ((url.includes('/checkout') || show) && dishes.length === 0) {
        history.replace('/menu');
      }
    },
  }),
  branch(
    ({ show, match: { url }, autoPromocode }) =>
      (url.includes('/checkout') || show) && autoPromocode,
    compose(
      withPromocodeValidation(),
      lifecycle({
        componentDidMount() {
          const { autoPromocode, validate, loyaltyPopupHidden } = this.props;
          this.timeout = setTimeout(() => validate(autoPromocode), 250);
        },
        componentWillUnmount() {
          // HACK: prevents requests duplication due to component remounting for an unknown reason
          clearTimeout(this.timeout);
        },
      }),
    ),
  ),
  withPromocodeRevalidator,
);

export default CheckoutHOC(Checkout);
