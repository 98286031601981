import { normalize } from 'normalizr';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { camelizeKeys } from 'humps';

import wretches from 'wretches';
import { actions as spinnerActions } from 'redux/modules/spinner/reducer';
import schema from './schema';

export const actionTypes = {
  GET_GENERAL_SUCCESS: 'GET_GENERAL_SUCCESS',
};

const initialState = {
  restaurantInfo: {
    hide_loyalty_qrcode: false,
  },
  deliveryInfo: { deliveryEnabled: true },
  orderingSettings: {
    quickTipPercent: [],
    fixedLoyalty: 1,
  },
  checkoutOptions: {
    appliesTo: '',
    options: [],
    defaultOption: 0,
    title: '',
  },
  paymentMethods: { cashEnabled: false, creditCardEnabled: false },
  orderAheadInfo: {},
  asapInfo: {},
  serviceFee: {},
  currency: {
    usd: {
      symbol: '$',
    },
  },
  cantinaInfo: {
    enabled: false,
    dateRangeInDays: 7,
    label: '',
  },
  application: undefined,
  name: undefined,
  logo: undefined,
  loyaltyEnabled: false,
  locations: {},
  pickupEnabled: true,
  globalMinimumQuantity: 1,
  phone: '',
  offline: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_GENERAL_SUCCESS:
      return {
        ...state,
        ...pickBy(
          pick(action.payload.result, [
            'restaurantInfo',
            'deliveryInfo',
            'orderingSettings',
            'paymentMethods',
            'orderAheadInfo',
            'cantinaInfo',
            'asapInfo',
            'tax',
            'serviceFee',
            'currency',
            'application',
            'name',
            'logo',
            'loyaltyEnabled',
            'locations',
            'tax',
            'temporaryClosures',
            'externalDelivery',
            'activePromotions',
            'checkoutOptions',
            'phone',
            'offline',
            'ageVerification',
          ]),
        ),
        pickupEnabled: action.payload.result.pickupEnabled,
        globalMinimumQuantity: action.payload.result.globalMinimumQuantity,
      };
    default:
      return state;
  }
}

const getGeneral = () => dispatch => {
  // GOD endpoint. Consider refactoring
  dispatch(spinnerActions.showSpinner());
  wretches.general
    .get()
    .json(data => {
      dispatch(spinnerActions.hideSpinner());
      const camelcased = camelizeKeys(data, (key, convert) =>
        /^[:0-9-]+$/.test(key) ? key : convert(key),
      );
      const normalized = normalize(camelcased, schema);
      if (process.env.NODE_ENV === 'development') {
        // modify here
      }
      dispatch({ type: actionTypes.GET_GENERAL_SUCCESS, payload: normalized });
      const title =
        camelcased.application === 'landing'
          ? camelcased.name
          : camelcased.restaurantInfo.name;
      if (title) {
        document.title = `${title} - Order Online`;
      }
    })
    .catch(() => {
      dispatch(spinnerActions.hideSpinner());
      const normalized = normalize(
        { application: 'landing', offline: true },
        schema,
      );
      dispatch({ type: actionTypes.GET_GENERAL_SUCCESS, payload: normalized });
    });
  // dispatch({ type: 'ERR', error })
};

export const actions = {
  getGeneral,
};
