import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';
import addMinutes from 'date-fns/add_minutes';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import parse from 'date-fns/parse';

import { ordersSelector } from 'redux/modules/orders';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { showIntervalsSelector } from 'selectors/showIntervalsSelector';
import { cantinaWeekSelector } from 'selectors/cantinaWeekSelector';
import { dineInEnabledSelector } from 'selectors/dineInEnabledSelector';

import dataFetcher from 'HOC/dataFetcher';
import { actions as ordersActions } from 'redux/modules/orders/reducer';
import OrderDetails from '../components/OrderDetails';
import lineItemsToCart from 'utils/lineItemsToCart';

import { restaurantInfoSelector } from 'redux/modules/general';
import { firstNameSelector } from 'redux/modules/user';

const TIME_FORMAT = 'hh:mma';

const OrderDetailsHOC = compose(
  connect(
    state => ({
      orders: ordersSelector(state),
      cantinaWeek: cantinaWeekSelector(state),
      dineInEnabled: dineInEnabledSelector(state),
      restaurantInfo: restaurantInfoSelector(state),
      firstName: firstNameSelector(state),
    }),
    {
      getOrderInvoice: ordersActions.getOrderInvoice,
    },
  ),

  withProps(
    ({
      cantinaWeek,
      orders,
      restaurantInfo,
      firstName,
      getOrderInvoice,
      match: {
        params: { orderId },
      },
    }) => {
      const orderInfo = get(orders, orderId);
      const orderDate = get(orderInfo, ['data', 'orderDate']);
      const deliveryDate = orderDate && parse(orderDate).toISOString();
      const deliveryType = get(orderInfo, ['data', 'method'], '').toUpperCase();
      const deliveryDay = cantinaWeek
        ? cantinaWeek?.start
        : deliveryDate && format(deliveryDate, 'MMMM DD, YYYY');

      if (typeof orderInfo !== 'undefined') {
        const address1 = orderInfo.data.userAddress;

        const address = {
          address: address1.place,
        };

        const totals = orderInfo.data.totals;

        let discounts = 0;

        let total = 0;

        if (totals) {
          Object.values(totals).map(totalItem => {
            if (totalItem.isRefund) discounts += totalItem.total * -1;
          });
          total = parseFloat(orderInfo.data.total);
        } else {
          total = 0;
          getOrderInvoice(orderId);
        }

        if (orderInfo.data.status == 'cancelled') {
          discounts = total;
        }

        const status = orderInfo.data.status;

        const paidwith =
          orderInfo.data.paymentType == 'card' ? 'Credit Card' : 'Cash';

        const lineI = lineItemsToCart(orderInfo.data.lineItems);
        const SpecialInstructions = orderInfo.data.specialInstructions;

        return {
          orderId,
          deliveryDay,
          deliveryDate,
          deliveryType,
          address,
          lineI,
          total,
          totals,
          paidwith,
          restaurantInfo,
          firstName,
          SpecialInstructions,
          discounts,
          status,
        };
      }

      getOrderInvoice(orderId);
      return {};
    },
  ),
  connect((state, { deliveryType }) => ({
    orders: ordersSelector(state),
    minutesStepSize: timeIntervalSelector(state, { deliveryType }),
    showIntervals: showIntervalsSelector(state, { deliveryType }),
  })),
  withProps(
    ({ cantinaWeek, deliveryDate, showIntervals, minutesStepSize }) => ({
      deliveryTime: cantinaWeek
        ? `- ${cantinaWeek?.stop}`
        : deliveryDate &&
          (showIntervals
            ? `${format(deliveryDate, TIME_FORMAT)} - ${format(
                addMinutes(deliveryDate, minutesStepSize),
                TIME_FORMAT,
              )}`
            : format(deliveryDate, 'hh:mm A')),
    }),
  ),
  // withProps(() => ({
  //   orderId: '2142',
  //   deliveryDay: 'Today',
  //   deliveryTime: '01:06 PM',
  //   deliveryType: 'PICKUP',
  //   address: {
  //     name: 'Home',
  //     address: '8133 Northwest 139th Terrace',
  //     city: 'Hialeah',
  //     state: 'FL',
  //     zipcode: '33016',
  //     fullAddress: '540 W 29th St, Hialeah, 33012',
  //   },
  // })),
);

export default OrderDetailsHOC(OrderDetails);
