import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';
import RadioButton from 'components/RadioButton';
import CardIcon from 'routes/Checkout/components/PaymentMethod/components/CardIcon';
import { media } from 'utils/mediaQueries';

class Payment extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const {
      def,
      last4,
      type,
      deletePayment,
      makeDefaultPayment,
      selected,
      selectPayment,
      expanded,
      expand,
      selectorTouched,
      paymentsSelectable,
      name,
      expandedMode,
      expandRemove,
    } = this.props;

    return (
      <CardHolder
        withRadio={paymentsSelectable}
        expanded={
          (paymentsSelectable && selected) ||
          (selectorTouched && expanded && expandedMode)
        }
      >
        {paymentsSelectable && (
          <RadioButtonWrapper onClick={expand}>
            <RadioButton name="" active={selected} onClick={expand} />
          </RadioButtonWrapper>
        )}
        <EditIconWrapper>
          <EditIcon onClick={expandRemove} aria-label="Remove">
            <Icon name="icon-cross-small" size="26px" />
          </EditIcon>
        </EditIconWrapper>
        <CardName onClick={expand}>
          <span>Name on card</span>
          {name}
        </CardName>
        <Wrapper>
          <CardIconWrapper onClick={expand}>
            <Inner>
              <CardIcon brand={type} />
              <InputWrapper>
                <HiddenNumber>····</HiddenNumber>
                <Number>{last4}</Number>
              </InputWrapper>
            </Inner>
          </CardIconWrapper>
          <LabelWrapper onClick={def ? undefined : makeDefaultPayment}>
            <Label def={def}>
              {def ? 'Default' : 'Set as default payment'}
            </Label>
          </LabelWrapper>
        </Wrapper>
        {paymentsSelectable && selected && (
          <Button onClick={selectPayment}>
            <ButtonLabel>USE THIS PAYMENT METHOD</ButtonLabel>
          </Button>
        )}
        {selectorTouched && expanded && expandedMode && (
          <ButtonRemove onClick={deletePayment}>
            <ButtonLabel>REMOVE CARD</ButtonLabel>
          </ButtonRemove>
        )}
      </CardHolder>
    );
  }
}

Payment.propTypes = {
  def: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  last4: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  deletePayment: PropTypes.func.isRequired,
  makeDefaultPayment: PropTypes.func.isRequired,
  selectPayment: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
  selectorTouched: PropTypes.bool.isRequired,
  paymentsSelectable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  expandedMode: PropTypes.bool.isRequired,
};

Payment.defaultProps = {
  name: '',
};

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  cursor: pointer;
`;

const CardIconWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  padding: 0 5px;
`;

const HiddenNumber = styled.span`
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  max-width: 55px;
  outline: none;
  border: none;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  display: none;

  ${media[350]`
   display: inherit; 
  `};
`;

const Number = styled.span`
  max-width: 55px;
  outline: none;
  border: none;
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  margin-left: 5px;
  margin-right: 5px;
`;

const LabelWrapper = styled.button`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: right;
  padding-right: 10px;
  min-height: 42px;
`;

const Label = styled.div`
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: rgb(66, 70, 72);
  letter-spacing: 0.3px;
  text-align: center;
  line-height: 16px;
  color: ${({ def }) => (def ? 'white' : 'rgb(94, 98, 99)')};
  padding: ${({ def }) => (def ? '5px 15px' : '5px 0px')};
  border-radius: 5px;
  font-weight: 300;
  background: ${({ def }) => (def ? 'rgb(139 139 139)' : 'white')};
  max-width: ${({ def }) => (def ? '100px' : '90px')};
`;

const EditIconWrapper = styled.div`
  position: absolute;
  right: 0px;
`;

const EditIcon = styled.button`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  color: #5f6263;
`;

const Inner = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const RadioButtonWrapper = styled.div`
  height: 90px;
  top: 0px;
  left: 0px;
  position: absolute;
  padding-top: 10px;
`;

const Button = styled.button`
  margin-top: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: ${p => (p.disabled ? '#d2d3d3' : '#54a300')};
  text-align: center;

  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  position: absolute;
  padding: 25px 15px;
  left: 0px;
`;

const ButtonRemove = styled.button`
  margin-top: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: red;
  text-align: center;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  position: absolute;
  padding: 25px 15px;
  left: 0px;
`;

const ButtonLabel = styled.div`
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: normal;
  text-align: center;
  text-shadow: 1px 1px 1px #595959;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const CardName = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  span {
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
    color: #424648;
    font-weight: 300;
    display: block;
    padding-bottom: 7px;
  }
`;

const CardHolder = styled.div`
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: white;
  padding-left: ${({ withRadio }) => (withRadio ? '50px' : '15px')};
  position: relative;
  padding-bottom: ${({ expanded }) => (expanded ? '66px' : '10px')};
`;

export default Payment;
