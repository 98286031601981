import { compose, withProps, withHandlers } from 'recompose';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { actions as userActions } from 'redux/modules/user';
import wretches from 'wretches';
import isEmail from 'utils/isEmail';
import cleanPhoneNumber from 'utils/cleanPhoneNumber';
import { actions as spinnerActions } from 'redux/modules/spinner';

const enterPasswordHOC = compose(
  withProps(({ values: { login } }) => ({
    login,
  })),
  connect(null, {
    logIn: userActions.logIn,
    showSpinner: spinnerActions.showSpinner,
    hideSpinner: spinnerActions.hideSpinner,
  }),
  withFormik({
    mapPropsToValues: () => ({ password: '' }),
    enableReinitialize: true,
    validate: values => {
      const errors = {};
      if (!values.password) {
        errors.password = 'Required';
      }
      return errors;
    },
    handleSubmit: (
      { password },
      {
        props: { logIn, login, afterSignInDo, showSpinner, hideSpinner },
        resetForm,
        setFieldError,
      },
    ) => {
      showSpinner();
      wretches.userLogin
        .json({
          username: isEmail(login) ? login : cleanPhoneNumber(login),
          password,
        })
        .post()
        .json(json => {
          logIn({ ...json });
          hideSpinner();
          resetForm();
          if (afterSignInDo) {
            afterSignInDo();
          }
        })
        .catch(err => {
          hideSpinner();
          setFieldError('password', err.json.message);
        });
    },
  }),
  withHandlers({
    onPasswordFieldChange: ({ setFieldValue }) => e =>
      setFieldValue('password', e.target.value),
  }),
);

export default enterPasswordHOC;
