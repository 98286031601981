import {
  compose,
  withStateHandlers,
  branch,
  renderComponent,
  withProps,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { setDeliveryTimeType } from 'redux/modules/delivery';
import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { showIntervalsSelector } from 'selectors/showIntervalsSelector';
import { cantinaWeekSelector } from 'selectors/cantinaWeekSelector';

import { externalDeliverySelector } from 'redux/modules/general';

import { actions as modalActions } from 'components/ReduxModal';
import format from 'date-fns/format';
import addMinutes from 'date-fns/add_minutes';

import EditTime from 'routes/Checkout/components/ScheduleSettings/EditTime';
import ScheduleSettings from '../components/ScheduleSettings';

import isToday from 'date-fns/is_today';
import { history } from 'routes';

const TIME_FORMAT = 'hh:mma';

const scheduleSettingsHOC = compose(
  connect(
    state => ({
      minutesStepSize: timeIntervalSelector(state),
      showIntervals: showIntervalsSelector(state),
      scheduleType: deliveryTimeTypeSelector(state),
      scheduleTime: scheduleTimeSelector(state),
      cantinaWeek: cantinaWeekSelector(state),
      externalDelivery: externalDeliverySelector(state),
    }),
    {
      onClick: setDeliveryTimeType,
      showModal: modalActions.showModal,
      closeModal: modalActions.closeModal,
    },
  ),
  withProps(
    ({ scheduleTime, minutesStepSize, showIntervals, cantinaWeek }) => ({
      scheduleDate: cantinaWeek
        ? `${cantinaWeek?.start} - ${cantinaWeek?.stop}`
        : `${
            isToday(scheduleTime) ? 'Today' : format(scheduleTime, 'ddd, MMM D')
          }, ${
            showIntervals
              ? `${format(scheduleTime, 'hh:mm')} - ${format(
                  addMinutes(scheduleTime, minutesStepSize),
                  TIME_FORMAT,
                )}`
              : format(scheduleTime, TIME_FORMAT)
          }`,
    }),
  ),
  withHandlers({
    goToEditSchedule: ({ closeModal }) => () => {
      closeModal();
      history.push('/');
    },
  }),
  withStateHandlers(
    { editModeEnabled: false },
    {
      enableEditMode: (_, { cantinaWeek }) => () =>
        !cantinaWeek && { editModeEnabled: true },
      disableEditMode: () => () => ({ editModeEnabled: false }),
    },
  ),
  branch(({ editModeEnabled }) => editModeEnabled, renderComponent(EditTime)),
);

export default scheduleSettingsHOC(ScheduleSettings);
