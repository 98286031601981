import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import isNumber from 'lodash/isNumber';
import { actions as cartActions, choiseSelector } from 'redux/modules/cart';
import { checkoutOptionsSelector } from 'redux/modules/general';
import { getDeliveryType } from 'selectors/getDeliveryType';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import Choises from '../components/Choises';

export const ChoisesHOC = compose(
  connect(
    state => ({
      checkoutOptions: checkoutOptionsSelector(state),
      choise: choiseSelector(state),
      deliveryType: getDeliveryType(state),
      cartSubtotal: cartSubtotalSelector(state),
    }),
    {
      setChoise: cartActions.setChoise,
    },
  ),
  withProps(
    ({
      deliveryType,
      checkoutOptions: { options, title, defaultOption, appliesTo },
    }) => ({
      choisesEnabled:
        appliesTo == 'both' ||
        (deliveryType === 'DELIVERY' && appliesTo == 'delivery') ||
        (deliveryType === 'PICKUP' && appliesTo == 'pickup'),
      checkoutOptions: options,
    }),
  ),
  withProps(({ choise, checkoutOptions }) => ({
    choiseValue: choise,
    selectedOptionIndex: !isUndefined(choise)
      ? checkoutOptions.findIndex(v => choise === v.id)
      : undefined,
  })),
  withHandlers({
    onChoiseClick: ({ setChoise, choise }) => v => () => {
      if (v.id === choise) {
        setChoise(undefined);
      } else {
        setChoise(v.id || 0);
      }
    },
  }),
);

export default ChoisesHOC(Choises);
