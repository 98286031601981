import { createSelector } from 'reselect';

import getPreparationMinutesForTimeFunc from 'selectors/getPreparationMinutesForTimeFunc';
import deliveryTypeSelector from 'selectors/deliveryTypeSelector';

const getPreparationPlusDeliveryMinutesForTimeFunc = createSelector(
  [
    getPreparationMinutesForTimeFunc,
    deliveryTypeSelector,
    state => state.delivery,
    (_, props) => props,
  ],
  (
    getPreparationMinutesForTime,
    deliveryType = 'PICKUP',
    { deliveryTime = 0 },
    props,
  ) => time => {
    deliveryTime = 0;

    if (props) {
      //const selDeliveryTime = props.deliveryConditions.deliveryTime;
      const selDeliveryTime = 0;
      return (
        getPreparationMinutesForTime(time) +
        (props.deliveryType === 'DELIVERY' && props.deliveryConditions
          ? +selDeliveryTime
          : 0)
      );
    }
    return (
      getPreparationMinutesForTime(time) +
      (deliveryType === 'DELIVERY' ? +deliveryTime : 0)
    );
  },
);

export default getPreparationPlusDeliveryMinutesForTimeFunc;
