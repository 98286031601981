import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import format from 'date-fns/format';
import isYesterday from 'date-fns/is_yesterday';
import isToday from 'date-fns/is_today';
import isTomorrow from 'date-fns/is_tomorrow';

import { backendScheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import { getDeliveryTypeFormatted } from 'selectors/getDeliveryTypeFormatted';
import placeIdSelector from 'selectors/placeIdSelector';
import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { actions as orderActions } from 'redux/modules/orders';
import { actions as modalActions } from 'components/ReduxModal';

import OrderCard from '../components/OrderCard';

const OrderCardHOC = compose(
  connect(
    (
      state,
      {
        order: {
          data: { method },
        },
      },
    ) => ({
      type: getDeliveryType(state),
      deliveryTypeFormatted: getDeliveryTypeFormatted(state, { type: method }),
      deliveryTimeType: deliveryTimeTypeSelector(state),
      backendScheduleTime: backendScheduleTimeSelector(state),
      placeId: placeIdSelector(state),
    }),
    {
      reorder: orderActions.reorder,
      closeModal: modalActions.closeModal,
    },
  ),
  withProps(({ order: { data: { total, orderDate } } }) => ({
    deliveryTimeRepr: {
      [true]: format(orderDate, 'MM-DD-YYYY - hh:mm A'),
      [isYesterday(orderDate)]: `Yesterday at ${format(orderDate, 'hh:mm A')}`,
      [isToday(orderDate)]: `Today at ${format(orderDate, 'hh:mm A')}`,
      [isTomorrow(orderDate)]: `Tomorrow at ${format(orderDate, 'hh:mm A')}`,
    }.true,
    price: +total,
  })),
  withRouter,
  withHandlers({
    onReorderClick: ({
      reorder,
      order: { orderId },
      type,
      deliveryTimeType,
      backendScheduleTime,
      placeId,
      history,
    }) => () => {
      reorder({
        orderId,
        type,
        deliveryTimeType,
        backendScheduleTime,
        placeId,
        history,
      });
    },
    onViewInvoiceClick: ({
      order: { orderId },
      history,
      closeModal
    }) => () => {
      closeModal();  
      history.push({
        pathname: '/orders/invoice/'+orderId,
        state: {
          modal: false,
        },
      });
    },
  }),
);

export default OrderCardHOC(OrderCard);
