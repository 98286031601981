import { compose, withStateHandlers,withHandlers } from 'recompose';
import { connect } from 'react-redux';

import {
  promocodeSelector,
  redeemCurrencySelector,
  removePromocode,
  nonceSelector,
} from 'redux/modules/cart';

import { centsToDollars } from 'utils/formatCents';
import { getDeliveryType } from 'selectors/getDeliveryType';
import tipAmountSelector from 'selectors/tipAmountSelector';
import tipsAllowedSelector from 'selectors/tipsAllowedSelector';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import promocodeDiscountSelector from 'selectors/promocodeDiscountSelector';
import taxesTotalSelector from 'selectors/taxesTotalSelector';
import { serviceFeeSelector } from 'redux/modules/general';
import serviceFeeTotalSelector from 'selectors/serviceFeeTotalSelector';
import serviceFeeNameSelector from 'selectors/serviceFeeNameSelector';
import deliveryCostSelector from 'selectors/deliveryCostSelector';
import totalPriceSelector from 'selectors/totalPriceSelector';
import OrderSummary from '../components/OrderSummary';
import { actions as orderActions } from 'redux/modules/orders';


const mapStateToProps = state => {
  const cartSubtotal = cartSubtotalSelector(state);
  const discountPrice = promocodeDiscountSelector(state);
  const deliveryCost = deliveryCostSelector(state);
  const deliveryType = getDeliveryType(state);
  const tipPrice = tipAmountSelector(state);
  const tipsAllowed = tipsAllowedSelector(state);
  const taxesTotal = taxesTotalSelector(state);
  const total = totalPriceSelector(state);
  const serviceFeeTotal = serviceFeeTotalSelector(state);
  const serviceFeeName = serviceFeeNameSelector(state);
  const serviceFee = serviceFeeSelector(state);
  const promocode = promocodeSelector(state);
  const redeemCurrency = redeemCurrencySelector(state);
  const taxesAndFees = taxesTotal + serviceFeeTotal;
  const nonce = nonceSelector(state);

  return {
    prices: [
      { name: 'Subtotal', price: cartSubtotal, isSubtotal: true },
      {
        name: serviceFeeTotal > 0 ? 'Taxes & Fees' : 'Tax',
        price: taxesAndFees,
        isTaxAndFees: serviceFeeTotal > 0,
      },
      ...(deliveryType === 'DELIVERY'
        ? [{ name: 'Delivery Fee', price: deliveryCost, isDeliveryCost: true }]
        : []),
      ...(tipsAllowed && tipPrice ? [{ name: 'Tip', price: tipPrice }] : []),
    ],
    ...(redeemCurrency && {
      redeemCurrency: {
        name: `Rewards`,
        price: centsToDollars(redeemCurrency),
      },
    }),
    ...(promocode && {
      discount: {
        name: `Promotion`,
        price: discountPrice,
      },
    }),
    total,
    serviceFeeTotal,
    taxesTotal,
    ServiceFeeName: serviceFeeName || 'Service fee',
    nonce,
    cartSubtotal,
  };
};

export const OrderSummaryHOC = compose(
  connect(mapStateToProps, 
  {
    removePromocode,
    feeInform: orderActions.feeInform,
  }
  ),
  withHandlers({
    sendInform: ({ feeInform,nonce,serviceFeeTotal,cartSubtotal }) => value => {
      feeInform(nonce,serviceFeeTotal,cartSubtotal);
    }
  }),
  withStateHandlers(
    { TaxAndFeesEnabled: false, DeliveryInfoEnabled: false},
    {
      enableTaxAndFees: (_, { sendInform }) => () => {
        sendInform();
        return {
          TaxAndFeesEnabled: true 
        };
        

      },
      disableTaxAndFees: () => () => ({ TaxAndFeesEnabled: false }),
      enableDeliveryInfo: () => () => ({ DeliveryInfoEnabled: true }),
      disableDeliveryInfo: () => () => ({ DeliveryInfoEnabled: false }),
    },
  ),
);

export default OrderSummaryHOC(OrderSummary);
