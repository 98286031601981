import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from 'components/Icon';

class DeliveryTime extends Component {
  render() {
    const { description, cantinaEnabled } = this.props;
    return (
      <Wrapper>
        <Top>
          <CalendarIcon>
            <Icon name="icon-soonest-available-small" size="32px" />
          </CalendarIcon>
          <Title>
            <span>Scheduled:</span> {description}
          </Title>
          <IconEdit>
            {!cantinaEnabled && <Icon name="icon-edit-small" size="26px" />}
          </IconEdit>
        </Top>
      </Wrapper>
    );
  }
}

DeliveryTime.propTypes = {
  description: PropTypes.string,
  cantinaEnabled: PropTypes.bool,
};

const Wrapper = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 2px;
  border-top: 1px solid #eeefef;
`;

const CalendarIcon = styled.div`
  min-width: 22px;
  min-height: 20px;
  width: 22px;
  height: 20px;
  margin-right: 5px;
  color: #5f6263;
`;

const Title = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #424648;
  flex: 1 1 auto;
  font-weight: 300;

  span {
    font-weight: normal;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  color: rgb(116, 119, 120);
  margin-top: 5px;
  margin-left: 32px;
  font-weight: 300;
`;

const IconEdit = styled.div`
  width: 10px;
  height: 10px;
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const Bottom = styled.div``;

export default DeliveryTime;
