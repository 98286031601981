import React from 'react';
import { compose, withHandlers, withStateHandlers } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card, { Header, Title as CardTitle } from 'components/Card';
import Icon from 'components/Icon';

const SpecialInstructionsCard = ({
  value,
  onChange,
  placeholder,
  inputEnabled,
  showInput,
  hideInput,
  resetValue,
}) => (
  <GreenBorderCard showgreen={!inputEnabled && value == ''}>
    <InputToggler onClick={inputEnabled ? hideInput : showInput}>
      <IconWrapper>
        <Icon name="icon-note-small" size="35px" />
      </IconWrapper>
      Add Special Instructions
      {value == '' && (
        <IconWrapperRight>
          <Icon name="icon-arrow-right-small" size="25px" />
        </IconWrapperRight>
      )}
      {value !== '' && <RemoveButton onClick={resetValue}>Remove</RemoveButton>}
    </InputToggler>
    {(inputEnabled || value !== '') && (
      <Wrapper>
        <Textarea value={value} onChange={onChange} placeholder={placeholder} />
      </Wrapper>
    )}
  </GreenBorderCard>
);

SpecialInstructionsCard.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputEnabled: PropTypes.bool,
  showInput: PropTypes.func,
  hideInput: PropTypes.func,
  resetValue: PropTypes.func,
};

SpecialInstructionsCard.defaultProps = {
  placeholder: 'Add note (extra sauce, no pickles). Charges may apply.',
};

const SpecialInstructionsCardHOC = compose(
  withHandlers({
    onChange: ({ onChange }) => e => {
      onChange(e.target.value);
    },
    resetValue: ({ onChange }) => e => {
      onChange('');
    },
  }),
  withStateHandlers(
    { inputEnabled: false },
    {
      showInput: () => () => ({ inputEnabled: true }),
      hideInput: () => () => ({ inputEnabled: false }),
    },
  ),
);

export default SpecialInstructionsCardHOC(SpecialInstructionsCard);

const GreenBorderCard = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  border: 1px solid #ffffff;
`;

const Textarea = styled.textarea`
  background: #fff;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  resize: none;
  display: block;
  outline: none;
  width: 100%;
  padding: 5px 100px 5px 20px;
  min-height: 60px;
  border: none;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
`;

const Wrapper = styled.div`
  position: relative;
  :focus-within {
    outline: 1px solid #54a300;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  color: #424648;
`;

const IconWrapperRight = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  color: #424648;
`;

const InputToggler = styled.div`
  display: block;
  background: #fff;
  padding: 15px 15px 15px 45px;
  font-size: 17px;
  color: #424648;
  position: relative;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: #c93b32;
`;
