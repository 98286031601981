import { createSelector } from 'reselect/lib/index';

import format from 'date-fns/format';
import addMinutes from 'date-fns/add_minutes';

import { deliveryTimeTypeSelector } from 'selectors/deliveryTimeTypeSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import { scheduleTimeSelector } from 'selectors/scheduleTimeSelector';
import deliveryDaySelector from 'selectors/deliveryDaySelector';
import asapPreparationTimeSelector from 'selectors/asapPreparationTimeSelector';
import { showIntervalsSelector } from 'selectors/showIntervalsSelector';
import { timeIntervalSelector } from 'selectors/timeIntervalSelector';
import { cantinaWeekSelector } from 'selectors/cantinaWeekSelector';
import { getDeliveryTypeFormatted } from 'selectors/getDeliveryTypeFormatted';

const TIME_FORMAT = 'hh:mma';

const deliveryEstimateTextSelector = createSelector(
  [
    deliveryDaySelector,
    scheduleTimeSelector,
    deliveryTimeTypeSelector,
    getDeliveryType,
    getDeliveryTypeFormatted,
    asapPreparationTimeSelector,
    showIntervalsSelector,
    timeIntervalSelector,
    cantinaWeekSelector,
  ],
  (
    deliveryDay,
    deliveryTime,
    deliveryTimeType,
    deliveryType,
    deliveryTypeFormatted,
    asapPreparationTime,
    showIntervals,
    minutesStepSize,
    cantinaWeek,
  ) => {
    let left;
    if (deliveryTimeType && deliveryType) {
      left = deliveryTypeFormatted;
    }

    let right;
    if (cantinaWeek) {
      right = `${cantinaWeek?.start} - ${cantinaWeek?.stop}`;
    } else if (deliveryTimeType === 'SCHEDULE') {
      if (deliveryDay && deliveryTime) {
        if (showIntervals) {
          right = `${deliveryDay} ${format(
            deliveryTime,
            TIME_FORMAT,
          )} - ${format(
            addMinutes(deliveryTime, minutesStepSize),
            TIME_FORMAT,
          )}`;
        } else {
          right = `${deliveryDay} ${format(deliveryTime, TIME_FORMAT)}`;
        }
      }
    } else if (deliveryTimeType === 'SOONEST') {
      if (asapPreparationTime) {
        if (deliveryType == 'DELIVERY') {
          right = `${Math.floor(asapPreparationTime / 5) * 5 - 5}-${Math.floor(
            asapPreparationTime / 5,
          ) *
            5 +
            5} Minutes`;
        } else {
          right = `Soonest available (${asapPreparationTime}m)`;
        }
      }
    }

    if (left && right) {
      return `${left}: ${right}`;
    }

    return 'Select delivery option';
  },
);

export default deliveryEstimateTextSelector;
