import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
  actions as deliveryActions,
  deliverySelector,
} from 'redux/modules/delivery';
import { actions as userActions } from 'redux/modules/user';
import uuidv4 from 'uuid/v4';

const withAddNewAddress = compose(
  connect(
    state => ({
      delivery: deliverySelector(state),
    }),
    {
      addDeliveryAddress: userActions.addDeliveryAddress,
      createAddress: userActions.createAddress,
    },
  ),
  withProps(({ delivery: { userAddressId } }) => ({
    userAddressId,
  })),
  withHandlers({
    addNewAddress: ({ createAddress, addDeliveryAddress }) => addressInfo => {
      const id = uuidv4();
      const data = { id, name: addressInfo.saveAs, ...addressInfo };
      addDeliveryAddress(data);
      if (addressInfo.saveAs) {
        createAddress(data);
      }
    },
  }),
);

export default withAddNewAddress;
