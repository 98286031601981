import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import styled, {keyframes} from 'styled-components';
import Icon from 'components/Icon';
import thumbdown from '../../assets/thumbdown.png';
import cart_icon from '../../assets/cart_icon.png';


const PopupStyled = styled.div`
  pointer-events: all;
  cursor: default;
  text-align: center;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px;
  padding: 16px;
  width: 100%;
  max-width: 400px;
  position: absolute;
  bottom: 0;
`;


const UpsellItemsWrapper = styled.div`
  background: #101010b8;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  z-index: 3147483647;
  /* -webkit-transform: translate3d(0,0,0); */
  -ms-transform: translate3d(0, 0, 0);
  /* transform: translate3d(0,0,0); */
  top: 0px;
  bottom: 0px;
`;
const UpsellItemsTitle = styled.div`
  font-size: 19px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #000000;
  letter-spacing: 0.3px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
  &.doNotShow {
    opacity: 0.3;
  }
`;
const UpsellItemsDesc = styled.div`
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #000000;
  letter-spacing: 0.3px;
  text-align: center;
  margin-bottom: 30px;
`;

const UpsellItemsContent = styled.div`
  padding: 16px;
  z-index: 11002;
  background-color: #f7f7f7;
  position: fixed !important;
  margin-top: -100px;
  border-radius: 3px;
  width: 100%;
  max-width: 100%;
  bottom: -600px; /* Start from the bottom of the screen */
  left: 0px;
  transition: all 0.2s ease-in; /* Reduced the transition time for a smoother effect */
  &.showMe {
    bottom: 0% !important;
  }
`;

const UpsellItem = styled.div`
  display: block;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
  margin-top: 10px;
  border-radius: 7px;
  position: relative;
  
    p{

    }
`;

const UpsellItemName = styled.div`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 400;
  padding: 20px 20px 0px 20px;
  &.doNotShow {
    opacity: 0.3;
  }
`;

const UpsellItemDescription = styled.div`
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: #707070;
  text-align: left;
  margin-bottom: 0px;
  font-weight: 300;
  padding: 0px 20px 20px 20px;
  margin-right: 60px;
`;

const NoThanks = styled.div`
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: 400;
`;

const Image = styled.div`
  height: 210px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: url(${({ img }) => img}) no-repeat center / cover;
`;




const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
`;

const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 130, 150;
    stroke-dashoffset: 0;
  }
`;

const growLineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const CircleItem = styled.div`
  width: 170px;
  height: 170px;
  margin: 70px auto 0px auto;

  .circle__spinner {
    width: 170px;
    height: 170px;
    animation: ${spinAnimation} 3s cubic-bezier(0.5, 0, 0.25, 1) infinite;

    .path {
      stroke: #54A31C;
      stroke-linecap: round;
      animation: ${dashAnimation} 3s cubic-bezier(0.5, 0, 0.25, 1) infinite;
    }
  }
`;

const HorizontalLine = styled.div`
  background-color: #54A31C;
  height: 9px;
  width: 0;
  animation: ${growLineAnimation} 3s cubic-bezier(0.5, 0, 0.25, 1) infinite;
  position: absolute;
  left: 0px;
  top: 0px;
}
`;


const IconWrapper = styled.div`
      height: 150px;
      position: absolute;
      text-align: center;
      width: 170px;
      color: #54A31C;

      img {
        margin-top: 53px;
      }
`;

const DontShowThisAgain = styled.div`
font-size: 15px;
background: #000000cf;
height: 37px;
border-radius: 50px;
width: 37px;
line-height: 49px;
position: absolute;
top: 85px;
right: 35px;
&.hasDescription{
  top: 95px;
}
&.hasImage{
  top: 225px;
  left: 30px;
  right: auto;
}
`;

const MarkedAsDoNotShowAgain = styled.div `

    text-align: center;
    color: #FFFFFF;
    width: 100%;
    position: relative;
    
    
    svg {
      margin-top: 40px;
      position: absolute;
    }


    &.no_image {
      padding-bottom:20px;
      position: relative;
    }

    
    p {
      color: black;
      text-align: left;
      margin-left: 20px;
      font-size: 14px;
    }

    &.has_image {
        height: 210px;
        position: absolute;
        text-align: center;
        overflow: hidden;
        color: #FFFFFF;
        width: 100%;
        background: #000000a1;
        
        svg {
          margin-top: 40px;
          position: initial;  
        }
        p {
          margin-top: 40px;
          margin-bottom: 40px;
          margin-left: 0px;
          color: #FFFFFF;
          font-size: 16px;  
          text-align: center;
        }
    }

`;


const UndoDoNotShow = styled.div` 


text-align: center;
text-decoration: underline;
color: #FFFFFF;

  p:before {
    position: absolute;
    content: '';
    left: -30px;
    width: 20px;
    height: 20px;
    background: url(${thumbdown}) no-repeat center / contain;
    display: inline-block;
    top: 15px;
  }
&.no_image {
  position: absolute;
  right: 23px;
  top: 0px;
  color: #000000;
  font-size: 13px;
}
`;

const ThumbHolder = styled.div` 
width: 37px;
height: 37px;
background: #0000000a;
line-height: 37px;
position: absolute;
top: -60px;
right: 20px;
border-radius: 30px;

  svg{
    margin-top: 10px;
    position: initial;
  }
`;




const UpsellComponent = ({
  upsellItem,
  handleDishClick,
  handleNoThanksAction,
  markedAsDoNotShow,
  doNotShowAgain,
  showAgain
}) => (
  <PopupStyled>
      <div>
        <UpsellItemsWrapper>
            <UpsellItemsContent className='UpsellItemsContentHolder'>
            
            <>
                <UpsellItemsTitle>You might also be interested in</UpsellItemsTitle>
                <UpsellItem onClick={!markedAsDoNotShow ? handleDishClick : null} >
                    {markedAsDoNotShow && upsellItem.image && (
                      (
                        <MarkedAsDoNotShowAgain className='has_image'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.8" height="19.3" viewBox="0 0 20.8 19.3">
                            <g id="Group_3165" data-name="Group 3165" transform="translate(-41.35 -620.35)">
                              <path id="ThumbDownIcon" d="M7.5,15.25H4.372a2,2,0,0,1-2.054-1.715A12.136,12.136,0,0,1,2.25,12.25,11.948,11.948,0,0,1,4.9,4.729,2.04,2.04,0,0,1,6.5,4H10.52a4.5,4.5,0,0,1,1.423.23l3.114,1.04a4.5,4.5,0,0,0,1.423.23h1.294M7.5,15.25a.888.888,0,0,1,.725,1.282A7.471,7.471,0,0,0,7.5,19.75,2.25,2.25,0,0,0,9.75,22a.75.75,0,0,0,.75-.75v-.633a4.516,4.516,0,0,1,.322-1.672,3.471,3.471,0,0,1,1.653-1.715,9.04,9.04,0,0,0,2.86-2.4,2.638,2.638,0,0,1,2.032-1.08h.384M7.5,15.25H9.7M17.775,5.5a.63.63,0,0,0,.05.148,9.014,9.014,0,0,1-.074,8.1m.023-8.25a.613.613,0,0,1,.575-.75h.908a2.014,2.014,0,0,1,1.972,1.368,12.058,12.058,0,0,1-.31,7.9A2.023,2.023,0,0,1,19,15.25H17.948a.629.629,0,0,1-.5-.96q.161-.265.3-.54" transform="translate(39.75 617)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                            </g>
                          </svg>
                          <p>You won't be offered this again</p>
                          <UndoDoNotShow onClick={showAgain}>Undo</UndoDoNotShow>
                        </MarkedAsDoNotShowAgain>
                      )
                    )}
                    {upsellItem.image && <Image img={upsellItem.image} />}
                    <UpsellItemName className={markedAsDoNotShow ? 'doNotShow' : ''} >{upsellItem.title}</UpsellItemName>
                    <UpsellItemDescription className={markedAsDoNotShow ? 'doNotShow' : ''}> 
                        {
                        // show up to 30 words of description, show ... at the end only if there are more than 30 words
                        upsellItem.description.split(" ").length < 30 ? upsellItem.description : upsellItem.description.split(" ").splice(0,30).join(" ") + "..."
                        }
                    </UpsellItemDescription>
                    {markedAsDoNotShow && !upsellItem.image && (
                      (
                        <MarkedAsDoNotShowAgain className='no_image'>
                          <ThumbHolder>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.8" height="19.3" viewBox="0 0 20.8 19.3">
                              <g id="Group_3165" data-name="Group 3165" transform="translate(-41.35 -620.35)">
                                <path id="ThumbDownIcon" d="M7.5,15.25H4.372a2,2,0,0,1-2.054-1.715A12.136,12.136,0,0,1,2.25,12.25,11.948,11.948,0,0,1,4.9,4.729,2.04,2.04,0,0,1,6.5,4H10.52a4.5,4.5,0,0,1,1.423.23l3.114,1.04a4.5,4.5,0,0,0,1.423.23h1.294M7.5,15.25a.888.888,0,0,1,.725,1.282A7.471,7.471,0,0,0,7.5,19.75,2.25,2.25,0,0,0,9.75,22a.75.75,0,0,0,.75-.75v-.633a4.516,4.516,0,0,1,.322-1.672,3.471,3.471,0,0,1,1.653-1.715,9.04,9.04,0,0,0,2.86-2.4,2.638,2.638,0,0,1,2.032-1.08h.384M7.5,15.25H9.7M17.775,5.5a.63.63,0,0,0,.05.148,9.014,9.014,0,0,1-.074,8.1m.023-8.25a.613.613,0,0,1,.575-.75h.908a2.014,2.014,0,0,1,1.972,1.368,12.058,12.058,0,0,1-.31,7.9A2.023,2.023,0,0,1,19,15.25H17.948a.629.629,0,0,1-.5-.96q.161-.265.3-.54" transform="translate(39.75 617)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
                              </g>
                            </svg>
                          </ThumbHolder>
                          <p>You won't be offered this again</p>
                          <UndoDoNotShow className='no_image' onClick={showAgain}>Undo</UndoDoNotShow>
                        </MarkedAsDoNotShowAgain>
                      )
                    )}     
                </UpsellItem>
                {!markedAsDoNotShow &&  (
                <DontShowThisAgain onClick={doNotShowAgain} className={upsellItem.image ? 'hasImage': ''}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20.8" height="19.3" viewBox="0 0 20.8 19.3">
  <g id="Group_3165" data-name="Group 3165" transform="translate(-41.35 -620.35)">
    <path id="ThumbDownIcon" d="M7.5,15.25H4.372a2,2,0,0,1-2.054-1.715A12.136,12.136,0,0,1,2.25,12.25,11.948,11.948,0,0,1,4.9,4.729,2.04,2.04,0,0,1,6.5,4H10.52a4.5,4.5,0,0,1,1.423.23l3.114,1.04a4.5,4.5,0,0,0,1.423.23h1.294M7.5,15.25a.888.888,0,0,1,.725,1.282A7.471,7.471,0,0,0,7.5,19.75,2.25,2.25,0,0,0,9.75,22a.75.75,0,0,0,.75-.75v-.633a4.516,4.516,0,0,1,.322-1.672,3.471,3.471,0,0,1,1.653-1.715,9.04,9.04,0,0,0,2.86-2.4,2.638,2.638,0,0,1,2.032-1.08h.384M7.5,15.25H9.7M17.775,5.5a.63.63,0,0,0,.05.148,9.014,9.014,0,0,1-.074,8.1m.023-8.25a.613.613,0,0,1,.575-.75h.908a2.014,2.014,0,0,1,1.972,1.368,12.058,12.058,0,0,1-.31,7.9A2.023,2.023,0,0,1,19,15.25H17.948a.629.629,0,0,1-.5-.96q.161-.265.3-.54" transform="translate(39.75 617)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"/>
  </g>
</svg>
                </DontShowThisAgain>
                )
                }
                {markedAsDoNotShow ? (
                <>
                  <Button  onClick={handleNoThanksAction}>Continue</Button>
                </>
                ):
                <>
                  <Button  onClick={handleDishClick}>Add to Order</Button>
                  <NoThanks onClick={handleNoThanksAction}>No thanks</NoThanks> 
                </>
                }
            </>

            </UpsellItemsContent>
        </UpsellItemsWrapper>
        
      </div>
    
  </PopupStyled>
);




UpsellComponent.propTypes = {
  upsellItem: PropTypes.object,
};

export default UpsellComponent;
