import { createSelector } from 'reselect';

import round from 'lodash/round';

import { backendFeeSelector } from 'redux/modules/cart';

const serviceFeeNameSelector = createSelector(
  [backendFeeSelector],
  backendFeeSelector => backendFeeSelector.feeName,
);

export default serviceFeeNameSelector;
