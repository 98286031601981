import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import deliveryEstimateTextSelector from 'selectors/deliveryEstimatesTextSelector';
import { getDeliveryType } from 'selectors/getDeliveryType';
import cartSubtotalSelector from 'selectors/cartSubtotalSelector';
import { actions as modalActions } from 'components/ReduxModal';
import { actions as orderActions } from 'redux/modules/orders';

import CartHOC from 'HOC/Cart';

import DesktopCart from '../components/DesktopCart';

export const DesktopCartHOC = compose(
  CartHOC,
  withRouter,
  connect(
    state => ({
      deliveryTimeText: deliveryEstimateTextSelector(state),
      deliveryType: getDeliveryType(state),
      cartSubtotal: cartSubtotalSelector(state),
    }),
    {
      showModal: modalActions.showModal,
      reviewOrder: orderActions.reviewOrder,
    },
  ),
  withHandlers({
    handleCheckoutClick: ({
      userLoggedIn,
      checkoutButtonEnabled,
      history,
      deliveryType,
      cartSubtotal,
      reviewOrder,
    }) => () => {
      if (checkoutButtonEnabled) {
        const method = {
          type: deliveryType.toLowerCase(),
        };

        const body = {
          method,
          cartTotal: cartSubtotal,
        };
        const myMethod = {
          deliveryType,
        };
        reviewOrder(body, myMethod, true, userLoggedIn);
      }
    },
    handleEditTimeClick: ({ deliveryType, closeCart, showModal }) => () => {
      if (deliveryType) {
        showModal('edit-time');
      } else {
        closeCart();
      }
    },
  }),
);

export default DesktopCartHOC(DesktopCart);
