import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'utils/mediaQueries';

import SettingsCard from 'components/SettingsCard';
import Icon from 'components/Icon';

import SpecialInstructionsCard from 'components/SpecialInstructionsCard';
import Card, {
  Header,
  Title as CardTitle,
  Label as CardLabel,
} from 'components/Card';
import Footer from 'components/Footer';

import FixedNumber from 'components/FixedNumber';
import { history } from 'routes';

import DesktopHeader from 'containers/DesktopHeader';
import PaymentMethod from './components/PaymentMethod';
import ScheduleSettings from './containers/ScheduleSettings';
import Tip from './containers/TipContainer';
import { TableNumberInput } from 'routes/Checkout/components/TableNumberInput';
import { DesktopDishesList } from 'components/DesktopHeader/components/DesktopCart';
import OrderSummaryContainer from './containers/OrderSummaryContainer';
import DeliveryMethod from './containers/DeliveryMethod';
import { RedeemPoints } from './RedeemPoints';

import Choises from './containers/ChoisesContainer';

const ItemsList = ({ dishes, subtotal, onViewMenuClick }) => (
  <Card>
    <Header whiteBg>
      <CardTitle> Item List </CardTitle>
      <CardLabel style={{ cursor: 'pointer' }} onClick={onViewMenuClick}>
        EDIT ORDER
      </CardLabel>
    </Header>
    <DesktopDishesList dishes={dishes} readOnly />
  </Card>
);

ItemsList.propTypes = {
  dishes: PropTypes.array,
  subtotal: PropTypes.number,
  onViewMenuClick: PropTypes.func,
};

const Checkout = ({
  price,
  onPlaceOrderClick,
  promocode,
  cartSubtotal,
  dishes,
  showModalPromocode,
  specialInstructions,
  onSpecialInstructionsChange,
  paymentInvalid,
  tipsAllowed,
  loyalty,
  redeemCurrency,
  toggleRedeemCurrency,
  dineInEnabled,
  dineInTable,
  setDineInTable,
  tableNumberInvalid,
  removePromocode,
  hideLoyaltyPopup,
  hideSpecialInstructions,
  hidePickupBox,
  checkoutOptions,
  checkoutOptionsAllowed,
  checkoutOptionInvalid,
  deliveryType,
}) => (
  <Wrapper>
    <HeaderWrapper>
      <DesktopHeader />
    </HeaderWrapper>
    <ContentInner>
      <Container>
        <Content>
          {dineInEnabled ? (
            <ItemWrapper showError={tableNumberInvalid}>
              <SettingsCard
                title="Dine-in table"
                showError={tableNumberInvalid}
              >
                <TableNumberInput
                  value={dineInTable}
                  onChange={setDineInTable}
                  error={tableNumberInvalid}
                />
              </SettingsCard>
            </ItemWrapper>
          ) : (
            <ItemWrapper>
              <DeliveryMethod ismobile={false} hidePickupBox={hidePickupBox} />
            </ItemWrapper>
          )}

          {checkoutOptionsAllowed && (
            <ItemWrapper showError={checkoutOptionInvalid}>
              <SettingsCard
                whiteBg
                title={checkoutOptions.title}
                showError={checkoutOptionInvalid}
              >
                <Choises />
              </SettingsCard>
            </ItemWrapper>
          )}

          <ItemWrapper showError={paymentInvalid}>
            <SettingsCard
              whiteBg
              title="Payment method"
              showError={paymentInvalid}
            >
              <PaymentMethod />
            </SettingsCard>
          </ItemWrapper>

          {tipsAllowed && (
            <ItemWrapper>
              <SettingsCard whiteBg title="Tip">
                <Tip />
              </SettingsCard>
            </ItemWrapper>
          )}

          {hideSpecialInstructions != 'both' &&
            hideSpecialInstructions != 'checkout' && (
              <ItemWrapper>
                <SpecialInstructionsCard
                  whiteBg
                  value={specialInstructions}
                  onChange={onSpecialInstructionsChange}
                />
              </ItemWrapper>
            )}
        </Content>

        <Content>
          <ItemWrapper>
            <ItemsList
              dishes={dishes}
              subtotal={cartSubtotal}
              onViewMenuClick={() => history.push('/menu/cart')}
            />
          </ItemWrapper>

          <ItemWrapper>
            <Promo onClick={!promocode ? showModalPromocode : undefined}>
              <IconWrapper className={promocode ? 'promoApplied' : undefined}>
                <Icon name="icon-promotions-small" size="34px" />
              </IconWrapper>
              <PromoButton>
                {promocode ? (
                  <>
                    <span>Promotion Applied</span>
                    <PromocodeName>{promocode}</PromocodeName>
                    <RemoveButton onClick={removePromocode}>
                      Remove
                    </RemoveButton>
                  </>
                ) : (
                  <>
                    <span>Enter Promo code</span>
                    <IconWrapperRight>
                      <Icon name="icon-arrow-right-small" size="25px" />
                    </IconWrapperRight>
                  </>
                )}
              </PromoButton>
            </Promo>
          </ItemWrapper>

          <ItemWrapper>
            <RedeemPoints
              loyalty={loyalty}
              redeemCurrency={redeemCurrency}
              toggleRedeemCurrency={toggleRedeemCurrency}
            />
          </ItemWrapper>

          <ItemWrapper>
            <OrderSummaryContainer />
          </ItemWrapper>

          {checkoutOptions.disclaimer && (
              <ItemWrapper>
                <Disclaimer>{checkoutOptions.disclaimer.replace("{method}",deliveryType.toLowerCase())}</Disclaimer>
              </ItemWrapper>
          )}

          <ItemWrapper>
            <ReviewOrder onClick={onPlaceOrderClick}>
              <Label>Place Order · </Label>
              <Amount>
                $<FixedNumber>{price}</FixedNumber>
              </Amount>
            </ReviewOrder>
          </ItemWrapper>
        </Content>
      </Container>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </ContentInner>
  </Wrapper>
);

export default Checkout;

Checkout.propTypes = {
  onPlaceOrderClick: PropTypes.func,
  price: PropTypes.number,
  promocode: PropTypes.string,
  dishes: PropTypes.array,
  cartSubtotal: PropTypes.number,
  showModalPromocode: PropTypes.func,
  specialInstructions: PropTypes.string,
  onSpecialInstructionsChange: PropTypes.func,
  paymentInvalid: PropTypes.bool.isRequired,
  tipsAllowed: PropTypes.bool.isRequired,
  loyalty: PropTypes.shape({}).isRequired,
  redeemCurrency: PropTypes.number.isRequired,
  toggleRedeemCurrency: PropTypes.func,
  dineInEnabled: PropTypes.bool.isRequired,
  dineInTable: PropTypes.number.isRequired,
  setDineInTable: PropTypes.func.isRequired,
  tableNumberInvalid: PropTypes.bool.isRequired,
  removePromocode: PropTypes.func,
  hideLoyaltyPopup: PropTypes.func,
  hideSpecialInstructions: PropTypes.string,
  hidePickupBox: PropTypes.bool,
  checkoutOptionsAllowed: PropTypes.bool.isRequired,
  checkoutOptionInvalid: PropTypes.bool.isRequired,
  deliveryType: PropTypes.string,
};

Checkout.defaultProps = {
  toggleRedeemCurrency: undefined,
};

const Content = styled.div`
  width: calc(50% - 20px);

  &:last-child {
    margin-left: 40px;
  }
`;

const ReviewOrder = styled.button`
  background: #54a300;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 17px 10px;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 400;
  letter-spacing: 1px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 15px 15px;
  cursor: pointer;
  transition: background 0.2s ease;

  &:hover {
    background: #67c70f;
  }
`;

const Amount = styled.div`
  font-family: 'Roboto', sans-serif;
  display: inline-block;
  font-size: 17px;
  padding-left: 6px;
  font-weight: bold;
`;

const Label = styled.div`
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  display: inline-block;
  font-size: 19px;
  font-weight: normal;
`;

const ItemWrapper = styled.div`
  margin-top: 25px;
  border-radius: 2px;
  border: ${p => p.showError && '2px solid red'};
`;

const Promo = styled.button`
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-transform: uppercase;
  position: relative;
  span {
    text-transform: initial;
  }
  .promoApplied {
    margin-top: -20px;
  }
`;

const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  color: #5f6263;
`;

const PromoButton = styled.div`
  font-size: 17px;
  font-family: 'Roboto', sans-serif;
  outline: none;
  border: none;
  padding: 5px 0;
  margin-left: 10px;

  &::-webkit-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &::-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-ms-input-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }
  &:-moz-placeholder {
    color: rgb(116, 121, 122);
    font-style: italic;
  }

  span {
    color: rgb(116, 121, 122);
  }
`;

const Wrapper = styled.div``;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
  padding: 0;
  box-shadow: 1px 1px 1px 0 #dedfe0;
`;

const FooterWrapper = styled.div`
  margin-top: 100px;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  max-width: 885px;
  margin: 12px auto 0 auto;

  ${media.tablet`
    padding: 0 30px;
  `} ${media.desktop`
    padding: 0;
  `};
`;

const ContentInner = styled.div`
  padding-top: 50px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const PromocodeName = styled.div`
  font-size: 13px;
  font-weight: 300;
  text-transform: capitalize;
  padding-top: 10px;
  color: #424648;
`;
const IconWrapperRight = styled.div`
  position: absolute;
  top: 22px;
  right: 25px;
  color: #424648;
`;

const RemoveButton = styled.div`
  position: absolute;
  top: 23px;
  right: 1px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 11px;
  color: #c93b32;
  text-transform: none;
`;

const Disclaimer = styled.div`
    clear: both;
    display: block;
    margin: 0px;
    padding: 10px;
    background: #ffe503;
    line-height: 150%;
    text-align: center;
    color: #0d0e0e;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 10%);
`;
