import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import enterVerificationCodeHOC from 'HOC/enterVerificationCodeHOC';
import SubFooter from 'components/SubFooter';
import CodeNumberInput from 'components/CodeNumberInput';
import AccountDropdown from 'components/AccountDropdown';
import InputErrors from 'components/InputErrors';
import Timer from 'routes/Modals/Timer';
import ModalComponents from './ModalComponents';

const EnterVerificationCode = ({
  title,
  phoneNumber,
  submitCode,
  codeError,
  requestCodeRetryClick,
  onTryAgainClick,
  retryTimerTill,
  sentVia,
  makedEmail,
  noMoreAttempts,
  lastName
}) => (
  <ModalComponents.ModalContent>
    <ModalComponents.ContentContainer>
      <ModalComponents.Title>{title}</ModalComponents.Title>
      <ModalComponents.SubTitle>We sent a code to</ModalComponents.SubTitle>
      <ModalComponents.Form>

        {sentVia || lastName ? (
            <SelectedMethod>{
              sentVia && sentVia == "email" ? makedEmail : phoneNumber
              }
            </SelectedMethod>
        ) : (
          
        <AccountDropdown
          value={(sentVia && sentVia == "email") ? makedEmail : phoneNumber}
          options={[(sentVia && sentVia == "email") ? makedEmail : phoneNumber]}
          onLoginChange={onTryAgainClick}
        />
        )}

        {codeError ? (
          <InputErrors>{codeError}</InputErrors>
        ) : (
          <React.Fragment>
            <Hint>Please enter your verification code</Hint>
            <SubHint>Code is valid for 15 minutes or 3 attempts</SubHint>
          </React.Fragment>
        )}
        <CodeNumberInput submitCode={submitCode}/>
        {!noMoreAttempts && (
        <ActionTextContainer>
          <ActionText>DIDN'T GET A CODE?</ActionText>
          <Timer label="Resend code in" till={retryTimerTill}>
            <ActionLink onClick={requestCodeRetryClick}>
              <span>Resend code</span>
            </ActionLink>
          </Timer>
        </ActionTextContainer>
        )}
      </ModalComponents.Form>
    </ModalComponents.ContentContainer>
    <SubFooter />
  </ModalComponents.ModalContent>
);

EnterVerificationCode.propTypes = {
  title: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  submitCode: PropTypes.func.isRequired,
  codeError: PropTypes.string.isRequired,
  requestCodeRetryClick: PropTypes.func.isRequired,
  onTryAgainClick: PropTypes.func.isRequired,
  retryTimerTill: PropTypes.instanceOf(Date).isRequired,
  sentVia: PropTypes.string,
  makedEmail: PropTypes.string,
  changeToEmail: PropTypes.func.isRequired,
  lastName: PropTypes.string,

};

const SelectedMethod = styled.div`
  margin-bottom: 24px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  color: #5e6263;
  font-weight: bold;
  text-align: center;
`;
const ActionTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #424648;
`;

const ActionText = styled.div`
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #424648;
  padding-right: 12px;
`;

const ActionLink = styled.a`
  font-weight: 500;
  color: #54a300;
  text-decoration: underline;
  cursor: pointer;
`;

const Hint = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: #5e6263;
`;

const SubHint = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: #424648;
  display: block;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export default enterVerificationCodeHOC(EnterVerificationCode);
