import { createAction, handleActions } from 'redux-actions';
import wretches from 'wretches';
import flattenDeep from 'lodash/flattenDeep';
import { decamelizeKeys } from 'humps';
import { FINISH_ORDER } from 'redux/modules/cart';
import setReduxPersistTimeout from 'utils/setReduxPersistTimeout';
import { actions as spinnerActions } from 'redux/modules/spinner/reducer';
import inform from 'utils/inform';
import fallbackScheduleVisibleSelector from 'selectors/fallbackScheduleVisibleSelector';
import setScheduleTimeConditionalThunk from './setScheduleTimeConditionalThunk';

export const deliveryTypes = {
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
};

export const deliveryTimeTypes = {
  SOONEST: 'SOONEST',
  SCHEDULE: 'SCHEDULE',
};

// ---
// CONSTANTS
// ---
export const SET_DELIVERY_TYPE = 'delivery/SET_DELIVERY_TYPE';
export const SET_DELIVERY_TIME_TYPE = 'delivery/SET_DELIVERY_TIME_TYPE';
export const SET_DELIVERY_SETTINGS = 'delivery/SET_DELIVERY_SETTINGS';
export const SET_SCHEDULE_TIME = 'delivery/SET_SCHEDULE_TIME';
export const SET_SOONEST_TIME = 'delivery/SET_SOONEST_TIME';
export const SET_USER_ADDRESS = 'delivery/SET_USER_ADDRESS';
export const SET_TEMP_USER_ADDRESS = 'delivery/SET_TEMP_USER_ADDRESS';
export const CLEAR = 'delivery/CLEAR';
export const UPDATE_DELIVERY = 'delivery/SET_DELIVERY';
export const SET_DELIVERY_CONDITIONS = 'delivery/SET_DELIVERY_CONDITIONS';
export const SET_REORDER_MODE = 'delivery/SET_REORDER_MODE';
export const CANCEL_REORDER_MODE = 'delivery/CANCEL_REORDER_MODE';
export const SNAPSHOT_DELIVERY_SETTINGS = 'delivery/SNAPSHOT_DELIVERY_SETTINGS';
export const ROLLBACK_DELIVERY_SETTINGS = 'delivery/ROLLBACK_DELIVERY_SETTINGS';
const SET_DINE_IN_TABLE = 'delivery/SET_DINE_IN_TABLE';
export const SET_AUTO_CONTINUE = 'delivery/SET_AUTO_CONTINUE';

// ---
// ACTION_CREATORS
// ---
export const setDeliveryType = createAction(
  SET_DELIVERY_TYPE,
  payload => deliveryTypes[payload],
);
export const setDeliveryTimeType = createAction(
  SET_DELIVERY_TIME_TYPE,
  payload => deliveryTimeTypes[payload],
);
const setDeliverySettings = createAction(SET_DELIVERY_SETTINGS);
export const setScheduleTimeSuccess = createAction(SET_SCHEDULE_TIME);
export const setSoonestTimeSuccess = createAction(SET_SOONEST_TIME);
export const setUserAddressId = createAction(SET_USER_ADDRESS);
const setTempUserAddress = createAction(SET_TEMP_USER_ADDRESS);
export const clear = createAction(CLEAR);
export const updateDelivery = createAction(UPDATE_DELIVERY);
const setDeliveryConditions = createAction(SET_DELIVERY_CONDITIONS);
const setReorderMode = createAction(SET_REORDER_MODE);
const cancelReorderMode = createAction(CANCEL_REORDER_MODE);
const snapshotDeliverySettings = createAction(SNAPSHOT_DELIVERY_SETTINGS);
const rollbackDeliverySettings = createAction(ROLLBACK_DELIVERY_SETTINGS);
const setDineInTable = createAction(SET_DINE_IN_TABLE);
const setAutoContinue = createAction(SET_AUTO_CONTINUE);

// ---
// INITIAL_STATE
// ---
const initialState = {
  type: null,
  availability: null,
  userAddressId: null,
  tempUserAddress: null,
  deliveryTimeType: null,
  scheduleTime: undefined,
  minimumOrder: undefined,
  deliveryCharge: undefined,
  deliveryTime: undefined,
  lastDeliveryTimeType: undefined,
  lastScheduleTime: undefined,
  dineInTable: '',
  placeId: undefined,
  reorderId: undefined,
  snapshot: undefined,
  rewardLimit: undefined,
  ownDrivers: true,
  extraServiceFee: undefined,
  autoContinue: false,
  orderInitiatedAt: undefined,
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [SET_DELIVERY_TYPE]: (state, action) => ({
      ...state,
      ...initialState,
      lastScheduleTime: state.lastScheduleTime,
      lastDeliveryTimeType: state.lastDeliveryTimeType,
      reorderId: state.reorderId,
      type: action.payload,
      persistExpiresAt: setReduxPersistTimeout(),
      orderInitiatedAt: new Date(),
    }),
    [SET_DELIVERY_TIME_TYPE]: (state, action) => ({
      ...state,
      deliveryTimeType: action.payload,
      scheduleTime: undefined,
      orderInitiatedAt: new Date(),
    }),
    [SET_DELIVERY_SETTINGS]: (state, action) => ({
      ...state,
      ...action.payload,
      persistExpiresAt: setReduxPersistTimeout(),
      orderInitiatedAt: new Date(),
    }),
    [SET_SCHEDULE_TIME]: (state, action) => ({
      ...state,
      deliveryTimeType: deliveryTimeTypes.SCHEDULE,
      scheduleTime: action.payload,
      ...(action.payload && {
        lastDeliveryTimeType: deliveryTimeTypes.SCHEDULE,
        lastScheduleTime: action.payload,
      }),
    }),
    [SET_SOONEST_TIME]: (state, action) => ({
      ...state,
      deliveryTimeType: deliveryTimeTypes.SOONEST,
      scheduleTime: action.payload,
      ...(action.payload && {
        lastDeliveryTimeType: deliveryTimeTypes.SOONEST,
        lastScheduleTime: action.payload,
      }),
      orderInitiatedAt: new Date(),
    }),
    [SET_USER_ADDRESS]: (state, action) => ({
      ...state,
      userAddressId: action.payload,
    }),
    [SET_TEMP_USER_ADDRESS]: (state, action) => ({
      ...state,
      tempUserAddress: action.payload,
    }),
    [SET_DINE_IN_TABLE]: (state, action) => ({
      ...state,
      dineInTable: action.payload,
    }),
    [CLEAR]: () => ({
      ...initialState,
    }),
    [UPDATE_DELIVERY]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [FINISH_ORDER]: () => ({
      ...initialState,
    }),
    [SET_DELIVERY_CONDITIONS]: (
      state,
      {
        payload: {
          availability,
          minimumOrder,
          deliveryCharge,
          deliveryTime,
          placeId,
          rewardLimit,
          eSF,
          ownDrivers,
        },
      },
    ) => ({
      ...state,
      availability: availability || null,
      minimumOrder: +minimumOrder || undefined,
      deliveryCharge: +deliveryCharge || undefined,
      deliveryTime: +deliveryTime || undefined,
      placeId,
      rewardLimit: +rewardLimit || undefined,
      ownDrivers,
      extraServiceFee: +eSF || undefined,
    }),
    [SET_REORDER_MODE]: (state, action) => ({
      ...state,
      reorderId: action.payload,
    }),
    [CANCEL_REORDER_MODE]: state => ({
      ...state,
      reorderId: undefined,
    }),
    [SNAPSHOT_DELIVERY_SETTINGS]: state => ({
      ...state,
      snapshot: { ...state, snapshot: undefined },
    }),
    [ROLLBACK_DELIVERY_SETTINGS]: state => ({
      ...state.snapshot,
    }),
    [SET_AUTO_CONTINUE]: (state, action) => ({
      ...state,
      autoContinue: action.payload,
    }),
  },
  initialState,
);

const validatePlaceId = (placeId, onFailure) => dispatch =>
  new Promise(resolve => {
    dispatch(spinnerActions.showSpinner());
    wretches.addressValidate
      .query(decamelizeKeys({ placeId }))
      .get()
      .json(response => {
        dispatch(spinnerActions.hideSpinner());
        const availabilityValues = flattenDeep(
          Object.values(response.availability),
        );
        if (!availabilityValues.length) {
          onFailure();
        } else {
          resolve(response);
        }
      })
      .catch(err => {
        dispatch(spinnerActions.hideSpinner());
        if (err.json.code === 'rest_invalid_param') {
          onFailure();
        }
      });
  });

const checkAndSetDeliveryConditions = conditions => (dispatch, getState) => {
  dispatch(setDeliveryConditions(conditions));
  const state = getState();
  const fallbackScheduleVisible = fallbackScheduleVisibleSelector(state);
  if (fallbackScheduleVisible) {
    const error =
      'Unfortunately, it looks like your address is not available for delivery at this time. You can enter a different address or change your order to PICKUP.';
    inform({
      title: 'Oops...',
      message: error,
      buttonText: 'OK',
    });
    dispatch(setDeliveryConditions({ deliveryAddressError: error }));
    dispatch(setUserAddressId(null));
  }
};

export const validateDeliveryAddress = params => dispatch => {
  wretches.addressValidate
    .query(decamelizeKeys(params))
    .get()
    .json(json => {
      dispatch(checkAndSetDeliveryConditions(json));
    })
    .catch(err => {
      inform({
        title: 'Oops...',
        message: 'Address outside delivery area',
        buttonText: 'OK',
      });
      dispatch(
        setDeliveryConditions({ deliveryAddressError: err.json.message }),
      );
      dispatch(setUserAddressId(null));
    });
};

const setScheduleTime = (time, history) => dispatch => {
  dispatch(
    setScheduleTimeConditionalThunk(
      time,
      'SCHEDULE',
      setScheduleTimeSuccess,
      history,
    ),
  );
};

const setSoonestTime = (time, history) => dispatch => {
  dispatch(
    setScheduleTimeConditionalThunk(
      time,
      'SOONEST',
      setSoonestTimeSuccess,
      history,
    ),
  );
};

export const actions = {
  setDeliveryType,
  setDeliveryTimeType,
  setDeliverySettings,
  setScheduleTime,
  setSoonestTime,
  setUserAddressId,
  clear,
  updateDelivery,
  setDeliveryConditions,
  validatePlaceId,
  validateDeliveryAddress,
  setReorderMode,
  cancelReorderMode,
  snapshotDeliverySettings,
  rollbackDeliverySettings,
  setTempUserAddress,
  setDineInTable,
  setAutoContinue,
};
