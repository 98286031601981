import { handleActions, createAction } from 'redux-actions';
import { camelizeKeys } from 'humps';
import wretches from 'wretches';
import { LOG_IN } from 'redux/modules/user';
import { actions as spinnerActions } from 'redux/modules/spinner';
import inform from 'utils/inform';

// ---
// CONSTANTS
// ---
const ADD_PAYMENT_SUCCESS = 'payments/ADD_PAYMENT_SUCCESS';
const DELETE_PAYMENT_SUCCESS = 'payments/DELETE_PAYMENT_SUCCESS';
const MAKE_DEFAULT_PAYMENT_SUCCESS = 'payments/MAKE_DEFAULT_PAYMENT_SUCCESS';
const SELECT_PAYMENT = 'payments/SELECT_PAYMENT';
const SET_STRIPE_LOADED = 'payments/SET_STRIPE_LOADED';

// ---
// ACTION CREATORS
// ---
const addPaymentSuccess = createAction(ADD_PAYMENT_SUCCESS);
const deletePaymentSuccess = createAction(DELETE_PAYMENT_SUCCESS);
const makeDefaultPaymentSuccess = createAction(MAKE_DEFAULT_PAYMENT_SUCCESS);
const selectPayment = createAction(SELECT_PAYMENT);
const setStripeLoaded = createAction(SET_STRIPE_LOADED);

// ---
// INITIAL STATE
// ---

const initialState = {
  entities: {},
  defaultPayment: undefined,
  selectedPayment: undefined,
  fulfilled: false,
  stripeLoaded: false,
};

// ---
// REDUCER
// ---
export default handleActions(
  {
    [LOG_IN]: (state, action) => ({
      ...state,
      entities: camelizeKeys(action.payload.payments).stored,
      defaultPayment: camelizeKeys(action.payload.payments).defaultCc,
      selectedPayment: camelizeKeys(action.payload.payments).defaultCc,
      fulfilled: true,
    }),
    [ADD_PAYMENT_SUCCESS]: (state, action) => ({
      ...state,
      entities: camelizeKeys(action.payload.payments).stored,
      defaultPayment: camelizeKeys(action.payload.payments).defaultCc,
      selectedPayment: action.payload.newcc,
    }),
    [DELETE_PAYMENT_SUCCESS]: (state, action) => ({
      ...state,
      entities: camelizeKeys(action.payload.payments).stored,
      defaultPayment: camelizeKeys(action.payload.payments).defaultCc,
      ...(+action.payload.id === +state.selectedPayment && {
        selectedPayment: camelizeKeys(action.payload.payments).defaultCc,
      }),
    }),
    [MAKE_DEFAULT_PAYMENT_SUCCESS]: (state, action) => ({
      ...state,
      defaultPayment: action.payload,
    }),
    [SELECT_PAYMENT]: (state, action) => ({
      ...state,
      selectedPayment: action.payload,
    }),
    [SET_STRIPE_LOADED]: state => ({
      ...state,
      stripeLoaded: true,
    }),
  },
  initialState,
);

const addPayment = ({ token, name }) => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.payments
    .json({
      token,
      name,
    })
    .post()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(addPaymentSuccess(json));
    })
    .catch(err => {
      dispatch(spinnerActions.hideSpinner());
      inform({
        title: 'Oops...',
        message: err.json.message,
        buttonText: 'OK',
      });
    });
};

const deletePayment = id => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.payments
    .url(`/${id}`)
    .delete()
    .json(json => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(deletePaymentSuccess({ ...json, id }));
    })
    .catch(() => {
      dispatch(spinnerActions.hideSpinner());
    });
};

const updatePayments = ({ json, id }) => dispatch => {
  dispatch(deletePaymentSuccess({ ...json, id }));
};

const makeDefaultPayment = id => dispatch => {
  dispatch(spinnerActions.showSpinner());
  wretches.paymentsMakeDefault
    .url(`/${id}`)
    .post()
    .json(() => {
      dispatch(spinnerActions.hideSpinner());
      dispatch(makeDefaultPaymentSuccess(id));
    })
    .catch(() => {
      dispatch(spinnerActions.hideSpinner());
    });
};

export const actions = {
  addPayment,
  deletePayment,
  makeDefaultPayment,
  selectPayment,
  setStripeLoaded,
  updatePayments,
};
