import {
  compose,
  withHandlers,
  branch,
  renderComponent,
  defaultProps,
  withProps,
  lifecycle,
} from 'recompose';
import { withFormik } from 'formik';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { actions as userActions } from 'redux/modules/user';
import isEmail from 'utils/isEmail';
import inform from 'utils/inform';
import EnterVerificationCode from 'routes/Modals/EnterVerificationCode';
import { last } from 'lodash';

const forgotPasswordHOC = compose(
  defaultProps({ title: 'Reset your password', action: 'reset_password' }),
  connect(null, {
    resetPassword: userActions.resetPassword,
    resetPasswordSMS: userActions.resetPasswordSMS,
    forgotPassword: userActions.forgotPassword,
  }),
  withRouter,
  withProps(({ location: { state: { login, email } = {} } }) => ({
    login,
    email,
  })),
  withFormik({
    mapPropsToValues: ({ email }) => ({
      emailOrNumberField: email || '',
      email: '',
      token: '',
      phoneNumber: '',
      makedEmail: '',
      sentVia: '',
      lastName: '',
    }),
    enableReinitialize: true,
    validate: values => {
      const errors = {};
      if (!values.lastName) {
        errors.lastName = ['Required'];
      }
      return errors;
    },
    handleSubmit: (
      { lastName,phoneNumber},
      { props: { emailOrNumberField,resetPasswordSMS,login }, setFieldValue },
    ) => {
      const onSuccess = () => {

        setFieldValue('phoneNumber', login);
        setFieldValue('token', 'a23');
      };
      const onFailure = (message) => {
        inform({
          message: message,
          title: 'Error',
          buttonText: 'OK',
        });
      }
      resetPasswordSMS({ login: login,type:'sms', onFailure, onSuccess, lastName });
      
    },
  }),
  withHandlers({
    forgotPasswordRequest: ({ forgotPassword }) => (phoneNumber,onResetPasswordSuccess ) => {
      forgotPassword({ phoneNumber },onResetPasswordSuccess);
    },
    onEmailOrNumberFieldChange: ({ setFieldValue }) => e =>
      setFieldValue('emailOrNumberField', e.target.value),
    resendEmail: ({ resetPassword, values: { email } }) => () => {
      resetPassword({
        email,
        onSuccess: () => {
          inform({
            message: 'Email has been resent',
            buttonText: 'OK',
          });
        },
      });
    },
    sendEmailCode: (
      { setFieldValue,login,values: { makedEmail }  },
    ) => {
      console.log(login);
      console.log(makedEmail);
      setFieldValue('phoneNumber', login);
      setFieldValue('makedEmail', makedEmail);
      setFieldValue('token', 'a234');
      setFieldValue('sentVia', 'email');  // Update sentVia here
    },
    
    sendSMScode: (
      { login,setFieldValue },
    ) => {
      setFieldValue('phoneNumber', login);
      setFieldValue('token', 'a23');
    },
    onCodeValidated: ({ history }) => ({ recoverToken }) => {
      
      // redirect to url /recover/:token
      history.push({
        pathname: '/recover/' + recoverToken,
      });
    },
 
    onTryAgainClick: ({ history }) => () => {
      history.push({
        pathname: '/sign-in',
        state: { redirect: '/my-account' },
      });

    },
    onResetPasswordSuccess: ({ setFieldValue }) => (data) => {
      setFieldValue('makedEmail', data.email);
    },
    enableSMSMethod: ({ setFieldValue,history,login, values:{makedEmail} }) => () => {
      
      history.push({
        pathname: '/validate-lastname',
        state: {
          login: login,
          makedEmail: makedEmail
        },  
      });
      setFieldValue('sentVia', 'sms');
    },
    changeToEmail: ({ setFieldValue,history,login, values: { makedEmail,sentVia } }) => () => {
      if (sentVia != 'email') {
        setFieldValue('sentVia', 'email');
        setFieldValue('makedEmail', makedEmail);
        setFieldValue('token', 'a235');
        console.log("changeToEmail email");
      }
      else
      {
        history.push({
          pathname: '/validate-lastname',
          state: {
            login: login,
            makedEmail: makedEmail
          },  
        });
        setFieldValue('sentVia', 'sms');
      }
    }

  }),
  lifecycle({
    componentDidMount() {
      const { forgotPasswordRequest,login,onResetPasswordSuccess } = this.props;
      forgotPasswordRequest(
        login,
        onResetPasswordSuccess
      );

    },
  }),
  // new branch with enter verification code when sendSMScode is called
  withProps(({ values: { phoneNumber, token, sentVia,makedEmail },changeToEmail }) => ({
    phoneNumber,
    token,
    sentVia,
    makedEmail,
    changeToEmail
  })),
  branch(({ values: { token } }) => !!token, renderComponent(EnterVerificationCode)),
  
);

export default forgotPasswordHOC;
