import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isArray from 'lodash/isArray';

import Counter from 'components/Counter';

import SpecialInstructionsCard from 'components/SpecialInstructionsCard';

import { getFixedPrice } from 'utils/currencyHelpers';
import { media } from 'utils/mediaQueries';

import VariationCard from './components/VariationCard';
import Modal, { Header } from './components/Modal';

const DishDetails = ({
  dish,
  dish: { image, soldOut, minCount, notAllowedForDelivery },
  deliveryType,
  ownDrivers,
  dishPrice,
  configuredDishVariations,
  configuration,
  updateVariation,
  showFlavorsModal,
  closeDishDetailsModal,
  show,
  isFavorite,
  onFavoriteClick,
  submitButtonActive,
  submitButtonText,
  submitButtonTouched,
  price,
  updateCounter,
  handleSpecialInstructionsChange,
  handleSubmitClick,
  onVariationRef,
  onScrollableNodeRef,
  onContentNodeRef,
  handleScroll,
  hideSpecialInstructions,
}) => (
  <Modal show={show} onCloseClick={closeDishDetailsModal}>
    <ModalContent>
      <Header
        title={dish.title}
        hasImage={!!image}
        isFavorite={isFavorite}
        onFavoriteClick={() => onFavoriteClick(dish.id, isFavorite)}
        onCloseClick={closeDishDetailsModal}
        modalShow={show}
      />
      <ScrollableContent innerRef={onScrollableNodeRef} onScroll={handleScroll}>
        <Wrapper
          soldOut={
            soldOut ||
            (notAllowedForDelivery && deliveryType == 'DELIVERY' && !ownDrivers)
          }
          innerRef={onContentNodeRef}
        >
          <Inner style={{ marginTop: image ? 0 : 50 }}>
            {image ? <Image src={image} alt="" /> : <AntiCollapseHack />}
            <Information>
              <Top>
                <Title>{dish.title}</Title>
                {/* {!!dishPrice && ( */}
                {/*  <Price> */}
                {/*    {isArray(dishPrice) */}
                {/*      ? dishPrice.map(p => `$${getFixedPrice(p)}`).join(' - ') */}
                {/*      : `$${getFixedPrice(dishPrice)}`} */}
                {/*    {minCount > 1 && ( */}
                {/*      <PriceQuantityLabel> /person</PriceQuantityLabel> */}
                {/*    )} */}
                {/*  </Price> */}
                {/* )} */}
              </Top>
              {minCount && minCount > 1 && (
                <MinimumCount>Minimum {minCount} people</MinimumCount>
              )}

              <Bottom>
                <Description>
                  {dish.description &&
                    dish.description.split('\n').map(line => <p>{line}</p>)}
                </Description>
              </Bottom>
            </Information>

            <Content>
              {configuredDishVariations.map(variation => (
                <CardWrapper
                  key={variation.id}
                  ref={onVariationRef(variation.id)}
                >
                  <VariationCard
                    configuration={configuration.variations[variation.id]}
                    onChange={updateVariation}
                    data={variation}
                    onMultiplePickerClick={showFlavorsModal}
                    submitButtonTouched={submitButtonTouched}
                  />
                </CardWrapper>
              ))}
              {hideSpecialInstructions != 'both' &&
                hideSpecialInstructions != 'item' && (
                  <CardWrapper>
                    <SpecialInstructionsCard
                      value={configuration.specialInstructions}
                      onChange={handleSpecialInstructionsChange}
                      placeholder="Add note (extra sauce, no pickles). Charges may apply."
                    />
                  </CardWrapper>
                )}
              <CounterWrapper>
                <Counter
                  onIncClick={() => updateCounter(1)}
                  count={configuration.count}
                  onDecClick={() => updateCounter(-1)}
                />
              </CounterWrapper>
            </Content>
          </Inner>
        </Wrapper>
      </ScrollableContent>
      <ButtonWrapper>
        {minCount > 1 && (
          <PerPersonPrice>
            ${getFixedPrice(dishPrice)} per person
          </PerPersonPrice>
        )}
        {notAllowedForDelivery && deliveryType == 'DELIVERY' && !ownDrivers ? (
          <SoldOut>
            <SoldOutLabel>NOT AVAILABLE</SoldOutLabel>
            <SoldOutHint>For Delivery Orders</SoldOutHint>
          </SoldOut>
        ) : soldOut ? (
          <SoldOut>
            <SoldOutLabel>SOLD OUT</SoldOutLabel>
            <SoldOutHint>Temporarily unavailable</SoldOutHint>
          </SoldOut>
        ) : (
          <ReviewOrder active={submitButtonActive} onClick={handleSubmitClick}>
            <Label active={submitButtonActive}>{submitButtonText}</Label>
            <Amount>${price.toFixed(2)}</Amount>
          </ReviewOrder>
        )}
      </ButtonWrapper>
    </ModalContent>
  </Modal>
);

DishDetails.propTypes = {
  dish: PropTypes.object,
  dishPrice: PropTypes.number,
  configuredDishVariations: PropTypes.array,
  updateVariation: PropTypes.func,
  configuration: PropTypes.object,
  price: PropTypes.number,
  showFlavorsModal: PropTypes.func,
  closeDishDetailsModal: PropTypes.func,
  show: PropTypes.bool,
  isFavorite: PropTypes.bool,
  onFavoriteClick: PropTypes.func,
  submitButtonActive: PropTypes.bool,
  submitButtonText: PropTypes.string,
  submitButtonTouched: PropTypes.bool.isRequired,
  updateCounter: PropTypes.func.isRequired,
  handleSpecialInstructionsChange: PropTypes.func.isRequired,
  handleSubmitClick: PropTypes.func.isRequired,
  onVariationRef: PropTypes.func.isRequired,
  onScrollableNodeRef: PropTypes.func.isRequired,
  onContentNodeRef: PropTypes.func.isRequired,
  handleScroll: PropTypes.func.isRequired,
  hideSpecialInstructions: PropTypes.string,
};

const ScrollableContent = styled.div`
  overflow: auto;
  background: #f3f4f5;
  flex: 1;
`;

const Wrapper = styled.div`
  background: #f3f4f5;
  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
  opacity: ${p => p.soldOut && '0.7'};
  ${media.tablet`
    max-width: 440px;
    margin: 0;
  `};
`;

const Inner = styled.div`
  width: 100%;
  margin: 0 auto;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const Image = styled.img`
  display: block;
  width: 100%;

  /* -webkit-perspective: 1000; */
  /* -webkit-backface-visibility: hidden; */
  /* transform: translate3d(0, 0, 1000); */
  /* transform-style: preserve-3d; */
`;

const AntiCollapseHack = styled.div`
  content: ' ';
  display: table;
`;

const Information = styled.div`
  margin: 0;
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Top = styled.div`
  display: flex;
  flex-flow: row nowrap;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const MinimumCount = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 200;
  color: #323537;
  margin-top: 6px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-size: 19px;
  font-family: 'Roboto', sans-serif;
  color: #000;
  line-height: 25px;
  padding-right: 10px;
  flex: 1 1 auto;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const Price = styled.div`
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: rgb(66, 70, 72);
  line-height: 25px;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const PriceQuantityLabel = styled.span`
  font-size: 14px;
  color: #323537;
  font-weight: 300;
`;

const Bottom = styled.div`
  margin-top: 10px;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const Description = styled.div`
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  color: #414141;
  font-weight: 400;
  line-height: 20px;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const Content = styled.div`
  padding: 15px;

  -webkit-backface-visibility: hidden;
  /* -webkit-transform-style: preserve-3d; */
`;

const CardWrapper = styled.div`
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  &:not(:first-child) {
    margin-top: 20px;
  }

  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
`;

const CounterWrapper = styled.div`
  padding: 25px 0 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  border-radius: 2px;

  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
`;

const ButtonWrapper = styled.div`
  background: #fff;
  padding: 15px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.1);
  z-index: 4;
`;

const PerPersonPrice = styled.div`
  color: #424648;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 12px;
`;

const SoldOut = styled.div`
  color: rgb(66, 70, 72);
  text-align: center;
  border-radius: 2px;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  transition: background 0.2s ease;
  line-height: 1.6;
`;

const SoldOutLabel = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
`;

const SoldOutHint = styled.div`
  color: red;
  font-size: 14px;
  font-weight: 300;
`;

const ReviewOrder = styled.button`
  color: ${({ active }) => (active ? '#fff' : 'rgba(48, 48, 48, 0.8)')};
  text-align: center;
  border-radius: 2px;
  background: ${({ active }) => (active ? 'rgb(84, 163, 0)' : '#d2d3d3')};
  height: 52px;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 19px 15px;
  transition: background 0.2s ease;

  ${media.tablet`
    cursor: pointer;
    &:hover {
      background: ${({ active }) => (active ? '#67c70f' : '#d2d3d3')};
    }
  `};
`;

const Amount = styled.div`
  font-size: 14px;
  opacity: 0.8;
  font-family: 'Roboto';
  font-weight: normal;
`;

const Label = styled.div`
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: initial;
  pointer-events: auto;
  ${media.tablet`
    width: 436px;
    height: initial;
    max-height: 90vh;
  `};
`;

export default DishDetails;
